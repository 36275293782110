import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useReaction } from '../../Hooks/useReaction';
import { RemoteDataState } from '../../Utils/RemoteData';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import CollectionsStore from '../../Stores/CollectionsStore';
import { stores } from '../../Stores/RootStore';

export interface DialogSettings {
  onClean: (wallStore: CollectionsStore) => void;
  reactionExpression: () => any;
  messageKey: string;
  titleKey: string;
  redirect?: string;
}

const TransactionDialog = ({
  onClean,
  reactionExpression,
  messageKey,
  titleKey,
  redirect
}: DialogSettings) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { collectionsStore } = useStores();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleSuccessDialog = (value: boolean) => () => {
    onClean(collectionsStore);
    setDialogOpen(value);
    if (redirect) {
      navigate(redirect, { replace: true });
    }
  };

  useReaction(reactionExpression, state => {
    if (state) {
      setDialogOpen(true);
    }
  });

  return (
    <Dialog open={isDialogOpen} onClose={handleSuccessDialog(false)}>
      <DialogTitle>{t(titleKey)}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(messageKey)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSuccessDialog(false)}
          color="primary"
          variant="contained"
          disableElevation
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const createDialog = (props: DialogSettings) => {
  return observer(() => <TransactionDialog {...props} />);
};

// Create Collection
export const CreateCollectionSuccessDialog = createDialog({
  onClean: collectionsStore => {
    collectionsStore.clearCreateCollection();
  },
  reactionExpression: () =>
    stores.collectionsStore.createCollectionRd.state ===
    RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_edit',
  titleKey: 'transaction_success',
  redirect: '/'
});
export const CreateCollectionErrorDialog = createDialog({
  onClean: collectionsStore => {
    collectionsStore.clearCreateCollection();
  },
  reactionExpression: () =>
    stores.collectionsStore.createCollectionRd.state ===
    RemoteDataState.FAILURE,
  messageKey: 'transaction_error_edit',
  titleKey: 'transaction_error'
});

// Set Collection
export const SetCollectionSuccessDialog = createDialog({
  onClean: collectionsStore => {
    collectionsStore.clearSetContentCollection();
  },
  reactionExpression: () =>
    stores.collectionsStore.setContentCollectionRd.state ===
    RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_edit',
  titleKey: 'transaction_success'
});
export const SetCollectionErrorDialog = createDialog({
  onClean: collectionsStore => {
    collectionsStore.clearSetContentCollection();
  },
  reactionExpression: () =>
    stores.collectionsStore.setContentCollectionRd.state ===
    RemoteDataState.FAILURE,
  messageKey: 'transaction_error_edit',
  titleKey: 'transaction_error'
});

// Create Token
export const CreateTokenSuccessDialog = ({
  collectionId
}: {
  collectionId: string;
}) => {
  return (
    <TransactionDialog
      onClean={collectionsStore => {
        collectionsStore.clearCreateToken();
      }}
      reactionExpression={() =>
        stores.collectionsStore.createTokenRd.state === RemoteDataState.SUCCESS
      }
      messageKey="transaction_success_edit"
      titleKey="transaction_success"
      redirect={`/${stores.metaMaskStore.chainData?.nameForUrl}/collection/${collectionId}`}
    />
  );
};
export const CreateTokenErrorDialog = createDialog({
  onClean: collectionsStore => {
    collectionsStore.clearCreateToken();
  },
  reactionExpression: () =>
    stores.collectionsStore.createTokenRd.state === RemoteDataState.FAILURE,
  messageKey: 'transaction_error_edit',
  titleKey: 'transaction_error'
});

// Edit Token
export const EditTokenSuccessDialog = ({
  collectionId,
  tokenId
}: {
  collectionId: string;
  tokenId: string;
}) => {
  return (
    <TransactionDialog
      onClean={collectionsStore => {
        collectionsStore.clearEditToken();
      }}
      reactionExpression={() =>
        stores.collectionsStore.setContentTokenRd.state ===
        RemoteDataState.SUCCESS
      }
      messageKey="transaction_success_edit"
      titleKey="transaction_success"
      redirect={`/${stores.metaMaskStore.chainData?.nameForUrl}/token/${collectionId}/${tokenId}`}
    />
  );
};
export const EditTokenErrorDialog = createDialog({
  onClean: collectionsStore => {
    collectionsStore.clearEditToken();
  },
  reactionExpression: () =>
    stores.collectionsStore.setContentTokenRd.state === RemoteDataState.FAILURE,
  messageKey: 'transaction_error_edit',
  titleKey: 'transaction_error'
});
