import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
  mediaFile: yup.object({
    file: yup.mixed().required()
  }),
  cover: yup.object({
    file: yup.mixed().required()
  })
});

export default schema;
