import React from 'react';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MenuIcon from '@mui/icons-material/Menu';
import { TransitionProps } from '@mui/material/transitions';
import { ListItemButton } from '@mui/material';
import { t } from 'i18next';
import HeaderLink from '../Header/HeaderLink';
import BalanceInformer from '../../Containers/BalanceInformer/BalanceInformer';
import { MetamaskState } from '../../Stores/MetaMaskStore';
import s from './MobileMenu.module.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function MobileMenu({
  metaMaskState
}: {
  metaMaskState: MetamaskState;
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={s.btnMenu}>
        <MenuIcon onClick={handleClickOpen} htmlColor="#fff" fontSize="large" />
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={s.main}
        PaperProps={{ sx: { backgroundColor: '#444' } }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon htmlColor="#fff" />
          </IconButton>
          <BalanceInformer />
        </Toolbar>
        <List onClick={handleClose}>
          <ListItemButton>
            <HeaderLink title={t('showroom')} to={`/showroom`} />
          </ListItemButton>
          <Divider color="#fff" />
          {metaMaskState === 3 && (
            <>
              <ListItemButton>
                <HeaderLink title={t('my_assets')} to="/" />
              </ListItemButton>
              <Divider color="#fff" />
            </>
          )}
          <ListItemButton>
            <HeaderLink title={t('instructions')} to={`/instructions`} />
          </ListItemButton>
          <Divider color="#fff" />
          <ListItemButton>
            <HeaderLink title={t('promo')} to="/promo" />
          </ListItemButton>
        </List>
      </Dialog>
    </div>
  );
}
