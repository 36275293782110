import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import './Promo.scss';

const NoCollections = ({
  show,
  promoterCollections
}: {
  show: boolean;
  promoterCollections: Nullable<CollectionTypeTg[]>;
}) => {
  const navigate = useNavigate();
  if (!show) return null;
  return (
    <div>
      <Typography variant="body1" mb={2}>
        {t('to_unlock_sales_')}
      </Typography>
      {promoterCollections && promoterCollections.length ? (
        <button className="pm-button" onClick={() => navigate(`/`)}>
          {t('create_nft')}
        </button>
      ) : (
        <button
          className="pm-button"
          onClick={() => navigate(`/create-collection`)}
        >
          {t('create_collection')}
        </button>
      )}
    </div>
  );
};

export default NoCollections;
