import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, CardMedia, CircularProgress } from '@mui/material';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { oc } from 'ts-optchain';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Stores/RootStore';
import { RemoteDataState } from '../../Utils/RemoteData';
import { getCidFromUri } from '../../Helpers/helpers';
import DeleteTokenDialog from '../../Components/Dialogs/DeleteTokenDialog';
import SendTokenDialog from '../../Components/Dialogs/SendTokenDialog';
import verifiedIconGreen from '../../Resources/verified_s_green.svg';
import verifiedIconGrey from '../../Resources/verified_s_grey.svg';
import verifiedIconRed from '../../Resources/verified_s_red.svg';
import editIcon from '../../Resources/edit_icon.svg';
import photoImg from '../../Resources/photo.svg';
import arrowIcon from '../../Resources/arrow_icon.svg';
import s from './NftCard.module.scss';

interface NftCardProps {
  nft: TokenTypeTg;
  path?: string;
}

const NftCard: React.FC<NftCardProps> = ({ nft, path }) => {
  const { t } = useTranslation();
  const { metaMaskStore, collectionsStore } = useStores();
  const { baseSiteURI } = collectionsStore;
  const { collectionId } = useParams();
  const { name, image, tokenId } = nft;

  if (!collectionId) return null;

  const verifyData = oc(collectionsStore)
    .tokensVerifyDataTg.value([])
    .find((i: TokenVerifyDataTgType) => i.tokenId === nft?.id);

  const status = verifyData ? verifyData.status : null;

  return (
    <div className={s.main}>
      <RouterLink to={`${path}/token/${collectionId}/${tokenId}`}>
        <CardMedia
          component="img"
          height="161"
          image={image ? baseSiteURI.concat(getCidFromUri(image)) : photoImg}
          alt=""
          sx={{ objectFit: 'contain' }}
        />
      </RouterLink>
      <Box m={1} className={s.contentCard}>
        <Box className={s.flexStart}>
          {status === null &&
          collectionsStore.tokensVerifyDataTg.state !==
            RemoteDataState.REQUEST ? null : (
            <Box mr={1}>
              {status === null &&
                collectionsStore.tokensVerifyDataTg.state ===
                  RemoteDataState.REQUEST && <CircularProgress size={12} />}
              {status === 'Initial' && <img src={verifiedIconGrey} alt="" />}
              {status === 'Pending' && <img src={verifiedIconGrey} alt="" />}
              {status === 'Proved' && <img src={verifiedIconGreen} alt="" />}
              {status === 'NotProved' && <img src={verifiedIconRed} alt="" />}
            </Box>
          )}
          <Typography variant="body2" className={s.name}>
            {name}
          </Typography>
        </Box>
        {metaMaskStore.currentAccount === nft.owner && (
          <Box className={s.flexsBetween} mt={1}>
            <DeleteTokenDialog token={nft} collectionId={collectionId} />
            <SendTokenDialog token={nft} collectionId={collectionId}>
              <img
                className={s.imgIcon}
                src={arrowIcon}
                alt={t('transfer')}
                title={t('transfer')}
              />
            </SendTokenDialog>
            <RouterLink to={`/edit-nft/${collectionId}/${tokenId}`}>
              <img src={editIcon} alt={t('edit')} title={t('edit')} />
            </RouterLink>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default observer(NftCard);
