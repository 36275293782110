import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import FieldLabel from '../../Components/FieldLabel/FieldLabel';
import { useStores } from '../../Stores/RootStore';
import schema from './schemaBurn';

import s from './FormCidNew.module.scss';
import CustomContainer from '../../Components/CustomContainer/CustomContainer';

const FormCidNew = () => {
  const { metaMaskStore, collectionsStore } = useStores();

  const provider = oc(metaMaskStore).provider(null);

  if (!provider) return null;

  return (
    <React.Fragment>
      <CustomContainer>
        <Typography variant="h1">Burn token</Typography>
        <Formik
          initialValues={{
            id: '',
            contract: ''
          }}
          onSubmit={async (values, { setSubmitting }) => {
            collectionsStore.deleteTokenOld(values.id, values.contract);
            setSubmitting(false);
          }}
          validationSchema={schema}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className={s.form}>
              <Box mb={1}>
                <FieldLabel title="Contract" />
                <Field
                  component={TextField}
                  name="contract"
                  type="text"
                  hiddenLabel
                  variant="filled"
                  size="small"
                />
              </Box>
              <Box mb={3}>
                <FieldLabel title="ID" />
                <Field
                  component={TextField}
                  name="id"
                  type="text"
                  hiddenLabel
                  variant="filled"
                  size="small"
                />
              </Box>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Burn
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </CustomContainer>
    </React.Fragment>
  );
};

export default observer(FormCidNew);
