import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../Stores/RootStore';
import { STORAGE_PENDING_TRANSACTIONS } from '../../../constants';

import s from './PendingTransactions.module.scss';
import { t } from 'i18next';

const PendingTransactions = () => {
  const { metaMaskStore, collectionsStore, promoStore } = useStores();
  const [pending, setPendingTransactions] = useState(0);

  const getTransactionsArr = () => [
    ...collectionsStore.transactions,
    ...promoStore.transactions
  ];

  const calcPendingTransactions = () => {
    setPendingTransactions(
      getTransactionsArr().reduce((acc, cur) => {
        if (cur.status === '0') {
          return acc + 1;
        }
        return acc;
      }, 0)
    );
  };

  useEffect(() => {
    if (!metaMaskStore.provider || !metaMaskStore.currentAccount) return;
    calcPendingTransactions();
    sessionStorage.setItem(
      `${STORAGE_PENDING_TRANSACTIONS}_${metaMaskStore.currentAccount}`,
      JSON.stringify(getTransactionsArr().filter(i => i.status === '0'))
    );
    collectionsStore.transactions.forEach(i => {
      if (i.status === '0' && metaMaskStore.provider) {
        metaMaskStore.provider.waitForTransaction(i.hash).then(() => {
          collectionsStore.setTransactionStatus(
            i.hash,
            '1',
            metaMaskStore.currentAccount
          );
        });
      }
    });
    promoStore.transactions.forEach(i => {
      if (i.status === '0' && metaMaskStore.provider) {
        metaMaskStore.provider.waitForTransaction(i.hash).then(() => {
          promoStore.setTransactionStatus(
            i.hash,
            '1',
            metaMaskStore.currentAccount
          );
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    collectionsStore.transactions.length,
    promoStore.transactions.length,
    metaMaskStore.currentAccount,
    metaMaskStore.provider
  ]);

  if (!pending) return null;

  return (
    <Box
      className={s.pending}
      title={getTransactionsArr()
        .map(i => i.hash)
        .join(', ')}
    >
      <Typography variant="body1">
        {pending} {t('pending')}
      </Typography>

      <div className={s.loaderSmall}>
        <CircularProgress size={12} sx={{ color: '#fff' }} />
      </div>
    </Box>
  );
};

export default observer(PendingTransactions);
