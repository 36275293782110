import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useStores } from '../../../Stores/RootStore';
import rightImg from '../../../Resources/Promo/balloon-toothy.png';
import { t } from 'i18next';
import s from './Dialog.module.scss';
import { Typography } from '@mui/material';

const BuyPromoteDialog = ({ promoPrice }: { promoPrice: Nullable<string> }) => {
  const { promoStore } = useStores();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    setOpen(false);
    promoStore.contractPromoteMe();
  };

  return (
    <div>
      <Button
        className="card-btn"
        onClick={handleClickOpen}
        disabled={!promoPrice}
      >
        {!promoPrice ? t('already_promoted') : t('promote')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <DialogContent className={s.dialog}>
          <div>
            <DialogContentText
              id="alert-dialog-description"
              variant="h2"
              className={s.content}
            >
              {t('promote_your_link')}
            </DialogContentText>
            <Typography
              mb={2}
              dangerouslySetInnerHTML={{
                __html: t('price_for_promoting_your_link_', {
                  matic: promoPrice
                })
              }}
            />
            <button className="pm-button" onClick={handleYes}>
              {t('promote')}
            </button>
          </div>
          <img src={rightImg} alt="" />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BuyPromoteDialog;
