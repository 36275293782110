import React from 'react';
import { reaction, IReactionPublic, IReactionOptions } from 'mobx';
// import { IReactionPublic } from 'mobx/lib/internal';
// import { IReactionOptions } from 'mobx/lib/api/autorun';

export function useReaction<T>(
  expression: (r: IReactionPublic) => T,
  effect: (arg: T, prev: T | undefined, r: IReactionPublic) => void,
  options?: IReactionOptions<T, any>
) {
  const expressionRef = React.useRef(expression);
  const optionsRef = React.useRef(options);
  React.useEffect(() => {
    return reaction(expressionRef.current, effect, optionsRef.current);
  }, [effect]);
}
