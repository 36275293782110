export const CONFIGURATION_QUERY = `
query getPromoConfiguration($id: ID) {
  configuration(id: $id) {
    id
    counter
    contract
    promoPrice
    refLinkPrice
    totalSupply
    availableSupply
    price
    lastActionTime
  }
}`;

export const PROMOTER_FIELDS = `
  id
  referrer {
    id
  }
  referrals(first: 1000) {
    id
    lastBuyAction {
      time
      tokenCost
      tokenPrice
      id
    }
  }
  tokenCost
  tokenPrice
  withdrawn
  spent
  restForUnlock
  refLinkBought
  linkPromoted
  hasToken
  lastBuyAction {
    time
  }
`;

export const PROMOTER_FIELDS_PAGE = `
  id
  referrer {
    id
  }
  referrals(first: $first, orderBy: id, where: {id_gt: $referralId}) {
    id
    lastBuyAction {
      time
      tokenCost
      tokenPrice
      id
    }
  }
  tokenCost
  tokenPrice
  withdrawn
  spent
  restForUnlock
  refLinkBought
  linkPromoted
  hasToken
  lastBuyAction {
    time
  }
`;

export const PROMOTERS_QUERY = `
query getPromoters($first: Int, $id: ID) {
  promoters(first: $first, orderBy: id, where: {id_gt: $id}) {
    ${PROMOTER_FIELDS}
  }
}
`;

export const PROMOTERS_QUERY_LINK_PROMOTED = `
query getPromotersLinkPromoted($first: Int, $id: ID) {
  promoters(first: $first, orderBy: id, where: {id_gt: $id, linkPromoted: true}) {
    ${PROMOTER_FIELDS}
  }
}
`;

export const PROMOTER_QUERY = `
query getPromoterPage($id: ID, $first: Int, $referralId: ID) {
  promoter(id: $id) {
    ${PROMOTER_FIELDS_PAGE}
  }
}
`;
