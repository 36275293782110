import React from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Stores/RootStore';
import schema from './schema';
import s from './Dialog.module.scss';

interface SendTokenDialogProps {
  token: TokenTypeTg;
  collectionId: string;
}

const SendTokenDialog: React.FC<SendTokenDialogProps> = ({
  token,
  collectionId,
  children
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { collectionsStore, metaMaskStore } = useStores();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box onClick={handleClickOpen}>{children}</Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <DialogContent className={cx(s.dialog, s.dialogWithAddress)}>
          <DialogContentText
            id="alert-dialog-description"
            variant="h2"
            className={s.content}
          >
            {t('enter_address_user_send_nft')}
          </DialogContentText>
        </DialogContent>
        <Formik
          initialValues={{
            address: ''
          }}
          onSubmit={(values, { setSubmitting }) => {
            collectionsStore.sendToken(
              metaMaskStore.currentAccount,
              values.address,
              token,
              collectionId
            );
            setSubmitting(false);
            handleClose();
          }}
          validationSchema={schema}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className={s.mainForm}>
              <Box className={s.formContent}>
                <Field
                  component={TextField}
                  name="address"
                  type="text"
                  hiddenLabel
                  variant="filled"
                  size="small"
                  className={cx(s.inputField, s.inputAddress)}
                />
              </Box>

              <DialogActions className={s.dialogActions}>
                <Button variant="outlined" onClick={handleClose} autoFocus>
                  {t('cancel')}
                </Button>
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  {t('Send')}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default SendTokenDialog;
