import React, { useState } from 'react';
import { Button, Box, Dialog, DialogActions, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppCardMedia from '../../../Components/AppCardMedia/AppCardMedia';
import { Formik, Form } from 'formik';
import { oc } from 'ts-optchain';
import { useStores } from '../../../Stores/RootStore';
import FieldLabel from '../../../Components/FieldLabel/FieldLabel';
import photoImg from '../../../Resources/photo.svg';
import { uploadJson, uploadImage } from '../../../Utils/Storage';
import { handleFileImage } from '../../../Utils/HandleFile';
import { MAXIMUM_FILE_SIZE, TOKENS, IMAGE_TYPES } from '../../../constants';
import s from './EditCollectionDialog.module.scss';

interface SendTokenDialogProps {
  collectionId: string;
}

const EditCollectionDialog: React.FC<SendTokenDialogProps> = ({
  collectionId
}) => {
  const { t } = useTranslation();
  const { metaMaskStore, collectionsStore } = useStores();
  const { baseSiteURI, collections, setContentCollection } = collectionsStore;
  const [open, setOpen] = useState(false);

  const collection = oc(collections)
    .value([])
    .find(i => i.id === collectionId);

  if (!collection) return null;

  const licensesData = oc(metaMaskStore).config.value.licenses([]);
  const license = licensesData.find(i => i.file_cid === collection.licenseCID);
  const provider = oc(metaMaskStore).provider(null);

  if (!provider) return null;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        className={s.titleBtnOpen}
        variant="body2"
        sx={{ color: 'primary.main' }}
        onClick={handleClickOpen}
      >
        {t('edit')}
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <Formik
          initialValues={{
            symbol: collection.symbol,
            name: collection.name,
            select: TOKENS[0],
            license: license ? license.title : '',
            cover: {
              file: null,
              name: '',
              path: '',
              error: ''
            }
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (!values.cover.file) return;
            const imgCid = await uploadImage(values.cover.file);
            const contentCid = await uploadJson({ image: imgCid });
            if (contentCid) {
              setContentCollection(contentCid, provider, collection.id);
            }
            setSubmitting(false);
            handleClose();
          }}
        >
          {({ submitForm, isSubmitting, setFieldValue, values }) => (
            <Form>
              <Box mt={4} className={s.mainForm}>
                <Box>
                  <Box className={s.title}>
                    <FieldLabel title={t('cover')} mb={2} />
                  </Box>
                  <Typography variant="caption" className={s.note} mb={2}>
                    {t('file_types_supported_value', {
                      value: MAXIMUM_FILE_SIZE / 10 ** 6
                    })}
                  </Typography>
                  <input
                    accept={`${IMAGE_TYPES}`}
                    className={s.uploadInput}
                    id="uploadarea"
                    multiple
                    type="file"
                    name="cover"
                    onChange={e =>
                      setFieldValue('cover', handleFileImage(e) ?? values.cover)
                    }
                  />
                  <Box my={1} className={s.boxImage}>
                    <label htmlFor="uploadarea">
                      <AppCardMedia
                        mediaObj={values.cover}
                        path={
                          collection.image
                            ? baseSiteURI.concat(collection.image)
                            : photoImg
                        }
                        maxWidth={true}
                        height="168"
                      />
                    </label>
                  </Box>
                </Box>
              </Box>
              <DialogActions className={s.dialogActions}>
                <Button
                  variant="contained"
                  disabled={isSubmitting || !values.cover.file}
                  onClick={submitForm}
                >
                  {t('save')}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default EditCollectionDialog;
