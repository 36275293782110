import React, { useState } from 'react';
import Button from '@mui/material/Button';
import cx from 'classnames';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useStores } from '../../../Stores/RootStore';
import headSnakeImg from '../../../Resources/Promo/head-snake.png';
import { t } from 'i18next';
import s from './Dialog.module.scss';
import { Typography } from '@mui/material';

const BuyDialog = ({
  refLinkPrice,
  disabled
}: {
  refLinkPrice: Nullable<string>;
  disabled: boolean;
}) => {
  const { promoStore } = useStores();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    setOpen(false);
    promoStore.contractBuyLink();
  };

  return (
    <div>
      <Button
        className="card-btn"
        onClick={handleClickOpen}
        disabled={disabled}
      >
        {t('buy')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <DialogContent className={cx(s.dialog, s.dialogRightImgV2)}>
          <div>
            <DialogContentText
              id="alert-dialog-description"
              variant="h2"
              className={s.content}
            >
              {t('referral_link')}
            </DialogContentText>
            <Typography
              mb={2}
              dangerouslySetInnerHTML={{
                __html: t('buy_link_for_matic', {
                  matic: refLinkPrice
                })
              }}
            />
            <Button variant="contained" className={s.btnV1} onClick={handleYes}>
              {t('buy')}
            </Button>
          </div>
          <img src={headSnakeImg} alt="" />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BuyDialog;
