import { isEqualWith } from 'lodash';

const formatToTemplate = (token: TokenTypeTg) => ({
  name: token.name,
  description: token.description,
  image: token.image,
  external_url: token.external_url,
  animation_url: token.animation_url,
  content: token.content
});

/**
 * Comparison function
 *
 * Compares two tokens using deep comparison with isEqualWith
 * Uses an additional comparator that returns true
 * when comparing null and an empty string
 *
 * @param {TokenTypeTg} left - token
 * @param {TokenTypeTg} right - token
 */
const tokenComparate = (left: TokenTypeTg, right: TokenTypeTg) =>
  isEqualWith(
    formatToTemplate(left),
    formatToTemplate(right),
    // tokenComparate called when the standard comparator returns false
    (a, b) => {
      if ((a === null || a === '') && (b === null || b === '')) return true;
    }
  );

export default tokenComparate;
