import React, { useState } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  Button,
  Link,
  FormHelperText
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import cx from 'classnames';
import { Formik, Form, Field } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { TextField } from 'formik-mui';
import AppCardMedia from '../../../Components/AppCardMedia/AppCardMedia';
import FieldLabel from '../../../Components/FieldLabel/FieldLabel';
import photoImg from '../../../Resources/photo.svg';
import documentDownloadImg from '../../../Resources/document-download-white.svg';
import arrowLeft from '../../../Resources/ArrowLeft.svg';
import { uploadJson, uploadImage, uploadFile } from '../../../Utils/Storage';
import { handleFileImage } from '../../../Utils/HandleFile';
import { MAXIMUM_FILE_SIZE, TOKENS, IMAGE_TYPES } from '../../../constants';
import { useStores } from '../../../Stores/RootStore';
import schema from './schema';
import trashIcon from '../../../Resources/trash_icon.svg';
import s from '../Forms.module.scss';
import CustomContainer from '../../../Components/CustomContainer/CustomContainer';

interface userLicenseType {
  name: string;
  cid: string;
}

const CreateCollection = () => {
  const { t } = useTranslation();
  const { metaMaskStore, collectionsStore } = useStores();
  const [license, setLicense] = useState(0);
  const [userLicense, setUserLicense] =
    useState<Nullable<userLicenseType>>(null);
  const { baseURI } = collectionsStore;

  const licensesData = oc(metaMaskStore).config.value.licenses([]);
  const mintMeFactoryAddress = oc(metaMaskStore).config.value.contract('');
  const provider = oc(metaMaskStore).provider(null);

  if (!mintMeFactoryAddress || !provider) return null;

  const feeCreateCollectionStr = collectionsStore.feeCreateCollectionStr;

  return (
    <React.Fragment>
      <CustomContainer className={s.main}>
        <Formik
          initialValues={{
            symbol: '',
            name: '',
            select: TOKENS[0],
            license: licensesData.length > 0 ? licensesData[license].title : '',
            cover: {
              file: null,
              name: '',
              path: '',
              error: ''
            }
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (!values.cover.file) {
              return;
            }
            const imgCid = await uploadImage(values.cover.file);
            const contentCid = await uploadJson({ image: imgCid });

            let licenseCid = '';
            if (userLicense?.cid) {
              licenseCid = userLicense.cid;
            } else if (licensesData.length > 0) {
              licenseCid = licensesData[license].file_cid;
            }

            if (imgCid && contentCid) {
              collectionsStore.createCollection(
                values.name,
                values.symbol,
                contentCid,
                licenseCid,
                provider,
                mintMeFactoryAddress
              );
            }
            setSubmitting(false);
          }}
          validationSchema={schema}
        >
          {({
            submitForm,
            isSubmitting,
            setFieldValue,
            values,
            touched,
            errors
          }) => (
            <Form>
              <Typography variant="h1">
                <RouterLink to="/">
                  <img src={arrowLeft} alt="" />
                </RouterLink>{' '}
                {t('create_collection')}
              </Typography>
              <Box mt={4} className={s.mainForm}>
                <Box>
                  <FieldLabel title={t('cover')} mb={0} />
                  <Typography variant="caption">
                    {t('file_types_supported_value', {
                      value: MAXIMUM_FILE_SIZE / 10 ** 6
                    })}
                  </Typography>
                  <input
                    accept={`${IMAGE_TYPES}`}
                    className={s.uploadInput}
                    id="uploadCover"
                    type="file"
                    name="cover"
                    onChange={e =>
                      setFieldValue('cover', handleFileImage(e) ?? values.cover)
                    }
                  />
                  <Box width="190px" my={1} className={s.boxImage}>
                    <label htmlFor="uploadCover">
                      <AppCardMedia
                        mediaObj={values.cover}
                        path={photoImg}
                        error={!!touched.cover && !!errors.cover}
                      />
                    </label>
                  </Box>
                  {!!touched.cover && !!errors.cover && (
                    <FormHelperText error>{t('required')}</FormHelperText>
                  )}
                </Box>
                <Box className={s.rightBox}>
                  <Box mb={3} className={s.left}>
                    <FieldLabel title={t('symbol')} />
                    <Field
                      component={TextField}
                      name="symbol"
                      type="text"
                      hiddenLabel
                      variant="filled"
                      size="small"
                      className={s.inputField}
                    />
                  </Box>
                  <Box mb={3} className={s.left}>
                    <FieldLabel title={t('name')} />
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      hiddenLabel
                      variant="filled"
                      size="small"
                      className={s.inputField}
                    />
                  </Box>
                  <Box mb={3} className={s.left}>
                    <FieldLabel title={t('token_creation_standard')} />
                    <Field
                      className={cx(s.inputField, s.selectField)}
                      component={TextField}
                      type="text"
                      name="select"
                      select
                      variant="standard"
                      margin="normal"
                    >
                      {TOKENS.map(i => (
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Field>
                  </Box>
                  {!userLicense && (
                    <>
                      <Box mb={3} className={s.left}>
                        <FieldLabel title={t('license_file')} />
                        <Field
                          className={cx(s.inputField, s.selectField)}
                          component={TextField}
                          type="text"
                          name="license"
                          select
                          variant="standard"
                          margin="normal"
                          value={licensesData.length > 0 ? license : ''}
                          onChange={(i: any) => {
                            if (i.target.value === licensesData.length - 1) {
                              document.getElementById('userLicenses')?.click();
                            }
                            setLicense(i.target.value);
                          }}
                          onFocus={(i: any) => {
                            if (
                              !licensesData[license].file_cid &&
                              i.target.value === licensesData.length - 2
                            ) {
                              setLicense(0);
                            }
                          }}
                          disabled={licensesData.length < 1}
                        >
                          {licensesData.map((i, index) => (
                            <MenuItem key={i.title} value={index}>
                              {i.title}
                            </MenuItem>
                          ))}
                        </Field>
                      </Box>
                      {licensesData[license].file_cid ? (
                        <Link
                          className={s.downloadBtnBox}
                          href={baseURI.concat(licensesData[license].file_cid)}
                          target="_blank"
                        >
                          <img src={documentDownloadImg} alt="" />
                        </Link>
                      ) : (
                        <Link className={cx(s.downloadBtnBox, s.disabled)}>
                          <img src={documentDownloadImg} alt="" />
                        </Link>
                      )}
                    </>
                  )}

                  <input
                    className={s.uploadInput}
                    id="userLicenses"
                    type="file"
                    name="userLicenses"
                    onChange={async e => {
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0];
                        e.target.value = '';
                        if (file.size <= MAXIMUM_FILE_SIZE) {
                          const cid = await uploadFile(file, file.type);
                          if (cid) {
                            setUserLicense({ name: file.name, cid });
                          }
                        }
                      }
                    }}
                  />
                  <Box mb={1} className={s.left}>
                    {userLicense && (
                      <>
                        <FieldLabel title={t('license_file')} />
                        <div className={s.userLicense}>
                          <Typography variant="body1">
                            {userLicense.name}
                          </Typography>
                          <img
                            className={s.imgIcon}
                            src={trashIcon}
                            alt=""
                            onClick={() => {
                              setUserLicense(null);
                              setLicense(0);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Box>
                  {userLicense ? (
                    <Link
                      className={cx(s.downloadBtnBox, s.userLicense)}
                      href={baseURI.concat(userLicense.cid)}
                      target="_blank"
                    >
                      <img src={documentDownloadImg} alt="" />
                    </Link>
                  ) : null}

                  <Box mt={2} className={cx(s.boxSubmitBtn, s.left)}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      {t('create_val_cur', {
                        value: feeCreateCollectionStr,
                        currency:
                          metaMaskStore.chainData?.currency.toUpperCase()
                      })}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </CustomContainer>
    </React.Fragment>
  );
};

export default observer(CreateCollection);
