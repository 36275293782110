import React from 'react';
import { Container } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MyCollections from '../Collections/MyCollections';
import Collections from '../Collections/Collections';
import CreateCollection from '../Forms/CreateCollection/CreateCollection';
import Header from '../../Components/Header/Header';
import UserCollection from '../Collection/UserCollection';
import Collection from '../Collection/Collection';
import EditCollection from '../Forms/EditCollection/EditCollection';
import CreateNft from '../Forms/CreateNft/CreateNft';
import EditNft from '../Forms/EditNft/EditNft';
import DetailNft from '../DetailNft/DetailNft';
import DetailNftReadOnly from '../DetailNft/DetailNftReadOnly';
import MetamaskInfo from '../MetamaskInfo/MetamaskInfo';
import {
  CreateCollectionSuccessDialog,
  CreateCollectionErrorDialog,
  SetCollectionSuccessDialog,
  SetCollectionErrorDialog
} from '../../Components/Dialogs/Dialogs';
import FormCidNew from '../FormCid/FormCidNew';
import FormCidBurn from '../FormCid/FormCidBurn';
import Instructions from '../../Components/Instructions/Instructions';
import Promo from '../Promo/Promo';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';

import s from './App.module.scss';

function App() {
  return (
    <div className={s.main}>
      <BrowserRouter>
        <Header />
        <Container disableGutters maxWidth="xl">
          <ScrollToTop>
            <Routes>
              <Route path="/instructions/*" element={<Instructions />} />
              <Route
                path="/showroom/:net/token/:collectionId/:id"
                element={<DetailNft showroom={true} />}
              />
              <Route
                path="/showroom/:net/collection/:collectionId"
                element={<Collection />}
              />
              <Route
                path="/token/:net/:collectionId/:uuid"
                element={<DetailNftReadOnly />}
              />
              <Route
                path="/:net/token/:collectionId/:id"
                element={<DetailNft />}
              />
              <Route path="/showroom" element={<Collections />} />
              <Route path="/showroom/:net" element={<Collections />} />
              <Route path="/edit-nft/:collectionId/:id" element={<EditNft />} />
              <Route path="/create-nft/:collectionId" element={<CreateNft />} />
              <Route
                path="/edit-collection/:collectionId"
                element={<EditCollection />}
              />
              <Route
                path="/:net/collection/:collectionId"
                element={<UserCollection />}
              />
              <Route path="/create-collection" element={<CreateCollection />} />
              <Route path="/mint-token" element={<FormCidNew />} />
              <Route path="/burn-token" element={<FormCidBurn />} />
              <Route path="/promo" element={<Promo />} />
              <Route path="/" element={<MyCollections />} />
            </Routes>
          </ScrollToTop>
        </Container>
        <CreateCollectionSuccessDialog />
        <CreateCollectionErrorDialog />
        <SetCollectionSuccessDialog />
        <SetCollectionErrorDialog />
      </BrowserRouter>
      <MetamaskInfo />
    </div>
  );
}

export default App;
