import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import s from './Dialog.module.scss';

const InformationDialog = ({
  contentDialog,
  setContentDialog
}: {
  contentDialog: Nullable<JSX.Element>;
  setContentDialog: (arg0: Nullable<JSX.Element>) => void;
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setContentDialog(null);
  };

  if (contentDialog == null) return null;

  return (
    <div>
      <Dialog
        open={contentDialog != null}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <DialogContent className={s.dialog}>{contentDialog}</DialogContent>
        <DialogActions className={s.dialogActions}>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InformationDialog;
