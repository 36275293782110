import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#41C7C0'
    }
  },
  spacing: 8,
  typography: {
    fontFamily: "'Rawline', 'sans-serif'",
    h1: {
      fontSize: '1.5rem',
      marginBottom: '24px'
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: '700',
      color: '#A7A7A7'
    },
    caption: {
      fontSize: '0.8125rem',
      color: '#A7A7A7'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: 'none',
          textTransform: 'none',
          lineHeight: '2',
          minWidth: '125px'
        },
        contained: {
          color: '#fff'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginLeft: 0
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff !important',
          color: '#000'
        },
        input: {
          paddingLeft: '1rem',
          backgroundColor: '#fff !important',
          paddingTop: '10px',
          paddingBottom: '10px'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: '0',
          marginBottom: '0'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '0'
          // height: '2.5rem'
        }
      }
    }
  }
});

export default theme;
