import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box } from '@mui/material';
import { CodeBlock, github } from 'react-code-blocks';
import Infobox from '../Infobox';
import iconMintMe from '../../../Resources/Instructions/icon_mintme.svg';
import s from '../Instructions.module.scss';

const HowToIntegrateMintMe = () => {
  const { t } = useTranslation();
  const codeTheme = github;

  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('programming_guide_how_to_integrate_')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Infobox
            image={iconMintMe}
            title={t('what_is_mintme?')}
            text={t('mintme_allows_you_to_create_your_')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_this_guide_about?')}
            text={t('this_guide_is_intended_to_describe_how_to_integrate_')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Infobox
            icon="faQuestionCircle"
            title={t('who_needs_this_guide?')}
            text={t('this_guide_is_aimed_at_project_managers_')}
          />
        </Grid>
      </Grid>
      <Typography variant="h3">
        {t('what_can_be_done_before_we_start?')}
      </Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: t('what_can_be_done_before_we_start_text')
        }}
      />
      <Typography variant="h3">{t('steps_to_create_your_own_nft')}</Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: t('steps_to_create_your_own_nft_text')
        }}
      />
      <Typography variant="h3">
        {t('upload_a_multimedia_content_file_')}
      </Typography>
      <Typography variant="body1" mb={3}>
        {t('upload_a_multimedia_content_file_text_1')}
      </Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: t('upload_a_multimedia_content_file_text_2')
        }}
        mb={3}
      />
      <Typography variant="body1" mb={3}>
        {t('example_of_request')}:
      </Typography>
      <Box className={s.codeStyle} mb={3} ml={5}>
        <CodeBlock
          text={`curl --location --request POST 'https://mintme.global/api/upload' \\
--header 'Content-Type: image/png' \\
--data-binary '@/D:/Images/MyBestImage.png'`}
          language={'bash'}
          showLineNumbers={false}
          theme={codeTheme}
        />
      </Box>
      <Typography variant="body1" mb={3}>
        {t('example_of_answer')}:
      </Typography>
      <Box className={s.codeStyle} mb={3} ml={5}>
        <CodeBlock
          text="bafybeih7aq66nprssefig44cnljrl4vny7vqj76zezhaowhmwpxcsyzib4"
          language={'bash'}
          showLineNumbers={false}
          theme={codeTheme}
        />
      </Box>
      <Typography variant="body1">
        {t('by_the_end_of_this_step_you_should_have_')}
      </Typography>
      <Typography variant="h3">{t('create_a_json_file_')}</Typography>
      <Typography variant="body1" mb={3}>
        {t('secondly_you_need_to_draw_up_the_json_file_')}
      </Typography>
      <Box className={s.codeStyle} mb={3} ml={5}>
        <CodeBlock
          text={`{
  "title": "Asset Metadata",
  "type": "object",
  "properties": {
    "name": {
      "type": "string",
      "description": "Identifies the asset to which this NFT represents"
    },
    "description": {
      "type": "string",
      "description": "Describes the asset to which this NFT represents"
    },
    "image": {
      "type": "string",
      "description": "A URI pointing to a resource with mime type image/* representing the asset to which this NFT's cover represents. Consider making any images at a width between 320 and 1080 pixels and aspect ratio between 1.91:1 and 4:5 inclusive. It can be http/https URI, and IPFS URI typed in two ways: https://ipfs.io/ipfs/QmTgqnhFBMkfT9s8PHKcdXBn1f5bG3Q5hmBaR4U6hoTvb1?filename=Chainlink_Elf.png or ipfs://QmTgqnhFBMkfT9s8PHKcdXBn1f5bG3Q5hmBaR4U6hoTvb1"
    },
    "external_url": {
      "type": "string",
      "description": "This is the URL that will appear below the asset's image on OpenSea and will allow users to leave OpenSea and view the item on MintMe site. It must be a link, which follows directly to the token detailed on mintme.global, like https://mintme.global/token/matic/0xc02d332AbC7f9E755e2b1EB56f6aE21A7Da4B7AD/ 89893785-7f55-4e09-9bf5-fa955c53cfce"
    },
    "animation_url": {
      "type": "string",
      "description": "A URL to a multi-media attachment for the item. The file extensions GLTF, GLB, WEBM, MP4, M4V, OGV, and OGG are supported, along with the audio-only extensions MP3, WAV, and OGA. It can be http/https URI, and IPFS URI typed in two ways: https://ipfs.io/ipfs/QmTgqnhFBMkfT9s8PHKcdXBn1f5bG3Q5hmBaR4U6hoTvb1?filename=Chainlink_Elf.png or ipfs://QmTgqnhFBMkfT9s8PHKcdXBn1f5bG3Q5hmBaR4U6hoTvb1"
    },
    "content": {
      "type": "string",
      "description": "A URL to content attachment of NFT. It always presents if the token has content. It can duplicate anumation_url value or be without anumation_url in case of content type is image, not animation"
    }
  }
}`}
          language={'json'}
          showLineNumbers={false}
          theme={codeTheme}
          wrapLines
        />
      </Box>
      <Typography variant="body1" mb={3}>
        {t('example_of_json')}:
      </Typography>
      <Box className={s.codeStyle} mb={3} ml={5}>
        <CodeBlock
          text={`{
  "name": "My token name",
  "description": "My token description",
  "image": "https://ipfs.io/ipfs/bafybeih7aq66nprssefig44cnljrl4vny7vqj76zezhaowhmwpxcsyzib4",
  "content": "https://ipfs.io/ipfs/bafybeif2dizf76bcv4q44ql7lkeemkwluels6b7zq4ietywoacrjsymzbq",
  "animation_url": "https://ipfs.io/ipfs/bafybeif2dizf76bcv4q44ql7lkeemkwluels6b7zq4ietywoacrjsymzbq",
  "external_url": "https://mintme.global/token/mumbai/0x18c16f4e03c965f1ad17d09543be9a59530e9880/89893785-7f55-4e09-9bf5-fa955c53cfce"
}`}
          language={'json'}
          showLineNumbers={false}
          theme={codeTheme}
          wrapLines
        />
      </Box>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: t('lets_consider_this_example_in_detail_')
        }}
        mb={3}
      />
      <Box className={s.codeStyle} mb={3} ml={5}>
        <CodeBlock
          text={`https://mintme.global/token/BLOCKCHAIN_NAME/CONTRACT_ADDRESS/UUID`}
          language={'json'}
          showLineNumbers={false}
          theme={codeTheme}
          wrapLines
        />
      </Box>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: t('blockchain_name_the_brief_name_of_blockchain')
        }}
        mb={3}
      />
      <Typography variant="body1" mb={3}>
        <ul>
          <li>ethereum</li>
          <li>polygon</li>
          <li>bsc</li>
          <li>ropsten</li>
          <li>mumbai</li>
          <li>bsc-test</li>
        </ul>
      </Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: t('contract_address_the_address_')
        }}
        mb={3}
      />
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: t('uuid_randomly_generated_uuid_')
        }}
        mb={3}
      />
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: t('finally_we_need_to_upload_our_json_to_ipfs_')
        }}
        mb={3}
      />
      <Typography variant="h3">
        {t('create_nft_by_calling_the_smartcontract_function')}
      </Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: t('so_high_time_to_create_your_first_nft_')
        }}
        mb={3}
      />
      <Box className={s.codeStyle} mb={3} ml={5}>
        <CodeBlock
          text={`import { ethers } from 'ethers';
import { MintMeAbi } from './MintMeAbi';
  
const yourCollectionAddress = "0x18c16f4e03c965f1ad17d09543be9a59530e9880";
const provider = new ethers.providers.Web3Provider(window.ethereum);
const contract = new ethers.Contract(yourCollectionAddress, MintMeAbi, provider);
const signer = provider.getSigner();
const withSigner = contract.connect(signer);
const feeWei = "100000000000000000"; // Check the price of creation in selected blockchain
const value = ethers.utils.parseUnits(feeWei, 0);
const userAddress = "0xCbd1b14cCE9e973b55abF5991faB19205D656DDD";
const contentCID = "bafkreiajxmbppqf5g7pibxt6q426uzkmibwanlem4237f5pxtwangglu2i" // JSON’s CID
withSigner.mint(userAddress, contentCID, {value})
  .then((r, e) => {});`}
          language={'javascript'}
          showLineNumbers={false}
          theme={codeTheme}
        />
      </Box>
      <Typography variant="body1">
        {t('sign_transaction_in_metamask_and_enjoy_your_first_nft_')}
      </Typography>
    </div>
  );
};

export default HowToIntegrateMintMe;
