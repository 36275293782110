import { reaction } from 'mobx';
import { oc } from 'ts-optchain';
import { stores } from '../Stores/RootStore';
import { MetamaskState } from '../Stores/MetaMaskStore';
import { RemoteDataState } from '../Utils/RemoteData';
import { debounce } from 'lodash';

const { metaMaskStore, collectionsStore, promoStore } = stores;

const getDataVerifyByProver = debounce(() => {
  collectionsStore.getDataVerifyByProver();
}, 1000);

reaction(
  () => [metaMaskStore.state, metaMaskStore.currentAccount],
  () => {
    if (
      metaMaskStore.state !== MetamaskState.Connected ||
      !metaMaskStore.currentAccount
    )
      return;
    collectionsStore.getOwnerCollections(metaMaskStore.currentAccount);
    collectionsStore.getCollectionsTokenOwner(metaMaskStore.currentAccount);
    collectionsStore.getTransactions(metaMaskStore.currentAccount);
  }
);

reaction(
  () => metaMaskStore.chainData,
  chainData => {
    if (chainData) {
      collectionsStore.setChain(chainData);
      promoStore.setChain(chainData);
    }
  }
);

reaction(
  () => metaMaskStore.currentAccount,
  currentAccount => {
    collectionsStore.setCurrentAccount(currentAccount);
    promoStore.setCurrentAccount(currentAccount);
  }
);

reaction(
  () => [
    collectionsStore.token.state,
    collectionsStore.collections.state,
    collectionsStore.collectionsTokenOwner.state,
    collectionsStore.allCollections.state
  ],
  () => {
    getDataVerifyByProver();
  }
);

reaction(
  () => [metaMaskStore.provider, metaMaskStore.config.state],
  () => {
    if (
      metaMaskStore.provider &&
      metaMaskStore.config.state === RemoteDataState.SUCCESS
    ) {
      const contract = oc(metaMaskStore).config.value.contract('');
      if (contract) {
        collectionsStore.getBaseURI(metaMaskStore.provider, contract);
        collectionsStore.getFeeCreateCollection(
          metaMaskStore.provider,
          contract
        );
      }
    }
  }
);

reaction(
  () => [metaMaskStore.provider, metaMaskStore.configProveMe.state],
  () => {
    if (
      metaMaskStore.provider &&
      metaMaskStore.configProveMe.state === RemoteDataState.SUCCESS
    ) {
      const contract = oc(metaMaskStore).configProveMe.value.contract('');
      if (contract) {
        collectionsStore.getFeeVerifyByProver(metaMaskStore.provider, contract);
        collectionsStore.setContractProveMe(contract);
      }
    }
  }
);
