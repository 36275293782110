export const TOKENS_FIELDS = `
id
tokenId
name
description
image
external_url
animation_url
content
collection {
  id
  licenseCID
  name
}
contentCID
owner
timeCreation
timeChanging`;

const HIDDEN_AND_BANNED = `
  hiddenAddresses(first: 1000) {
    id
  }
  bannedAddresses(first: 1000) {
    id
  }
`;

export const GET_OWNER_COLLECTIONS = `
query getOwnerCollections($collectionId: ID, $first: Int, $owner: Bytes) {
  collections(first: $first, where: { owner: $owner, id_gt: $collectionId }) {
    id
    creator
    contentCID
    licenseCID
    name
    symbol
    owner
    tokens(first: $first) {
      ${TOKENS_FIELDS}
    }
    image
  }
  ${HIDDEN_AND_BANNED}
}`;

export const COLLECTIONS_TOKEN_OWNER = `
query getCollectionsTokenOwner($collectionId: ID, $first: Int, $owner: Bytes) {
  collections(first: $first, where: { owner_not_in: [$owner], id_gt: $collectionId }) {
    id
    creator
    contentCID
    licenseCID
    name
    symbol
    owner
    tokensOwner: tokens(first: $first, where: { owner: $owner }) {
      ${TOKENS_FIELDS}
    }
    tokens(first: $first) {
      ${TOKENS_FIELDS}
    }
    image
  }
}`;

export const ALL_COLLECTIONS = `
query getAllCollections($collectionId: ID, $first: Int) {
  collections(first: $first, where: { id_gt: $collectionId }) {
    id
    creator
    contentCID
    licenseCID
    name
    symbol
    owner
    tokens(first: $first) {
      ${TOKENS_FIELDS}
    }
    image
  }
  ${HIDDEN_AND_BANNED}
}`;

export const COLLECTION_BY_ID = `
query getCollectionById($id: ID, $first: Int, $tokenId: ID) {
  collection(id: $id) {
    id
    creator
    contentCID
    licenseCID
    name
    symbol
    owner
    tokens(first: $first, where: {id_gt: $tokenId}) {
      ${TOKENS_FIELDS}
    }
    image
  }
  ${HIDDEN_AND_BANNED}
}`;
