import React from 'react';
import MetaMaskStore from './MetaMaskStore';
import CollectionsStore from './CollectionsStore';
import PromoStore from './PromoStore';

export const stores = {
  metaMaskStore: new MetaMaskStore(),
  collectionsStore: new CollectionsStore(),
  promoStore: new PromoStore()
};

export const storesContext = React.createContext(stores);
export const useStores = () => React.useContext(storesContext);
