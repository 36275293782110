import React, { useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Switch
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Stores/RootStore';

import s from './Dialog.module.scss';

const VisibilityDialog = ({
  address,
  hidden
}: {
  address: string;
  hidden: boolean;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [hiddenCollection, setHiddenCollection] = useState(hidden);
  const { metaMaskStore, collectionsStore } = useStores();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggle = (e: any) => {
    setHiddenCollection(!e.target.checked);
  };

  const handleYes = () => {
    if (metaMaskStore.config.value?.mintMePerm) {
      collectionsStore.hideCollection(
        hiddenCollection,
        metaMaskStore.config.value.mintMePerm,
        address
      );
    }
    setOpen(false);
  };

  return (
    <div>
      <Typography
        className={s.titleBtnOpen}
        variant="body2"
        sx={{ color: 'primary.main' }}
        onClick={handleClickOpen}
      >
        {hidden ? t('only_owner') : t('everybody')}
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <DialogContent className={s.dialog}>
          <Box className={s.switchBox}>
            <Typography>{t('only_owner')}</Typography>
            <Switch onClick={toggle} checked={!hiddenCollection} />
            <Typography>{t('everybody')}</Typography>
          </Box>
        </DialogContent>
        <DialogActions className={s.dialogActions}>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleYes}
            disabled={hidden === hiddenCollection}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VisibilityDialog;
