import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { oc } from 'ts-optchain';
import { useStores } from '../../Stores/RootStore';
import { observer } from 'mobx-react-lite';
import { RemoteDataState } from '../../Utils/RemoteData';
import Token from '../../Components/Token/Token';
import { t } from 'i18next';
import { configNetList } from '../../configNetList';

const ENV_APP = window.config.ENV_APP as 'test' | 'prod';

const DetailNftReadOnly = () => {
  const { net, collectionId, uuid } = useParams();
  const navigate = useNavigate();
  const { metaMaskStore, collectionsStore } = useStores();
  const { baseSiteURI, siteURI, getTokenByExternalUrl } = collectionsStore;
  const { config } = metaMaskStore;
  let nft: Nullable<TokenTypeTg> = collectionsStore.token.value;

  if (
    metaMaskStore.state === 3 &&
    nft?.owner === metaMaskStore.currentAccount
  ) {
    navigate(`/${net}/token/${collectionId}/${nft.tokenId}`, { replace: true });
  }

  useEffect(() => {
    if (net) {
      const chainData = configNetList[ENV_APP].find(i => i.nameForUrl === net);
      if (chainData) {
        collectionsStore.setChain(chainData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !net ||
    !collectionId ||
    !uuid ||
    !collectionsStore.chainData?.tgUriMintMe
  ) {
    return <CircularProgress size={32} />;
  }

  if (collectionsStore.token.state === RemoteDataState.INITIAL) {
    getTokenByExternalUrl(
      `${siteURI}/token/${net}/${collectionId}/${uuid}`,
      collectionId
    );
    return <CircularProgress size={32} />;
  }

  if (!nft && collectionsStore.token.state === RemoteDataState.SUCCESS) {
    return <Typography variant="h1">{t('token_not_found')}</Typography>;
  } else if (!nft && collectionsStore.token.state === RemoteDataState.REQUEST) {
    return <CircularProgress size={32} />;
  }

  if (!nft) return null;

  const verifyData = oc(collectionsStore)
    .tokensVerifyDataTg.value([])
    .find((i: TokenVerifyDataTgType) => i.tokenId === nft?.id);

  return (
    <React.Fragment>
      <Token
        collectionId={collectionId}
        id={nft.tokenId}
        status={oc(verifyData).status(null)}
        nft={nft}
        licenses={oc(config).value.licenses([])}
        baseURI={baseSiteURI}
        chainData={collectionsStore.chainData}
        readonly={true}
        path={`/showroom/${net}`}
      />
    </React.Fragment>
  );
};

export default observer(DetailNftReadOnly);
