import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InstructionMenu from './InstructionMenu';
import SmartcontractsAndSupportedNetworks from './Pages/SmartcontractsAndSupportedNetworks';
import HowToStartWithMintMe from './Pages/HowToStartWithMintMe';
import HowToCreateCollection from './Pages/HowToCreateCollection';
import HowSeeCollectionOnOpensea from './Pages/HowSeeCollectionOnOpensea';
import HowToIntegrateMintMe from './Pages/HowToIntegrateMintMe';
import CustomContainer from '../CustomContainer/CustomContainer';
import HowToTransferCollectionOrNFT from './Pages/HowToTransferCollectionOrNFT';

const Instructions = () => {
  return (
    <CustomContainer>
      <Routes>
        <Route
          path="/smartcontracts-and-supported-networks"
          element={<SmartcontractsAndSupportedNetworks />}
        />
        <Route
          path="/how-to-start-with-mintme"
          element={<HowToStartWithMintMe />}
        />
        <Route path="/" element={<InstructionMenu />} />
        <Route
          path="/how-to-create-collection"
          element={<HowToCreateCollection />}
        />
        <Route
          path="/how-to-transfer"
          element={<HowToTransferCollectionOrNFT />}
        />
        <Route
          path="/how-see-collection-on-opensea"
          element={<HowSeeCollectionOnOpensea />}
        />
        <Route
          path="/how-to-integrate-mintme"
          element={<HowToIntegrateMintMe />}
        />
      </Routes>
    </CustomContainer>
  );
};

export default Instructions;
