import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Link, CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import { Link as RouterLink } from 'react-router-dom';
import { useStores } from '../../Stores/RootStore';
import { toShortAddress } from '../../Helpers/helpers';
import documentDownloadImg from '../../Resources/document-download.svg';
// import verifiedIconImg from '../../Resources/verified_green.svg';
// import notVerifiedIconImg from '../../Resources/verified_grey.svg';
import personIconImg from '../../Resources/personIcon.svg';
import userIconImg from '../../Resources/userIcon.svg';
// import ButtonLink from '../../Components/ButtonLink/ButtonLink';
import photoImg from '../../Resources/photo.svg';
import TransferCollection from '../../Components/Dialogs/TransferCollection';
import EditCollectionDialog from '../../Containers/Forms/EditCollectionDialog/EditCollectionDialog';
import { getTokensCount } from '../../Helpers/helpers';
import VisibilityDialog from '../../Components/Dialogs/VisibilityDialog';
import { checkCid } from '../../Utils/Storage';
import s from './MyCollectionCard.module.scss';

interface MyCollectionCardProps {
  collection: CollectionTypeTg;
  path?: string;
  showroom?: boolean;
}

const MyCollectionCard: React.FC<MyCollectionCardProps> = ({
  collection,
  path = '',
  showroom = false
}) => {
  const { metaMaskStore, collectionsStore } = useStores();
  const { baseSiteURI } = collectionsStore;
  const { t } = useTranslation();
  const { id, image, name, licenseCID, owner, symbol, hidden } = collection;
  const myCollection = owner === metaMaskStore.currentAccount;
  const license = oc(metaMaskStore)
    .config.value.licenses([])
    .find(i => i.file_cid === licenseCID);

  const licenseName = license?.title ? license.title : t('custom');
  // const verified = false;

  const tokensCount = getTokensCount(
    collection.tokens,
    metaMaskStore.currentAccount
  );
  return (
    <div className={s.main}>
      <RouterLink to={`${path}/collection/${id}`}>
        <CardMedia
          component="img"
          height="168"
          image={image ? baseSiteURI.concat(image) : photoImg}
          alt=""
        />
      </RouterLink>
      <Box m={2} className={s.bottom}>
        <Box my={1} className={s.flexsBetween}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {name} ({symbol})
          </Typography>
          {myCollection && (
            // <Typography variant="body2" sx={{ color: 'primary.main' }}>
            //   <Link component={RouterLink} to={`/edit-collection/${id}`}>
            //     {t('edit')}
            //   </Link>
            // </Typography>
            <EditCollectionDialog collectionId={id} />
          )}
        </Box>
        {!showroom &&
          (myCollection ? (
            <Box my={1} className={s.flexsBetween}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('visibility')}
              </Typography>
              <VisibilityDialog address={id} hidden={!!hidden} />
            </Box>
          ) : (
            <Box>&nbsp;</Box>
          ))}
        <Box my={1} className={s.flexsBetween}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('smartcontract')}
          </Typography>
          {!metaMaskStore.chainData ? (
            <Typography variant="body2">{toShortAddress(id, 8)}</Typography>
          ) : (
            <Link
              href={`${metaMaskStore.chainData.scanAddress}/${id}`}
              variant="body2"
              target="_blank"
            >
              {toShortAddress(id, 8)}
            </Link>
          )}
        </Box>
        <Box my={1} className={s.flexStart}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} mr={1}>
            {t('license')}
          </Typography>
          {checkCid(licenseCID) ? (
            <>
              <Typography variant="body2" mr={1}>
                {licenseName}
              </Typography>
              <Link href={baseSiteURI.concat(licenseCID)} target="_blank">
                <img src={documentDownloadImg} alt="" />
              </Link>
            </>
          ) : (
            <Typography variant="body2" mr={1}>
              {t('none')}
            </Typography>
          )}
        </Box>
        <Box my={1} mb={3}>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary', fontWeight: 'bold' }}
            mr={1}
          >
            {t('Owned_of_items', { ...tokensCount })}
          </Typography>
        </Box>
        {/* {verified ? (
          <Box my={3} className={s.flexCenter}>
            <img src={verifiedIconImg} alt="" />
            <Typography variant="body2" sx={{ fontWeight: 'bold' }} ml={1}>
              {t('verified')}
            </Typography>
          </Box>
        ) : myCollection ? (
          <Box my={3}>
            <ButtonLink
              to=""
              title={t('verify_contract_cur', { value: 50, currency: metaMaskStore.chainData?.currency.toUpperCase() })}
              fullWidth
            />
          </Box>
        ) : (
          <Box my={3} className={s.flexCenter}>
            <img src={notVerifiedIconImg} alt="" />
            <Typography variant="body2" sx={{ fontWeight: 'bold' }} ml={1}>
              {t('not_verified')}
            </Typography>
          </Box>
        )} */}
        {myCollection ? (
          <Box my={1} className={s.flexsBetween}>
            <div className={s.flexStart}>
              <img src={personIconImg} alt="" />
              <Typography variant="body2" sx={{ fontWeight: 'bold' }} ml={1}>
                {t('my_collection')}
              </Typography>
            </div>
            <TransferCollection collectionId={collection.id} />
          </Box>
        ) : (
          <Box className={s.flexStart}>
            <img src={userIconImg} alt="" />
            {!metaMaskStore.chainData ? (
              <Typography variant="body2">
                {toShortAddress(owner, 8)}
              </Typography>
            ) : (
              <Link
                href={`${metaMaskStore.chainData.scanAddress}/${owner}`}
                variant="body2"
                target="_blank"
                ml={1}
              >
                {toShortAddress(owner, 8)}
              </Link>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default observer(MyCollectionCard);
