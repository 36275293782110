import React from 'react';
import { useStores } from '../../Stores/RootStore';
import { Typography, Box } from '@mui/material';
import { t } from 'i18next';
import { ethers } from 'ethers';
import { oc } from 'ts-optchain';
import { NEED_REFERRALS } from './Promo';
import { shortAddress, dateStrFromTs3 } from '../../Helpers/helpers';
import ReferralsPopper from './ReferralsPopper';
import s from './Promo.module.scss';
import './Promo.scss';

const Referrals = ({
  haveMintCoin,
  promoter,
  promoterCollectionId
}: {
  haveMintCoin: boolean;
  promoter: Nullable<PromoterType>;
  promoterCollectionId: Nullable<string>;
}) => {
  const { promoStore } = useStores();

  if (!haveMintCoin || !promoter || !promoterCollectionId) return null;
  const restForUnlock =
    promoter?.restForUnlock != null ? +promoter.restForUnlock : NEED_REFERRALS;

  return (
    <div>
      {restForUnlock === 0 ? (
        <Typography variant="body1" mb={2}>
          {t('sell_your_mintcoin_as_fast_')}
        </Typography>
      ) : (
        <>
          <Typography variant="body1" mb={2}>
            {t('to_unlock_selling_your_referrals_')}
          </Typography>
          <ReferralsPopper promoter={promoter} />
          <Typography variant="body1" mb={2}>
            {t('just_more_purchase_to_unlock_', {
              value: promoter.restForUnlock
            })}
          </Typography>
        </>
      )}
      {!!promoter?.tokenPrice && (
        <Box mt={4}>
          <button
            disabled={restForUnlock !== 0}
            className="pm-button"
            onClick={() => {
              promoStore.contractSell(promoterCollectionId);
            }}
          >
            {t('sell_for_matic', {
              matic: ethers.utils.formatUnits(promoter.tokenPrice, 18)
            })}
          </button>
        </Box>
      )}

      <YourReferrals promoter={promoter} />
      {restForUnlock === NEED_REFERRALS && (
        <Typography variant="h5" mt={3} mb={3}>
          {t('invite_referrals_for_unlock_', { value: NEED_REFERRALS })}
        </Typography>
      )}
    </div>
  );
};

const YourReferrals = ({ promoter }: { promoter: Nullable<PromoterType> }) => {
  return (
    <div>
      <Typography variant="h5" mt={3} mb={3}>
        {t('your_referrals')}
      </Typography>
      <table className={s.yourReferralsTable}>
        <tbody>
          <tr>
            <th>{t('referral')}</th>
            <th>{t('last_purchase_time')}</th>
            <th>{t('last_purchase_amount')}</th>
          </tr>
          {oc(promoter)
            .referrals([])
            .map((i: PromoterType) => {
              const timeFormat = i.lastBuyAction?.time
                ? dateStrFromTs3(+i.lastBuyAction.time)
                : '';
              const amount = i.lastBuyAction?.tokenCost
                ? `${ethers.utils.formatUnits(
                    i.lastBuyAction.tokenCost,
                    18
                  )} ${t('matic')}`
                : '';
              return (
                <tr key={i.id}>
                  <td>{shortAddress(i.id)}</td>
                  <td>{timeFormat}</td>
                  <td>{amount}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Referrals;
