import React from 'react';
import { Link, Box, Typography } from '@mui/material';
import cx from 'classnames';
import { t } from 'i18next';
import { oc } from 'ts-optchain';
import { ethers } from 'ethers';
import cardImg1 from '../../Resources/Promo/card-1.png';
import cardImg2 from '../../Resources/Promo/card-2.png';
import cardImg3 from '../../Resources/Promo/card-3.png';
import cardImg2_1 from '../../Resources/Promo/card-2-1.png';
import cardImg2_2 from '../../Resources/Promo/card-2-2.png';
import cardImg2_3 from '../../Resources/Promo/card-2-3.png';
import dinosaurImg from '../../Resources/Promo/dinosaur.png';
import goatImg from '../../Resources/Promo/goat.png';
import BuyDialog from './Dialogs/BuyDialog';
import QuestDialog from './Dialogs/QuestDialog';
import CopyLinkDialog from './Dialogs/CopyLinkDialog';
import BuyPromoteDialog from './Dialogs/BuyPromoteDialog';
import { MathRound10 } from '../../Helpers/decimalAdjust';
import { FIRST_TO_UP } from './Promo';
import './Promo.scss';
import s from './Promo.module.scss';

export const Progress = ({
  value,
  total,
  title,
  green = false
}: {
  value: number;
  total: number;
  title?: string;
  green?: boolean;
}) => {
  const percent = MathRound10((100 * value) / total, 0);
  return (
    <Box className={s.progressBar}>
      {!!title && (
        <h3>
          {title}: <strong>{percent}%</strong>
        </h3>
      )}
      <Typography variant="body1" className={s.progressBarText}>
        {value} of {total}
      </Typography>
      <div className="progress-100 progressV2">
        <div
          className={cx('progress-x', green && 'green')}
          style={{ width: `${percent}%` }}
        ></div>
      </div>
    </Box>
  );
};

export const BorderBox = ({
  promoter,
  decimals,
  price,
  freeMintCoin
}: {
  promoter: Nullable<PromoterType>;
  decimals: Nullable<number>;
  price: Nullable<string>;
  freeMintCoin: boolean;
}) => {
  const haveMintCoin = promoter?.hasToken;
  if (haveMintCoin && decimals) {
    const bought = ethers.utils.formatUnits(
      oc(promoter).tokenCost('0'),
      decimals
    );
    const canSell = ethers.utils.formatUnits(
      oc(promoter).tokenPrice('0'),
      decimals
    );
    return (
      <div className={cx(s.borderBox, s.haveCoin)}>
        <h1>{t('you_have_mintcoin')}</h1>
        <p className="text-gradient huge">1</p>
        <p
          dangerouslySetInnerHTML={{
            __html:
              bought === '0.0'
                ? t('bought_for_free')
                : t('bought_for_matic', { value: bought })
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t('can_sell_for_matic', {
              value: canSell
            })
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t('easy_x_profit', {
              value: +bought === 0 ? '' : 'x3'
            })
          }}
        />
        <img className={s.bgImageBorderBox} src={goatImg} alt="" />
      </div>
    );
  }
  if (!haveMintCoin && !freeMintCoin && price) {
    return (
      <div className={cx(s.borderBox)}>
        <h1>{t('you_don_t_have_mintcoin')}</h1>
        <p className="text-gradient huge">0</p>
        <p
          dangerouslySetInnerHTML={{
            __html: t('you_can_buy_it_for_matic', { value: price })
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t('available_to_sale_for_matic', {
              value: MathRound10(+price * 3, -1)
            })
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t('easy_x_profit', {
              value: 'x3'
            })
          }}
        />
      </div>
    );
  }
  return (
    <div className={cx(s.borderBox)}>
      <h1>{t('you_don_t_have_mintcoin')}</h1>
      <p className="text-gradient huge">0</p>
      <p
        dangerouslySetInnerHTML={{
          __html: t('get_it_for_free')
        }}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: t('sell_it_for_matic', {
            matic:
              price && +price === 0
                ? MathRound10(FIRST_TO_UP * 3, -1)
                : price && MathRound10(+price * 3, -1)
          })
        }}
      />
      <p>{t('easy_profit')}!</p>
    </div>
  );
};

export const ButtonPromo = ({
  label,
  handleClick,
  disabled
}: {
  label: string;
  handleClick: () => void;
  disabled: boolean;
}) => {
  return (
    <button className="pm-button" onClick={handleClick} disabled={disabled}>
      {label}
    </button>
  );
};

export const NoSell = ({ text }: { text: string }) => {
  return (
    <span className="nosell">
      <span className="icon">{'!'}</span>
      <span className="text">{text}</span>
    </span>
  );
};

const Card = ({
  className,
  title,
  ctoa,
  btn,
  img
}: {
  className: string;
  title: string;
  ctoa?: string;
  btn?: () => JSX.Element;
  img: string;
}) => {
  return (
    <div className={`card ${className}`}>
      <main>
        <div>
          <h3>{title}</h3>
          {!!ctoa && <p>{ctoa}</p>}
        </div>
        {!!btn && btn()}
      </main>
      <aside>
        <img src={img} alt="" />
      </aside>
    </div>
  );
};

const LinkChat = () => (
  <Link
    target="_blank"
    variant="button"
    className="card-btn"
    href="https://t.me/mintme_promo"
  >
    {t('chat')}
  </Link>
);

export const Footer = ({
  refLinkPrice,
  disabledRefLink
}: {
  refLinkPrice: Nullable<string>;
  disabledRefLink: boolean;
}) => {
  return (
    <footer className="promo__footer">
      <Card
        className="one"
        title={t('buy_referral_link')}
        ctoa={t('buy_referral_link_for_matic', { value: refLinkPrice })}
        btn={() => BuyDialog({ refLinkPrice, disabled: disabledRefLink })}
        img={cardImg1}
      />

      <Card
        className="two"
        title="Community"
        ctoa="Subscribe our Telegram chat and ask for your referral link there"
        btn={LinkChat}
        img={cardImg2}
      />

      <Card
        className="three"
        title="Quest"
        ctoa="Complete the quest and get a referral link"
        btn={QuestDialog}
        img={cardImg3}
      />
    </footer>
  );
};

export const FooterHaveCoin = ({
  promoPrice,
  copyLink
}: {
  promoPrice: Nullable<string>;
  copyLink: string;
}) => {
  return (
    <div className={s.footerHaveCoin}>
      <footer className="promo__footer">
        <Card
          className="oneHaveCoin"
          title={t('promote_your_link_on_our_', { value: promoPrice })}
          ctoa=""
          btn={() => BuyPromoteDialog({ promoPrice })}
          img={cardImg2_1}
        />

        <Card
          className="twoHaveCoin"
          title={t('send_link_to_chat')}
          btn={LinkChat}
          ctoa=""
          img={cardImg2_2}
        />

        <Card
          className="threeHaveCoin"
          title={t('copy_referral_link')}
          ctoa=""
          btn={() => CopyLinkDialog({ copyLink })}
          img={cardImg2_3}
        />
      </footer>
      <img src={dinosaurImg} alt="" className={s.imageRight} />
    </div>
  );
};
