import React, { useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import { useStores } from '../../Stores/RootStore';
import MyCollectionCard from '../MyCollectionCard/MyCollectionCard';
import { configNetList } from '../../configNetList';
import HeaderLink from './HeaderLink';
import { RemoteDataState } from '../../Utils/RemoteData';
import s from './Collections.module.scss';
import CustomContainer from '../../Components/CustomContainer/CustomContainer';

const ENV_APP = window.config.ENV_APP as 'test' | 'prod';

const Collections = () => {
  const { t } = useTranslation();
  const { metaMaskStore, collectionsStore } = useStores();
  const { net } = useParams();
  const navigate = useNavigate();

  const metamaskNotInstall = metaMaskStore.state === 1;

  const setChain = (name: string) => {
    const chainData = configNetList[ENV_APP].find(i => i.nameForUrl === name);
    if (chainData) {
      metaMaskStore.setChainId(chainData.id);
    }
  };

  useEffect(() => {
    if (!metamaskNotInstall) return;
    if (net) {
      setChain(net);
    } else {
      navigate(`/showroom/${configNetList[ENV_APP][0].nameForUrl}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [net, metamaskNotInstall]);

  useEffect(() => {
    if (collectionsStore.chainData?.id) {
      collectionsStore.getAllCollections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsStore.chainData?.id]);

  const allCollections = oc(collectionsStore).allCollections.value([]);

  if (metaMaskStore.state === 0) {
    return <CircularProgress size={32} />;
  }

  const path = `/showroom/${collectionsStore.chainData?.nameForUrl}`;

  return (
    <React.Fragment>
      <CustomContainer className={s.main}>
        {metamaskNotInstall && net && (
          <Box className={s.topMenu}>
            {configNetList[ENV_APP].map(i => (
              <HeaderLink
                to={`/showroom/${i.nameForUrl}`}
                title={i.name}
                key={i.id}
              />
            ))}
          </Box>
        )}
        <Typography variant="h1">{t('collections')}</Typography>
        {collectionsStore.allCollections.state === RemoteDataState.REQUEST &&
        !allCollections.length ? (
          <CircularProgress size={32} />
        ) : (
          <Box mt={4} className={s.list}>
            {allCollections.length > 0 &&
              allCollections
                .filter(i => i.tokens.length > 1)
                .map(i => (
                  <MyCollectionCard
                    collection={i}
                    path={path}
                    showroom={true}
                    key={i.id}
                  />
                ))}
          </Box>
        )}
      </CustomContainer>
    </React.Fragment>
  );
};

export default observer(Collections);
