import React from 'react';
import { Typography } from '@mui/material';
import s from './Instructions.module.scss';

interface InfoboxProps {
  text: string;
  title?: string;
  icon?: string;
  num?: number;
  image?: string;
  component?: JSX.Element;
}

const Infobox: React.FC<InfoboxProps> = ({
  icon,
  title,
  text,
  num,
  image,
  component
}) => {
  return (
    <div className={s.infobox}>
      {title && <Typography variant="h4">{title}</Typography>}
      <div className={s.content}>
        {icon && <span className={s[icon]} />}
        {image && <img src={image} alt="" />}
        {num && (
          <div className={s.infoboxNum}>
            <Typography variant="body1">{num}</Typography>
          </div>
        )}
        <div className={s.infoboxContent}>
          <Typography variant="body1">
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Typography>
          {component ? component : null}
        </div>
      </div>
    </div>
  );
};

export default Infobox;
