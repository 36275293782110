import React from 'react';
import { Box, Typography, CardMedia, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { oc } from 'ts-optchain';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useStores } from '../../../Stores/RootStore';
import FieldLabel from '../../../Components/FieldLabel/FieldLabel';
import photoImg from '../../../Resources/photo.svg';
import arrowLeft from '../../../Resources/ArrowLeft.svg';
import { uploadJson, uploadImage } from '../../../Utils/Storage';
import { handleFileImage } from '../../../Utils/HandleFile';
import { MAXIMUM_FILE_SIZE, TOKENS, IMAGE_TYPES } from '../../../constants';
import s from '../Forms.module.scss';
import CustomContainer from '../../../Components/CustomContainer/CustomContainer';

const EditCollection = () => {
  const { t } = useTranslation();
  const { collectionId } = useParams();
  const { metaMaskStore, collectionsStore } = useStores();
  const { baseURI, collections, setContentCollection } = collectionsStore;

  const collection = oc(collections)
    .value([])
    .find(i => i.id === collectionId);

  if (!collection) return null;

  const licensesData = oc(metaMaskStore).config.value.licenses([]);
  const license = licensesData.find(i => i.file_cid === collection.licenseCID);
  const mintMeFactoryAddress = oc(metaMaskStore).config.value.contract('');
  const provider = oc(metaMaskStore).provider(null);

  if (!mintMeFactoryAddress || !provider) return null;

  return (
    <React.Fragment>
      <CustomContainer className={s.main}>
        <Formik
          initialValues={{
            symbol: collection.symbol,
            name: collection.name,
            select: TOKENS[0],
            license: license ? license.title : '',
            cover: {
              file: null,
              name: '',
              path: '',
              error: ''
            }
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (!values.cover.file) return;
            const imgCid = await uploadImage(values.cover.file);
            const contentCid = await uploadJson({ image: imgCid });
            if (contentCid) {
              setContentCollection(contentCid, provider, collection.id);
            }
            setSubmitting(false);
          }}
        >
          {({ submitForm, isSubmitting, setFieldValue, values }) => (
            <Form>
              <Typography variant="h1">
                <RouterLink to="/">
                  <img src={arrowLeft} alt="" />
                </RouterLink>{' '}
                {t('edit_collection')}
              </Typography>
              <Box mt={4} className={s.mainForm}>
                <Box>
                  <FieldLabel title={t('cover')} mb={0} />
                  <Typography variant="caption">
                    {t('file_types_supported_value', {
                      value: MAXIMUM_FILE_SIZE / 10 ** 6
                    })}
                  </Typography>
                  <input
                    accept={`${IMAGE_TYPES}`}
                    className={s.uploadInput}
                    id="uploadarea"
                    multiple
                    type="file"
                    name="cover"
                    onChange={e =>
                      setFieldValue('cover', handleFileImage(e) ?? values.cover)
                    }
                  />
                  <Box width="190px" my={1} className={s.boxImage}>
                    <label htmlFor="uploadarea">
                      <CardMedia
                        className={s.cardMedia}
                        component="img"
                        height="190"
                        image={
                          values.cover.path ||
                          (collection.image
                            ? baseURI.concat(collection.image)
                            : photoImg)
                        }
                        alt=""
                      />
                      {values.cover.error ? (
                        <Typography
                          variant="caption"
                          sx={{ color: 'error.main' }}
                        >
                          {t(values.cover.error)}
                        </Typography>
                      ) : (
                        <Typography variant="caption">
                          {values.cover.name}
                        </Typography>
                      )}
                    </label>
                  </Box>
                </Box>
                <Box>
                  <Box mb={3}>
                    <FieldLabel title={t('symbol')} />
                    <Field
                      disabled
                      component={TextField}
                      name="symbol"
                      type="text"
                      hiddenLabel
                      variant="filled"
                      size="small"
                      className={s.inputField}
                    />
                  </Box>
                  <Box mb={3}>
                    <FieldLabel title={t('name')} />
                    <Field
                      disabled
                      component={TextField}
                      name="name"
                      type="text"
                      hiddenLabel
                      variant="filled"
                      size="small"
                      className={s.inputField}
                    />
                  </Box>
                  <Box mb={3}>
                    <FieldLabel title={t('token_creation_standard')} />
                    <Field
                      disabled
                      className={s.inputField}
                      component={TextField}
                      type="text"
                      name="select"
                      variant="standard"
                      margin="normal"
                    ></Field>
                  </Box>
                  <Box mb={3}>
                    <FieldLabel title={t('license_file')} />
                    <Field
                      disabled
                      className={s.inputField}
                      component={TextField}
                      type="text"
                      name="license"
                      variant="standard"
                      margin="normal"
                    ></Field>
                  </Box>
                  <Box mt={5} className={s.boxSubmitBtn}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      {t('save')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </CustomContainer>
    </React.Fragment>
  );
};

export default observer(EditCollection);
