import { action, observable, makeObservable } from 'mobx';
import { PromoAbi } from '../Contracts/PromoAbi';
import { ethers } from 'ethers';
import {
  ApolloClient,
  InMemoryCache,
  gql,
  NormalizedCacheObject
} from '@apollo/client';
import {
  createRemoteData,
  handleTgRemoteData,
  handleTgRemoteDataPage,
  handleContractRemoteData
} from '../Utils/RemoteData';
import {
  CONFIGURATION_QUERY,
  PROMOTER_QUERY,
  PROMOTERS_QUERY_LINK_PROMOTED
} from '../Querys/promo';
import MetaMaskStore from './MetaMaskStore';
import {
  STORAGE_PENDING_TRANSACTIONS,
  UPDATE_INTERVAL,
  ZERO_ADDRESS
} from '../constants';
import {
  POLYGON_URI_PROMO_TEST,
  POLYGON_URI_PROMO_PROD
} from '../configNetList';

const ENV_APP = window.config.ENV_APP as ENV_APP_TYPE;
const FIRST = 1000;

class PromoStore {
  @observable contract: null | ethers.Contract = null;
  @observable client: null | ApolloClient<NormalizedCacheObject> = null;
  @observable chainData: Nullable<ChainDataType> = null;

  @observable buyMintCoinRd = createRemoteData<string>();
  @observable buyLinkRd = createRemoteData<string>();
  @observable sellMintCoinRd = createRemoteData<string>();
  @observable promoteMeRd = createRemoteData<string>();

  @observable configuration = createRemoteData<ConfigurationPromoType>();
  @observable promoter = createRemoteData<PromoterType>();
  // @observable promoters = createRemoteData<PromoterType[]>([]);
  @observable promotersLinkPromoted = createRemoteData<PromoterType[]>([]);

  @observable transactions: TransactionHashStatusType[] = [];
  @observable currentAccount = '';

  constructor() {
    makeObservable(this);
    this.getConfiguration();
    setInterval(() => this.updateRequests(), UPDATE_INTERVAL);
  }

  executeInitialRequests = () => {
    this.getPromotersLinkPromoted();
  };

  updateRequests = () => {
    this.getConfiguration();
    this.getPromotersLinkPromoted();
    if (this.currentAccount) {
      this.getPromoter(this.currentAccount);
    }
  };

  @action
  async setCurrentAccount(currentAccount: string) {
    this.currentAccount = currentAccount;
    if (currentAccount) {
      this.getPromoter(currentAccount);
    }
  }

  @action
  checkClient = () => {
    if (this.client) {
      this.client.cache.reset();
    } else {
      if (this.chainData?.tgUriPromo) {
        this.client = new ApolloClient({
          uri: this.chainData.tgUriPromo,
          cache: new InMemoryCache()
        });
      } else {
        this.client = new ApolloClient({
          uri:
            ENV_APP === 'test'
              ? POLYGON_URI_PROMO_TEST
              : POLYGON_URI_PROMO_PROD,
          cache: new InMemoryCache()
        });
      }
    }
  };

  @action
  setChain(chainData: ChainDataType) {
    this.client = null;
    this.chainData = chainData;
    this.executeInitialRequests();
  }

  @action
  async getConfiguration() {
    this.checkClient();
    if (!this.client) return;
    const id = '0';
    const client = this.client;
    return handleTgRemoteData(
      this.configuration,
      async () =>
        client.query({
          query: gql`
            ${CONFIGURATION_QUERY}
          `,
          variables: { id }
        }),
      data => {
        const result = (data as { configuration: ConfigurationPromoType })
          .configuration;
        return result;
      }
    );
  }

  // @action
  // async getPromoters() {
  //   this.checkClient();
  //   if (!this.client) return;
  //   const client = this.client;
  //   const first = FIRST;
  //   return handleTgRemoteDataPage(
  //     this.promoters,
  //     async (id: string) =>
  //       client.query({
  //         query: gql`
  //           ${PROMOTERS_QUERY}
  //         `,
  //         variables: { first, id }
  //       }),
  //     data => {
  //       const result: PromoterType[] = [];
  //       (data as { promoters: PromoterType[] }[]).forEach(i => {
  //         result.push(...i.promoters);
  //       });
  //       return result;
  //     },
  //     [],
  //     data => {
  //       const result = (data as { promoters: PromoterType[] }).promoters;
  //       if (Array.isArray(result) && result.length === first) {
  //         return result[result.length - 1].id;
  //       }
  //       return null;
  //     },
  //     '0'
  //   );
  // }

  @action
  async getPromotersLinkPromoted() {
    this.checkClient();
    if (!this.client) return;
    const client = this.client;
    const first = FIRST;
    return handleTgRemoteDataPage(
      this.promotersLinkPromoted,
      async (id: string) =>
        client.query({
          query: gql`
            ${PROMOTERS_QUERY_LINK_PROMOTED}
          `,
          variables: { first, id }
        }),
      data => {
        const result: PromoterType[] = [];
        (data as { promoters: PromoterType[] }[]).forEach(i => {
          result.push(...i.promoters);
        });
        return result;
      },
      [],
      data => {
        const result = (data as { promoters: PromoterType[] }).promoters;
        if (Array.isArray(result) && result.length === first) {
          return result[result.length - 1].id;
        }
        return null;
      },
      '0'
    );
  }

  @action
  async getPromoter(id: string) {
    this.checkClient();
    if (!this.client) return;
    const client = this.client;
    const first = FIRST;
    return handleTgRemoteDataPage(
      this.promoter,
      async (referralId: string) =>
        client.query({
          query: gql`
            ${PROMOTER_QUERY}
          `,
          variables: { id, referralId, first }
        }),
      data => {
        const referrals: PromoterType[] = [];
        const promoterResultArr = data as { promoter: PromoterType }[];
        promoterResultArr.forEach(i => {
          if (i.promoter.referrals) {
            referrals.push(...i.promoter.referrals);
          }
        });
        return { ...promoterResultArr[0].promoter, referrals };
      },
      [],
      data => {
        const result = (data as { promoter: PromoterType }).promoter;
        if (
          Array.isArray(result.referrals) &&
          result.referrals.length === first
        ) {
          return result.referrals[result.referrals.length - 1].id;
        }
        return null;
      },
      '0'
    );
  }

  @action
  setTransactionStatus(
    hash: string,
    status: TransactionHashStatusValType,
    currentAccount: string
  ) {
    this.transactions = this.transactions
      .map(i => {
        if (i.hash === hash) {
          return { ...i, status: status };
        }
        return i;
      })
      .filter(i => i.status === '0');
    sessionStorage.setItem(
      `${STORAGE_PENDING_TRANSACTIONS}_${currentAccount}`,
      JSON.stringify(this.transactions.filter(i => i.status === '0'))
    );
  }

  @action
  getContract = () => {
    if (this.contract) return this.contract;
    const provider = MetaMaskStore.provider;
    const contractAddress = this.configuration.value?.contract;
    if (!provider || !contractAddress) return null;
    const contract = new ethers.Contract(contractAddress, PromoAbi, provider);
    this.contract = contract;
    return contract;
  };

  contractPrice = async () => {
    const contract = this.getContract();
    if (!contract) return null;
    return await contract.price();
  };

  contractRefLinkPrice = async () => {
    const contract = this.getContract();
    if (!contract) return null;
    return await contract.refLinkPrice();
  };

  contractPromoPrice = async () => {
    const contract = this.getContract();
    if (!contract) return null;
    return await contract.promoPrice();
  };

  @action
  contractBuy = async (referrerAddress: Nullable<string>) => {
    const referrer =
      referrerAddress && referrerAddress.length === 42
        ? referrerAddress
        : ZERO_ADDRESS;
    const provider = MetaMaskStore.provider;
    const contract = this.getContract();
    if (!contract || !provider) return;
    const value = await this.contractPrice();

    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      withSigner
        .buy(referrer, {
          value
        })
        .then((r: any, e: Error) => {
          handleContractRemoteData(
            this.buyMintCoinRd,
            e,
            r.hash,
            this.transactions
          );
        });
    } catch (e) {
      console.log('Error contractBuy', e);
    }
  };

  @action
  clearBuyMintCoin() {
    this.buyMintCoinRd = createRemoteData<string>();
  }

  @action
  contractBuyLink = async () => {
    const provider = MetaMaskStore.provider;
    const contract = this.getContract();
    if (!contract || !provider) return;
    const value = await this.contractRefLinkPrice();
    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      withSigner
        .buyLink({
          value
        })
        .then((r: any, e: Error) => {
          handleContractRemoteData(
            this.buyLinkRd,
            e,
            r.hash,
            this.transactions
          );
        });
    } catch (e) {
      console.log('Error contractBuyLink', e);
    }
  };

  @action
  clearBuyLink() {
    this.buyLinkRd = createRemoteData<string>();
  }

  @action
  contractSell = async (address: string) => {
    const provider = MetaMaskStore.provider;
    const contract = this.getContract();
    if (!contract || !provider) return;
    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      withSigner.sell(address).then((r: any, e: Error) => {
        handleContractRemoteData(
          this.sellMintCoinRd,
          e,
          r.hash,
          this.transactions
        );
      });
    } catch (e) {
      console.log('Error contractSell', e);
    }
  };

  @action
  clearSell() {
    this.sellMintCoinRd = createRemoteData<string>();
  }

  @action
  contractPromoteMe = async () => {
    const provider = MetaMaskStore.provider;
    const contract = this.getContract();
    if (!contract || !provider) return;
    const value = await this.contractPromoPrice();
    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      withSigner
        .promoteMe({
          value
        })
        .then((r: any, e: Error) => {
          handleContractRemoteData(
            this.promoteMeRd,
            e,
            r.hash,
            this.transactions
          );
        });
    } catch (e) {
      console.log('Error contractPromoteMe', e);
    }
  };

  @action
  clearPromoteMe() {
    this.promoteMeRd = createRemoteData<string>();
  }
}

export default PromoStore;
