import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import cx from 'classnames';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import rightImg from '../../../Resources/Promo/toothy_cat.png';
import useClipboard from 'react-use-clipboard';
import { t } from 'i18next';
import s from './Dialog.module.scss';
import { Typography } from '@mui/material';

const CopyLinkDialog = ({ copyLink }: { copyLink: string }) => {
  const [open, setOpen] = React.useState(false);
  const [, setCopied] = useClipboard(copyLink);

  const handleClickOpen = () => {
    setOpen(true);
    setCopied();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button className="card-btn" onClick={handleClickOpen}>
        {t('copy')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <DialogContent className={cx(s.dialog, s.dialogRightImg)}>
          <div>
            <DialogContentText
              id="alert-dialog-description"
              variant="h2"
              className={s.content}
            >
              {t('copy_link')}
            </DialogContentText>
            <Typography mb={2}>{t('you_copied_the_link_')}</Typography>
            <Button variant="contained" className={s.btnV2} onClick={handleYes}>
              {t('close')}
            </Button>
          </div>
          <img src={rightImg} alt="" />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CopyLinkDialog;
