import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Typography, ButtonBase, Link } from '@mui/material';
import { MetamaskState } from '../../Stores/MetaMaskStore';
import metamaskLogo from '../../Resources/metamaskLogo.svg';
import mintmeMetamaskImg from '../../Resources/mintme_metamask.svg';
import { useStores } from '../../Stores/RootStore';
import s from './MetamaskInfo.module.scss';

const MetamaskInfo = () => {
  const { t } = useTranslation();
  const { metaMaskStore } = useStores();
  const { state } = metaMaskStore;

  return (
    <>
      {state === MetamaskState.NotInstalled && (
        <div className={s.container}>
          <img src={metamaskLogo} alt="Metamask" />
          <div className={s.info}>
            <Link
              href="https://metamask.io/download.html"
              target="_blank"
              variant="h5"
              color="textPrimary"
              className={s.text}
              underline="none"
              dangerouslySetInnerHTML={{ __html: t('install_metamask') }}
            />
          </div>
        </div>
      )}
      {state === MetamaskState.Installed && (
        <div className={cx(s.container, s.connect)}>
          <Typography variant="h5" color="secondary" gutterBottom>
            {t('metamask_installed')}
          </Typography>
          <ButtonBase onClick={() => metaMaskStore.connect()}>
            <Typography color="textPrimary" className={s.infoText}>
              {t('connect_metamask')}
            </Typography>
          </ButtonBase>
        </div>
      )}
      {state === MetamaskState.Connected && (
        <div className={cx(s.container, s.connected)}>
          <img
            src={mintmeMetamaskImg}
            alt={t('metamask_connected')}
            className={s.logoAll}
          />
          <Typography variant="h5" color="textPrimary" className={s.text}>
            {t('metamask_connected')}
          </Typography>
        </div>
      )}
    </>
  );
};

export default observer(MetamaskInfo);
