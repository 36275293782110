import React from 'react';

function RightArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.755"
      height="10.215"
      fill="none"
      version="1.1"
      viewBox="0 0 11.755 10.215"
    >
      <path
        fill="#41c7c0"
        d="M5.92 10.215l4.144-4.767.464.15H0v-.98h10.528l-.43.166L5.918 0h1.277l4.56 5.1-4.56 5.115z"
      ></path>
    </svg>
  );
}

export default RightArrow;
