import React from 'react';
import { Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import s from './Header.module.scss';

interface HeaderLinkProps {
  to: string;
  title: string;
  exact?: boolean;
}

const HeaderLink: React.FC<HeaderLinkProps> = ({ to, title, exact }) => {
  const { pathname } = useLocation();
  const active =
    to !== '/showroom' &&
    pathname.indexOf('/showroom') === -1 &&
    to !== '/instructions' &&
    pathname.indexOf('/instructions') === -1 &&
    to !== '/promo' &&
    pathname.indexOf('/promo') === -1;
  return (
    <Typography variant="h6" className={s.itemMenu}>
      <NavLink
        to={to}
        className={({ isActive }) => (isActive || active ? s.active : '')}
      >
        {title}
      </NavLink>
    </Typography>
  );
};

export default HeaderLink;
