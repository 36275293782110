import {
  action,
  observable,
  makeObservable,
  runInAction,
  computed
} from 'mobx';
import axios from 'axios';
import {
  ApolloClient,
  InMemoryCache,
  gql,
  NormalizedCacheObject
} from '@apollo/client';
import { BigNumber, ethers } from 'ethers';
import { oc } from 'ts-optchain';
import RemoteData, {
  RemoteDataState,
  handleRemoteData,
  createRemoteData,
  handleTgRemoteData,
  handleContractRemoteData,
  handleTgRemoteDataPage
} from '../Utils/RemoteData';
import { MintMeFactoryAbi } from '../Contracts/MintMeFactoryAbi';
import { MintMeCollectionAbi } from '../Contracts/MintMeCollectionAbi';
import { MintMeAbi } from '../Contracts/MintMeAbi';
import { MintMeAbiOld } from '../Contracts/MintMeAbiOld';
import { MintMePermAbi } from '../Contracts/MintMePermAbi';
import { ProveMeAbi } from '../Contracts/ProveMeAbi';
import { getCidFromUri } from '../Helpers/helpers';
import { STORAGE_PENDING_TRANSACTIONS, UPDATE_INTERVAL } from '../constants';
import {
  TOKENS_FIELDS,
  GET_OWNER_COLLECTIONS,
  COLLECTIONS_TOKEN_OWNER,
  ALL_COLLECTIONS,
  COLLECTION_BY_ID
} from '../Querys/mintMe';
import MetaMaskStore from './MetaMaskStore';

const SITE_URL = window.config.SITE_URL;
const FIRST = 1000;
export const HEADERS = {
  headers: {
    'Content-Type': 'application/json'
  }
};

class CollectionsStore {
  @observable clientProveMe: null | ApolloClient<NormalizedCacheObject> = null;
  @observable clientMintMe: null | ApolloClient<NormalizedCacheObject> = null;
  @observable provider: null | ethers.providers.Web3Provider = null;
  @observable chainData: Nullable<ChainDataType> = null;

  @observable collections = createRemoteData<CollectionTypeTg[]>([]);
  @observable collectionsTokenOwner = createRemoteData<CollectionTypeTg[]>([]);
  @observable allCollections = createRemoteData<CollectionTypeTg[]>([]);
  @observable tokens = createRemoteData<TokenTypeTg[]>([]);
  @observable token = createRemoteData<TokenTypeTg>();
  @observable tokenVerifyDataTg = createRemoteData<TokenVerifyDataTgType>();
  @observable tokensVerifyDataTg = createRemoteData<TokenVerifyDataTgType[]>(
    []
  );
  @observable transactions: TransactionHashStatusType[] = [];
  @observable createCollectionRd = createRemoteData<string>();
  @observable createTokenRd = createRemoteData<string>();
  @observable setContentTokenRd = createRemoteData<string>();
  @observable deleteTokenRd = createRemoteData<string>();
  @observable setContentCollectionRd = createRemoteData<string>();
  @observable verifyByProverRd = createRemoteData<string>();
  @observable transferCollectionRd = createRemoteData<string>();
  @observable hideCollectionRd = createRemoteData<string>();
  @observable reportVerifyData = createRemoteData<ReportVerifyDataType>();
  @observable baseURI = 'https://ipfs.io/ipfs/';
  @observable baseSiteURI = `${SITE_URL}/ipfs/`;
  // @observable baseSiteURI = '/ipfs/';
  @observable siteURI = SITE_URL;
  @observable currentAccount = '';
  @observable feeCreateCollection: Nullable<BigNumber> = null;
  @observable feeCreateToken: Nullable<BigNumber> = null;
  @observable feeVerifyByProver: Nullable<BigNumber> = null;
  @observable contractProveMe = '';

  constructor() {
    makeObservable(this);

    setInterval(() => {
      if (this.currentAccount) {
        this.getOwnerCollections(this.currentAccount);
        this.getCollectionsTokenOwner(this.currentAccount);
      }
      this.getAllCollections();
    }, UPDATE_INTERVAL);
  }

  @action
  checkMintMeClient = () => {
    if (this.clientMintMe) {
      this.clientMintMe.cache.reset();
    } else {
      if (this.chainData?.tgUriMintMe) {
        this.clientMintMe = new ApolloClient({
          uri: this.chainData.tgUriMintMe,
          cache: new InMemoryCache()
        });
      }
    }
  };

  @action
  checkProveMeClient = () => {
    if (this.clientProveMe) {
      this.clientProveMe.cache.reset();
    } else {
      if (this.chainData?.tgUriProveMe) {
        this.clientProveMe = new ApolloClient({
          uri: this.chainData.tgUriProveMe,
          cache: new InMemoryCache()
        });
      }
    }
  };

  @action
  setChain(chainData: ChainDataType) {
    this.clientProveMe = null;
    this.clientMintMe = null;
    this.chainData = chainData;
  }

  // get fee
  @action
  getFeeCreateCollection = async (
    provider: ethers.providers.Web3Provider,
    contractAddress: string
  ) => {
    if (!provider || !contractAddress) return;
    const contract = new ethers.Contract(
      contractAddress,
      MintMeFactoryAbi,
      provider
    );
    try {
      const value = await contract.feeWei();
      this.feeCreateCollection = value;
      this.feeCreateToken = value;
    } catch (e) {
      console.log('Error getFeeCreateCollection', e);
    }
  };

  @computed
  get feeCreateCollectionStr(): string {
    if (!this.feeCreateCollection || !this.chainData?.decimals) return '';
    return String(
      Number(
        ethers.utils.formatUnits(
          this.feeCreateCollection,
          this.chainData.decimals
        )
      )
    );
  }

  @computed
  get feeCreateTokenStr(): string {
    if (!this.feeCreateToken || !this.chainData?.decimals) return '';
    return String(
      Number(
        ethers.utils.formatUnits(this.feeCreateToken, this.chainData.decimals)
      )
    );
  }

  @action
  getFeeVerifyByProver = async (
    provider: ethers.providers.Web3Provider,
    contractAddress: string
  ) => {
    if (!provider || !contractAddress) return;
    const contract = new ethers.Contract(contractAddress, ProveMeAbi, provider);

    try {
      const value = await contract.fee();
      this.feeVerifyByProver = value;
    } catch (e) {
      console.log('Error getFeeVerifyByProver', e);
    }
  };
  @computed
  get feeVerifyByProverStr(): string {
    if (!this.feeVerifyByProver || !this.chainData?.decimals) return '';
    return String(
      Number(
        ethers.utils.formatUnits(
          this.feeVerifyByProver,
          this.chainData.decimals
        )
      )
    );
  }
  // /get fee

  @action
  async setContractProveMe(contract: string) {
    this.contractProveMe = contract;
  }

  @action
  async setCurrentAccount(currentAccount: string) {
    this.currentAccount = currentAccount;
  }

  @action
  async getOwnerCollections(owner: string) {
    const first = FIRST;
    this.checkMintMeClient();
    if (!this.clientMintMe) return;
    const client = this.clientMintMe;
    return handleTgRemoteDataPage(
      this.collections,
      async (collectionId: string) =>
        client.query({
          query: gql`
            ${GET_OWNER_COLLECTIONS}
          `,
          variables: { owner, collectionId, first }
        }),
      (data: unknown[]) => {
        if (!data.length) return this.allCollections.value;
        const resultArr = data as {
          collections: CollectionTypeTg[];
          hiddenAddresses: HiddenAddressType[];
          bannedAddresses: BannedAddressType[];
        }[];

        const collections: CollectionTypeTg[] = [];
        resultArr.forEach(i => {
          collections.push(...i.collections);
        });
        const hiddenAddresses = resultArr[0].hiddenAddresses.map(i => i.id);
        const bannedAddresses = resultArr[0].bannedAddresses.map(i => i.id);
        return collections
          .filter(i => !bannedAddresses.includes(i.id))
          .map(i => {
            if (i.tokens.length === first) {
              this.getTokensForCollections(i.id, this.collections);
            }
            return {
              ...i,
              hidden: hiddenAddresses.includes(i.id)
            };
          });
      },
      [],
      data => {
        const { collections } = data as { collections: CollectionTypeTg[] };
        if (Array.isArray(collections) && collections.length === first) {
          return collections[collections.length - 1].id;
        }
        return null;
      },
      '0'
    );
  }

  @action
  async getTokensForCollections(
    collectionId: string,
    collectionsData: RemoteData<CollectionTypeTg[]>,
    checkHide = true
  ) {
    const uri = this.chainData?.tgUriMintMe;
    if (!uri) return;
    const first = FIRST;
    handleTgRemoteDataPage(
      collectionsData,
      async (tokenId: string) =>
        axios
          .post(
            uri,
            JSON.stringify({
              query: COLLECTION_BY_ID,
              variables: {
                id: collectionId,
                tokenId,
                first
              }
            }),
            HEADERS
          )
          .then(r => r.data),
      (data: unknown[]) => {
        if (!data.length) return collectionsData.value;
        const resultArr = data as {
          collection: CollectionTypeTg;
          hiddenAddresses: HiddenAddressType[];
          bannedAddresses: BannedAddressType[];
        }[];

        const tokens: TokenTypeTg[] = [];
        resultArr.forEach(i => {
          tokens.push(...i.collection.tokens);
        });

        const collection = { ...resultArr[0].collection, tokens };
        const hiddenAddresses = resultArr[0].hiddenAddresses.map(i => i.id);
        const bannedAddresses = resultArr[0].bannedAddresses.map(i => i.id);
        if (
          checkHide &&
          (hiddenAddresses.includes(collection.id) ||
            bannedAddresses.includes(collection.id))
        ) {
          return this.allCollections.value;
        }
        const prevCollections = oc(collectionsData).value([]);

        for (let i = 0; i < prevCollections.length; i++) {
          if (prevCollections[i].id === collection.id) {
            prevCollections[i] = collection;
            return prevCollections;
          }
        }
        return collection ? [...prevCollections, collection] : prevCollections;
      },
      [],
      data => {
        const {
          collection: { tokens }
        } = data as { collection: CollectionTypeTg };
        if (Array.isArray(tokens) && tokens.length === first) {
          return tokens[tokens.length - 1].id;
        }
        return null;
      },
      '0'
    );
  }

  @action
  async getTokensOwnerForCollectionsTO(collectionId: string) {
    const uri = this.chainData?.tgUriMintMe;
    if (!uri) return;
    const first = FIRST;
    handleTgRemoteDataPage(
      this.collectionsTokenOwner,
      async (tokenId: string) =>
        axios
          .post(
            uri,
            JSON.stringify({
              query: COLLECTION_BY_ID,
              variables: {
                id: collectionId,
                tokenId,
                first
              }
            }),
            HEADERS
          )
          .then(r => r.data),
      (data: unknown[]) => {
        if (!data.length) return this.collectionsTokenOwner.value;
        const resultArr = data as {
          collection: CollectionTypeTg;
        }[];

        const tokensOwner: TokenTypeTg[] = [];
        resultArr.forEach(i => {
          if (oc(i).collection.tokensOwner([]).length) {
            tokensOwner.push(...oc(i).collection.tokensOwner([]));
          }
        });

        const collection = { ...resultArr[0].collection, tokensOwner };

        const prevCollections = oc(this).collectionsTokenOwner.value([]);

        for (let i = 0; i < prevCollections.length; i++) {
          if (prevCollections[i].id === collection.id) {
            prevCollections[i] = collection;
            return prevCollections;
          }
        }
        return collection ? [...prevCollections, collection] : prevCollections;
      },
      [],
      data => {
        const {
          collection: { tokens }
        } = data as { collection: CollectionTypeTg };
        if (Array.isArray(tokens) && tokens.length === first) {
          return tokens[tokens.length - 1].id;
        }
        return null;
      },
      '0'
    );
  }

  @action
  async getCollectionsTokenOwner(owner: string) {
    const uri = this.chainData?.tgUriMintMe;
    if (!uri) return;
    const first = FIRST;
    handleTgRemoteDataPage(
      this.collectionsTokenOwner,
      async (collectionId: string) =>
        axios
          .post(
            uri,
            JSON.stringify({
              query: COLLECTIONS_TOKEN_OWNER,
              variables: {
                owner,
                collectionId,
                first
              }
            }),
            HEADERS
          )
          .then(r => r.data),
      (data: unknown[]) => {
        if (!data.length) return this.allCollections.value;
        const resultArr = data as {
          collections: CollectionTypeTg[];
        }[];

        const collections: CollectionTypeTg[] = [];
        resultArr.forEach(i => {
          collections.push(...i.collections);
        });

        const result = collections.filter(
          i => oc(i).tokensOwner([]).length > 0
        );
        result.forEach(i => {
          if (oc(i).tokensOwner([]).length === first) {
            this.getTokensOwnerForCollectionsTO(i.id);
          }
          if (i.tokens.length === first) {
            this.getTokensForCollections(
              i.id,
              this.collectionsTokenOwner,
              false
            );
          }
        });
        return result;
      },
      [],
      data => {
        const { collections } = data as { collections: CollectionTypeTg[] };
        if (Array.isArray(collections) && collections.length === first) {
          return collections[collections.length - 1].id;
        }
        return null;
      },
      '0'
    );
  }

  @action
  async getAllCollections() {
    const uri = this.chainData?.tgUriMintMe;
    if (!uri) return;
    const first = FIRST;
    handleTgRemoteDataPage(
      this.allCollections,
      async (collectionId: string) =>
        axios
          .post(
            uri,
            JSON.stringify({
              query: ALL_COLLECTIONS,
              variables: {
                collectionId,
                first
              }
            }),
            HEADERS
          )
          .then(r => r.data),
      (data: unknown[]) => {
        if (!data.length) return this.allCollections.value;
        const resultArr = data as {
          collections: CollectionTypeTg[];
          hiddenAddresses: HiddenAddressType[];
          bannedAddresses: BannedAddressType[];
        }[];

        const collections: CollectionTypeTg[] = [];
        resultArr.forEach(i => {
          collections.push(...i.collections);
        });
        const hiddenAddresses = resultArr[0].hiddenAddresses.map(i => i.id);
        const bannedAddresses = resultArr[0].bannedAddresses.map(i => i.id);

        const result = collections.filter(
          i =>
            !hiddenAddresses.includes(i.id) && !bannedAddresses.includes(i.id)
        );
        result.forEach(i => {
          if (i.tokens.length === first) {
            this.getTokensForCollections(i.id, this.allCollections);
          }
        });
        return result;
      },
      [],
      data => {
        const { collections } = data as { collections: CollectionTypeTg[] };
        if (Array.isArray(collections) && collections.length === first) {
          return collections[collections.length - 1].id;
        }
        return null;
      },
      '0'
    );
  }

  @action
  async getAllCollectionsById(id: string) {
    this.getTokensForCollections(id, this.allCollections);
  }

  @action
  async getTokens(params: { collection: string }) {
    this.checkMintMeClient();
    if (!this.clientMintMe) return;
    const client = this.clientMintMe;
    const collectionId = params.collection;
    return handleTgRemoteData(
      this.tokens,
      async () =>
        client.query({
          query: gql`
            query getTokens($collectionId: ID) {
              tokens(where: { collection: $collectionId }) {
                ${TOKENS_FIELDS}
              }
            }
          `,
          variables: { collectionId }
        }),
      data => (data as { tokens: TokenTypeTg[] }).tokens
    );
  }

  @action
  getTokenById = async (id: string) => {
    this.token = createRemoteData<TokenTypeTg>();
    this.checkMintMeClient();
    if (!this.clientMintMe) return;
    const client = this.clientMintMe;
    return handleTgRemoteData(
      this.token,
      async () =>
        client.query({
          query: gql`
            query getTokenById($id: ID) {
              token(id: $id) {
                ${TOKENS_FIELDS}
              }
              hiddenAddresses {
                id
              }
              bannedAddresses {
                id
              }
            }
          `,
          variables: { id }
        }),
      data => {
        const result = data as {
          token: TokenTypeTg;
          hiddenAddresses: HiddenAddressType[];
          bannedAddresses: BannedAddressType[];
        };
        const hiddenAddresses = result.hiddenAddresses.map(i => i.id);
        const bannedAddresses = result.bannedAddresses.map(i => i.id);
        if (
          hiddenAddresses.includes(result.token.collection.id) ||
          bannedAddresses.includes(result.token.collection.id)
        ) {
          return this.token.value;
        }
        return result.token;
      }
    );
  };

  @action
  getTokenByExternalUrl = async (url: string, collectionId: string) => {
    this.checkMintMeClient();
    if (!this.clientMintMe) return;
    const client = this.clientMintMe;
    return handleTgRemoteData(
      this.token,
      async () =>
        client.query({
          query: gql`
            query getTokenByExternalUrl($url: String, $collectionId: ID) {
              tokens(first: 1, where: {external_url: $url, collection: $collectionId}) {
                ${TOKENS_FIELDS}
              }
              bannedAddresses {
                id
              }
            }
          `,
          variables: { url, collectionId }
        }),
      data => {
        const result = data as {
          tokens: TokenTypeTg[];
          bannedAddresses: BannedAddressType[];
        };
        const token = result.tokens[0];
        const bannedAddresses = result.bannedAddresses.map(i => i.id);
        if (bannedAddresses.includes(token.collection.id)) {
          return this.token.value;
        }
        return token;
      }
    );
  };

  @action
  getDataVerifyByProver = async () => {
    // select all received tokens
    const tokensCidArr: string[] = [];
    const tokensVerifyData = oc(this).tokensVerifyDataTg.value([]);
    [
      ...[{ tokens: [this.token.value] }],
      ...oc(this).collections.value([]),
      ...oc(this).collectionsTokenOwner.value([]),
      ...oc(this).allCollections.value([])
    ].forEach((collection: CollectionTypeTg) => {
      collection.tokens.forEach(async (token: TokenTypeTg) => {
        if (token != null) {
          const cid = getCidFromUri(token.content);
          const foundToken = tokensVerifyData.find(
            (i: TokenVerifyDataTgType) => i.tokenId === token.id
          );

          if (!foundToken) {
            tokensVerifyData.push({
              id: cid,
              cidJson: '',
              status: null,
              tokenId: token.id,
              data: null,
              newStatus: null
            });
            if (!tokensCidArr.includes(cid)) {
              tokensCidArr.push(cid);
            }
          } else if (
            foundToken.newStatus === 'Pending' ||
            foundToken.status === 'Pending' ||
            foundToken.status === 'Initial'
          ) {
            if (!tokensCidArr.includes(cid)) {
              tokensCidArr.push(cid);
            }
          }
        }
      });
    });

    this.checkProveMeClient();
    if (!this.clientProveMe) return;
    const client = this.clientProveMe;
    return handleTgRemoteData(
      this.tokensVerifyDataTg,
      async () =>
        client.query({
          query: gql`
            query getDataVerifyByProverTg {
              contents(
                where: {
                  id_in: ["${tokensCidArr.join(`","`)}"]
                }
              ) {
                id
                cidJson
                status
              }
            }
          `
        }),
      data => {
        const contents = (data as { contents: TokenVerifyDataTgType[] })
          .contents;

        const result: TokenVerifyDataTgType[] = tokensVerifyData.map(
          (i: TokenVerifyDataTgType): TokenVerifyDataTgType => {
            const content = contents.find(c => c.id === i.id);
            if (content) {
              return { ...i, ...content, newStatus: null };
            }
            if (i.status === null) {
              return { ...i, status: 'Initial' };
            }
            return i;
          }
        );

        return result;
      }
    );
  };

  @action
  setContentCollection = async (
    contentCID: string,
    provider: ethers.providers.Web3Provider,
    contractAddress: string
  ) => {
    if (!provider) return;
    const contract = new ethers.Contract(
      contractAddress,
      MintMeCollectionAbi,
      provider
    );

    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);

    try {
      withSigner
        .setContent(contentCID)
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.setContentCollectionRd,
            e,
            r.hash,
            this.transactions
          )
        );
    } catch (e) {
      console.log('Error setContentCollection', e);
    }
  };

  @action
  createCollection = async (
    name: string,
    symbol: string,
    contentCID: string,
    licenseCID: string,
    provider: ethers.providers.Web3Provider,
    contractAddress: string
  ) => {
    const value = this.feeCreateCollection;
    if (!provider || !value) return;
    const contract = new ethers.Contract(
      contractAddress,
      MintMeFactoryAbi,
      provider
    );

    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      withSigner
        .createCollection(name, symbol, contentCID, licenseCID, {
          value
        })
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.createCollectionRd,
            e,
            r.hash,
            this.transactions
          )
        );

      // handleContractRemoteData(this.createCollectionRd, tx, this.transactions);
      // const receipt = await tx.wait();
    } catch (e) {
      console.log('Error createCollection', e);
    }
  };

  @action
  getBaseURI = async (
    provider: ethers.providers.Web3Provider,
    contractAddress: string
  ) => {
    const contract = new ethers.Contract(
      contractAddress,
      MintMeFactoryAbi,
      provider
    );
    try {
      contract.baseURI().then((r: string) => {
        runInAction(() => (this.baseURI = r));
      });
    } catch (e) {
      console.log(e);
    }
  };

  @action
  transferCollection = async (addressTo: string, contractAddress: string) => {
    const provider = MetaMaskStore.provider;
    if (!provider) return;
    const contract = new ethers.Contract(contractAddress, MintMeAbi, provider);

    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      await withSigner
        .transferOwnership(addressTo)
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.transferCollectionRd,
            e,
            r.hash,
            this.transactions
          )
        );
    } catch (e) {
      console.log('Error transferCollection', e);
    }
  };

  @action
  createToken = async (
    contentCID: string,
    userAddress: string,
    provider: ethers.providers.Web3Provider,
    contractAddress: string
  ) => {
    const value = this.feeCreateToken;
    if (!provider || !value) return;
    const contract = new ethers.Contract(contractAddress, MintMeAbi, provider);

    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      await withSigner
        .mint(userAddress, contentCID, {
          value
        })
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.createTokenRd,
            e,
            r.hash,
            this.transactions
          )
        );
    } catch (e) {
      console.log('Error createToken', e);
    }
  };

  @action
  setContentToken = async (
    tokenId: string,
    contentCID: string,
    provider: ethers.providers.Web3Provider,
    contractAddress: string
  ) => {
    if (!provider) return;
    const contract = new ethers.Contract(contractAddress, MintMeAbi, provider);

    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      await withSigner
        .setContent(tokenId, contentCID)
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.setContentTokenRd,
            e,
            r.hash,
            this.transactions
          )
        );
    } catch (e) {
      console.log('Error createToken', e);
    }
  };

  @action
  sendToken = async (
    addressFrom: string,
    addressTo: string,
    token: TokenTypeTg,
    contractAddress: string
  ) => {
    const provider = MetaMaskStore.provider;
    if (!provider) return;
    const contract = new ethers.Contract(contractAddress, MintMeAbi, provider);

    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      await withSigner
        .safeTransferFrom(addressFrom, addressTo, token.tokenId)
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.deleteTokenRd,
            e,
            r.hash,
            this.transactions
          )
        );
    } catch (e) {
      console.log('Error sendToken', e);
    }
  };

  @action
  deleteToken = async (token: TokenTypeTg, contractAddress: string) => {
    const provider = MetaMaskStore.provider;
    if (!provider) return;
    const contract = new ethers.Contract(contractAddress, MintMeAbi, provider);

    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      await withSigner
        .burn(token.tokenId)
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.deleteTokenRd,
            e,
            r.hash,
            this.transactions
          )
        );
    } catch (e) {
      console.log('Error deleteToken', e);
    }
  };

  @action
  deleteTokenOld = async (tokenId: string, contractAddress: string) => {
    const provider = MetaMaskStore.provider;
    if (!provider) return;
    const contract = new ethers.Contract(
      contractAddress,
      MintMeAbiOld,
      provider
    );
    console.table({ tokenId, contractAddress });
    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      await withSigner
        .burn(tokenId)
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.deleteTokenRd,
            e,
            r.hash,
            this.transactions
          )
        );
    } catch (e) {
      console.log('Error deleteToken', e);
    }
  };

  @action
  verifyByProver = async (
    token: TokenTypeTg,
    provider: ethers.providers.Web3Provider,
    contractAddress: string
  ) => {
    const value = this.feeVerifyByProver;
    if (!provider || !contractAddress || !token.content || !value) return;
    const contract = new ethers.Contract(contractAddress, ProveMeAbi, provider);

    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      withSigner
        .request(getCidFromUri(token.content), {
          value
        })
        .then((r: any, e: Error) => {
          if (!e) {
            this.changeStatusVerify(token);
          }
          return handleContractRemoteData(
            this.verifyByProverRd,
            e,
            r.hash,
            this.transactions
          );
        });
    } catch (e) {
      console.log('Error verifyByProver', e);
    }
  };

  @action
  changeStatusVerify = (token: TokenTypeTg) => {
    oc(this)
      .tokensVerifyDataTg.value([])
      .forEach((i: TokenVerifyDataTgType) => {
        if (i.tokenId === token.id) {
          i.newStatus = 'Pending';
        }
      });
  };

  @action
  getTransactions = (currentAccount: string) => {
    if (
      sessionStorage.getItem(
        `${STORAGE_PENDING_TRANSACTIONS}_${currentAccount}`
      )
    ) {
      this.transactions = JSON.parse(
        sessionStorage.getItem(
          `${STORAGE_PENDING_TRANSACTIONS}_${currentAccount}`
        ) as string
      );
    } else {
      this.transactions = [];
    }
  };

  @action
  setTransactionStatus(
    hash: string,
    status: TransactionHashStatusValType,
    currentAccount: string
  ) {
    this.transactions = this.transactions
      .map(i => {
        if (i.hash === hash) {
          return { ...i, status: status };
        }
        return i;
      })
      .filter(i => i.status === '0');
    sessionStorage.setItem(
      `${STORAGE_PENDING_TRANSACTIONS}_${currentAccount}`,
      JSON.stringify(this.transactions.filter(i => i.status === '0'))
    );
  }

  @computed
  get allUserCollections(): CollectionTypeTg[] {
    return [
      ...oc(this).collections.value([]),
      ...oc(this).collectionsTokenOwner.value([])
    ];
  }

  getCollectionById = (
    collections: CollectionTypeTg[],
    collectionId: string
  ): Nullable<CollectionTypeTg> => {
    if (!collections.length) return null;
    return collections.find(i => i.id === collectionId);
  };

  @action
  getReportVerify = async (verifyData: TokenVerifyDataTgType) => {
    if (this.reportVerifyData.state === RemoteDataState.REQUEST) {
      setTimeout(() => {
        this.getReportVerify(verifyData);
      }, 500);
      return;
    }
    if (!verifyData.cidJson || (verifyData.cidJson && verifyData.data)) return;
    return handleRemoteData(
      this.reportVerifyData,
      async () =>
        axios.get<ReportVerifyDataType>(
          this.baseSiteURI.concat(verifyData.cidJson)
        ),
      // eslint-disable-next-line prettier/prettier
      () => { },
      result => {
        if (result?.result) {
          this.tokensVerifyDataTg.value = oc(this)
            .tokensVerifyDataTg.value([])
            .map((i: TokenVerifyDataTgType) => {
              if (i.id === verifyData.id) {
                return { ...i, data: result.result };
              }
              return i;
            });
          return result;
        }
        return null;
      }
    );
  };

  @action
  hideCollection = async (
    hide: boolean,
    contractAddress: string,
    address: string,
    provider = MetaMaskStore.provider
  ) => {
    if (!provider) return;
    const contract = new ethers.Contract(
      contractAddress,
      MintMePermAbi,
      provider
    );

    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);

    try {
      if (hide) {
        withSigner
          .hide(address)
          .then((r: any, e: Error) =>
            handleContractRemoteData(
              this.hideCollectionRd,
              e,
              r.hash,
              this.transactions
            )
          );
      } else {
        withSigner
          .unhide(address)
          .then((r: any, e: Error) =>
            handleContractRemoteData(
              this.hideCollectionRd,
              e,
              r.hash,
              this.transactions
            )
          );
      }
    } catch (e) {
      console.log('Error hideCollection', e);
    }
  };

  @action
  clearCreateToken() {
    this.createTokenRd = createRemoteData<string>();
  }

  @action
  clearEditToken() {
    this.setContentTokenRd = createRemoteData<string>();
  }

  @action
  clearCreateCollection() {
    this.createCollectionRd = createRemoteData<string>();
  }

  @action
  clearSetContentCollection() {
    this.setContentCollectionRd = createRemoteData<string>();
  }
}

export default CollectionsStore;
