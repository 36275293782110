import React, { useRef, useEffect } from 'react';
import { Container, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Stores/RootStore';
import logoImg from '../../Resources/logo.png';
import mintMeText from '../../Resources/MintMe.svg';
import BalanceInformer from '../../Containers/BalanceInformer/BalanceInformer';
import HeaderLink from './HeaderLink';
import MobileMenu from '../MobileMenu/MobileMenu';
import { t } from 'i18next';
import s from './Header.module.scss';

const Header = () => {
  const { metaMaskStore } = useStores();
  const focusRef = useRef<any>(null);
  useEffect(() => {
    focusRef.current.focus();
  }, []);
  return (
    <div className={s.main}>
      <div tabIndex={99999} ref={focusRef} />
      <Container maxWidth="xl" className={s.container}>
        <div className={s.logo}>
          <Box bgcolor="primary.main" className={s.logoImg}>
            <RouterLink to="/">
              <img src={logoImg} alt="MintMe logo" />
            </RouterLink>
          </Box>
          <div className={s.menuSec}>
            <RouterLink to="/">
              <img src={mintMeText} className={s.logoText} alt="MintMe" />
            </RouterLink>
            <Box className={s.menu}>
              <HeaderLink title={t('showroom')} to={`/showroom`} />
              {metaMaskStore.state === 3 && (
                <HeaderLink title={t('my_assets')} to="/" />
              )}
              <HeaderLink title={t('instructions')} to={`/instructions`} />
              <HeaderLink title={t('promo')} to={`/promo`} />
            </Box>
          </div>
        </div>
        <div className={s.balanceInformer}>
          <BalanceInformer />
        </div>
        <div className={s.mobileMenu}>
          <MobileMenu metaMaskState={metaMaskStore.state} />
        </div>
      </Container>
    </div>
  );
};

export default observer(Header);
