import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import cx from 'classnames';
import { oc } from 'ts-optchain';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useReaction } from '../../Hooks/useReaction';
import { RemoteDataState } from '../../Utils/RemoteData';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import PromoStore from '../../Stores/PromoStore';
import { stores } from '../../Stores/RootStore';
import rabbitImg from '../../Resources/Promo/rabbit.png';
import handImg from '../../Resources/Promo/hand.png';
import s from './Dialog.module.scss';

export interface DialogSettings {
  onClean: (wallStore: PromoStore) => void;
  reactionExpression: () => any;
  messageKey: string;
  titleKey: string;
  redirect?: string;
  successFunc?: () => void;
}

const TransactionDialog = ({
  onClean,
  reactionExpression,
  messageKey,
  titleKey,
  redirect,
  successFunc
}: DialogSettings) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { promoStore } = useStores();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleSuccessDialog = (value: boolean) => () => {
    onClean(promoStore);
    setDialogOpen(value);
    if (redirect) {
      navigate(redirect, { replace: true });
    }
  };

  useReaction(reactionExpression, state => {
    if (state) {
      if (successFunc) {
        successFunc();
      }
      setDialogOpen(true);
    }
  });

  return (
    <Dialog open={isDialogOpen} onClose={handleSuccessDialog(false)}>
      <DialogTitle>{t(titleKey)}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(messageKey)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSuccessDialog(false)}
          color="primary"
          variant="contained"
          disableElevation
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TransactionDialogSell = ({
  onClean,
  reactionExpression,
  messageKey,
  titleKey,
  redirect,
  successFunc
}: DialogSettings) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { promoStore } = useStores();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const promoter = promoStore.promoter.value;
  const decimals = promoStore.chainData?.decimals;

  const bought = ethers.utils.formatUnits(
    oc(promoter).tokenCost('0'),
    decimals
  );
  const canSell = ethers.utils.formatUnits(
    oc(promoter).tokenPrice('0'),
    decimals
  );

  const handleSuccessDialog = (value: boolean) => () => {
    onClean(promoStore);
    setDialogOpen(value);
    if (redirect) {
      navigate(redirect, { replace: true });
    }
  };

  useReaction(reactionExpression, state => {
    if (state) {
      if (successFunc) {
        successFunc();
      }
      setDialogOpen(true);
    }
  });

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleSuccessDialog(false)}
      className={cx(s.promo, s.sell)}
    >
      <DialogTitle>
        <Typography className={s.textGradient} variant="h5">
          {t(titleKey)}
        </Typography>
      </DialogTitle>
      <DialogContent className={s.dialogContent}>
        <DialogContentText
          dangerouslySetInnerHTML={{
            __html:
              bought === '0.0'
                ? t('you_bought_a_coin_for_free_', { canSell })
                : t('you_bought_a_coin_for_', { bought, canSell })
          }}
        />
        <img src={rabbitImg} alt="" className={s.imgLeft} />
        <img src={handImg} alt="" className={s.imgRight} />
      </DialogContent>
      <DialogActions className={s.dialogActions}>
        <Button
          onClick={handleSuccessDialog(false)}
          color="primary"
          variant="contained"
          disableElevation
          autoFocus
        >
          {t('cool')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const createDialog = (props: DialogSettings) => {
  return observer(() => <TransactionDialog {...props} />);
};

const createDialogSell = (props: DialogSettings) => {
  return observer(() => <TransactionDialogSell {...props} />);
};

// BuyMintCoin
export const BuyMintCoinSuccessDialog = createDialog({
  onClean: promoStore => {
    promoStore.clearBuyMintCoin();
  },
  reactionExpression: () =>
    stores.promoStore.buyMintCoinRd.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_edit',
  titleKey: 'transaction_success'
});
export const BuyMintCoinErrorDialog = createDialog({
  onClean: promoStore => {
    promoStore.clearBuyMintCoin();
  },
  reactionExpression: () =>
    stores.promoStore.buyMintCoinRd.state === RemoteDataState.FAILURE,
  messageKey: 'transaction_error_edit',
  titleKey: 'transaction_error'
});

// BuyLink
export const BuyLinkSuccessDialog = ({
  successFunc
}: {
  successFunc: () => void;
}) => {
  return (
    <TransactionDialog
      onClean={promoStore => {
        promoStore.clearBuyLink();
      }}
      reactionExpression={() =>
        stores.promoStore.buyLinkRd.state === RemoteDataState.SUCCESS
      }
      messageKey="transaction_success_edit"
      titleKey="transaction_success"
      successFunc={successFunc}
    />
  );
};
export const BuyLinkErrorDialog = createDialog({
  onClean: promoStore => {
    promoStore.clearBuyLink();
  },
  reactionExpression: () =>
    stores.promoStore.buyLinkRd.state === RemoteDataState.FAILURE,
  messageKey: 'transaction_error_edit',
  titleKey: 'transaction_error'
});

// Sell
export const SellMintCoinSuccessDialog = createDialogSell({
  onClean: promoStore => {
    promoStore.clearSell();
  },
  reactionExpression: () =>
    stores.promoStore.sellMintCoinRd.state === RemoteDataState.SUCCESS,
  messageKey: '',
  titleKey: 'congratulations'
});
export const SellMintCoinErrorDialog = createDialog({
  onClean: promoStore => {
    promoStore.clearSell();
  },
  reactionExpression: () =>
    stores.promoStore.sellMintCoinRd.state === RemoteDataState.FAILURE,
  messageKey: 'transaction_error_edit',
  titleKey: 'transaction_error'
});

// PromoteMe
export const PromoteMeSuccessDialog = createDialog({
  onClean: promoStore => {
    promoStore.clearPromoteMe();
  },
  reactionExpression: () =>
    stores.promoStore.promoteMeRd.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_edit',
  titleKey: 'transaction_success'
});
export const PromoteMeErrorDialog = createDialog({
  onClean: promoStore => {
    promoStore.clearPromoteMe();
  },
  reactionExpression: () =>
    stores.promoStore.promoteMeRd.state === RemoteDataState.FAILURE,
  messageKey: 'transaction_error_edit',
  titleKey: 'transaction_error'
});
