import * as React from 'react';
import { t } from 'i18next';
import { oc } from 'ts-optchain';
import Popper from '@mui/material/Popper';
import { Progress } from './Components';
import { NEED_REFERRALS } from './Promo';
import { shortAddress, dateStrFromTs3 } from '../../Helpers/helpers';
import s from './Promo.module.scss';

const ReferralsPopper = ({
  promoter
}: {
  promoter: Nullable<PromoterType>;
}) => {
  const restForUnlock =
    promoter?.restForUnlock != null ? +promoter.restForUnlock : NEED_REFERRALS;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'referrals-popper' : undefined;

  return (
    <div>
      <div
        aria-describedby={id}
        onClick={handleClick}
        className={s.popperProgress}
      >
        <Progress
          value={NEED_REFERRALS - restForUnlock}
          total={NEED_REFERRALS}
          green
        />
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top"
        className={s.popperReferrals}
      >
        <TableReferrals promoter={promoter} />
      </Popper>
    </div>
  );
};

const TableReferrals = ({ promoter }: { promoter: Nullable<PromoterType> }) => {
  const lastBuyAction = +oc(promoter).lastBuyAction?.time('0');
  if (!lastBuyAction) return null;
  const referrals = oc(promoter)
    .referrals([])
    .filter(i => +oc(i).lastBuyAction?.time('0') > lastBuyAction);
  return (
    <table className={s.yourReferralsTablePopper}>
      <tbody>
        <tr>
          <th>{t('referral')}</th>
          <th>{t('purchase_time')}</th>
        </tr>
        {referrals.map((i: PromoterType) => {
          const timeFormat = i.lastBuyAction?.time
            ? dateStrFromTs3(+i.lastBuyAction.time)
            : '';
          return (
            <tr key={i.id}>
              <td>{shortAddress(i.id)}</td>
              <td>{timeFormat}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ReferralsPopper;
