import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import cx from 'classnames';
import { MathFloor10 } from '../../Helpers/decimalAdjust';
import './Promo.scss';
import s from './Promo.module.scss';

export const AnimatedProgressBar = ({
  value,
  total,
  title,
  green = false
}: {
  value: number;
  total: number;
  title?: string;
  green?: boolean;
}) => {
  const [percent, setPercent] = useState(0);
  const [valueStep, setValueStep] = useState(0);

  const step = (v: number) => {
    setValueStep(v);
    setTimeout(() => {
      if (v >= value) {
        setValueStep(value);
      } else {
        step(Math.ceil(v + value / 100));
      }
    }, 100);
  };

  useEffect(() => {
    setPercent(MathFloor10((100 * valueStep) / total, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueStep]);

  useEffect(() => {
    step(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={s.progressBar}>
      {!!title && (
        <h3>
          {title}:{' '}
          <strong>
            <span className={s.percent}>{percent}</span>%
          </strong>
        </h3>
      )}
      <Typography variant="body1" className={s.progressBarText}>
        <span className={s.value}>{valueStep}</span> of{' '}
        <span className={s.total}>{total}</span>
      </Typography>
      <div className="progress-100 progressV2">
        <div
          className={cx('progress-x', green && 'green')}
          style={{ width: `${percent}%` }}
        ></div>
      </div>
    </Box>
  );
};

export default AnimatedProgressBar;
