import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import Infobox from '../Infobox';
import iconMintMe from '../../../Resources/Instructions/icon_mintme.svg';
import iconPolygon from '../../../Resources/Instructions/icon_polygon.svg';
import iconEthereum from '../../../Resources/Instructions/icon_ethereum.svg';
import iconSmartChain from '../../../Resources/Instructions/icon_smart_chain.svg';
import s from '../Instructions.module.scss';

const SmartcontractsAndSupportedNetworks = () => {
  const { t } = useTranslation();
  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('smartcontracts_and_supported_networks')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Infobox
            icon="faInfoCircle"
            title={t('about')}
            text={t('smart_contracts_of_the_mintme_project_')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Infobox
            image={iconMintMe}
            title={t('mintme')}
            text={t('mintme_is_a_multichain_platform_')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography variant="h4">{t('mintmefactory')}</Typography>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: t('the_head_smart_contract_that_')
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="h4">{t('mintmeperm')}</Typography>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: t('a_smart_contract_that_')
            }}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">
          {t('smart_contracts_and_networks')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Infobox
              image={iconPolygon}
              title={t('on_polygon')}
              text={
                t('smartcontract_and_networks', {
                  href: 'https://polygonscan.com/address/0x4b87768c820d95e5698361c854d983a920cb8027',
                  title: t('mintmefactory'),
                  contract: '0x4b87768c820d95e5698361c854d983a920cb8027'
                }) +
                t('smartcontract_and_networks', {
                  href: 'https://polygonscan.com/address/0xc9267d801305300904adf7b2ac7a68cc152b3f2c',
                  title: t('mintmeperm'),
                  contract: '0xc9267d801305300904adf7b2ac7a68cc152b3f2c'
                })
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              image={iconEthereum}
              title={t('on_ethereum')}
              text={
                t('smartcontract_and_networks', {
                  href: 'https://etherscan.io/address/0xf5c42c28913d9a6e4a0e2501d3814de7d70dbcaf',
                  title: t('mintmefactory'),
                  contract: '0xf5c42c28913d9a6e4a0e2501d3814de7d70dbcaf'
                }) +
                t('smartcontract_and_networks', {
                  href: 'https://etherscan.io/address/0x755b4ae82d977e83e990a6b2ea37b5e5e7dc98da',
                  title: t('mintmeperm'),
                  contract: '0x755b4ae82d977e83e990a6b2ea37b5e5e7dc98da'
                })
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              image={iconSmartChain}
              title={t('on_binance_smart_chain')}
              text={
                t('smartcontract_and_networks', {
                  href: 'https://bscscan.com/address/0x17b3ae7ce777c072ec142e767f0203576bc99730',
                  title: t('mintmefactory'),
                  contract: '0x17b3ae7ce777c072ec142e767f0203576bc99730'
                }) +
                t('smartcontract_and_networks', {
                  href: 'https://bscscan.com/address/0xdfe95f5eb13b7cf60f96cb12876c8951002dd5a4',
                  title: t('mintmeperm'),
                  contract: '0xdfe95f5eb13b7cf60f96cb12876c8951002dd5a4'
                })
              }
            />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default SmartcontractsAndSupportedNetworks;
