import React, { useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import { useNavigate } from 'react-router-dom';
import ButtonLink from '../../Components/ButtonLink/ButtonLink';
import { useStores } from '../../Stores/RootStore';
import MyCollectionCard from '../MyCollectionCard/MyCollectionCard';
import { MetamaskState } from '../../Stores/MetaMaskStore';
import s from './Collections.module.scss';
import CustomContainer from '../../Components/CustomContainer/CustomContainer';

const MyCollections = () => {
  const { t } = useTranslation();
  const { metaMaskStore, collectionsStore } = useStores();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      metaMaskStore.state === MetamaskState.NotInstalled ||
      metaMaskStore.state === MetamaskState.Installed
    ) {
      navigate('/showroom');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaMaskStore.state]);

  if (metaMaskStore.state === MetamaskState.Initial) {
    return <CircularProgress size={32} />;
  }

  const collections = oc(collectionsStore).collections.value([]);
  const collectionsTokenOwner = oc(
    collectionsStore
  ).collectionsTokenOwner.value([]);

  const path = metaMaskStore.chainData
    ? `/${metaMaskStore.chainData.nameForUrl}`
    : '';

  return (
    <React.Fragment>
      <CustomContainer className={s.main}>
        <Typography variant="h1">{t('my_collections')}</Typography>
        <ButtonLink
          to="/create-collection"
          title={t('create_collection')}
          disabled={metaMaskStore.readOnly}
        />
        {collections.length < 1 && (
          <Box mt={4}>
            <Typography variant="h2">{t('your_collections_will')}</Typography>
          </Box>
        )}

        <Box mt={4} className={s.list}>
          {collections.length > 0 &&
            collections.map(i => (
              <MyCollectionCard collection={i} key={i.id} path={path} />
            ))}
          {collectionsTokenOwner.length > 0 &&
            collectionsTokenOwner.map(i => (
              <MyCollectionCard collection={i} key={i.id} path={path} />
            ))}
        </Box>
      </CustomContainer>
    </React.Fragment>
  );
};

export default observer(MyCollections);
