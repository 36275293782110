import React from 'react';
import './config';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import App from './Containers/App/App';
import './main.scss';
import './Locale/i18n.js';
import theme from './theme';
import './Utils/Reactions';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);
