import React from 'react';
import { Box, Typography, Button, FormHelperText } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { v4 } from 'uuid';
import { uploadJson, uploadImage } from '../../../Utils/Storage';
import FieldLabel from '../../../Components/FieldLabel/FieldLabel';
import photoImg from '../../../Resources/photo.svg';
import arrowLeft from '../../../Resources/ArrowLeft.svg';
import { useStores } from '../../../Stores/RootStore';
import schema from './schema';
import { handleFileImage, handleFileMedia } from '../../../Utils/HandleFile';
import AppCardMedia from '../../../Components/AppCardMedia/AppCardMedia';
import { isVideoFile } from '../../../Utils/HandleFile';
import {
  CreateTokenSuccessDialog,
  CreateTokenErrorDialog
} from '../../../Components/Dialogs/Dialogs';
import s from '../Forms.module.scss';
import {
  IMAGE_TYPES,
  VIDEO_TYPES,
  AUDIO_TYPES,
  THREED_TYPES
} from '../../../constants';
import CustomContainer from '../../../Components/CustomContainer/CustomContainer';

const CreateNft = () => {
  const { t } = useTranslation();
  const { metaMaskStore, collectionsStore } = useStores();
  const { collectionId } = useParams();
  const { baseURI, siteURI, createToken } = collectionsStore;

  const provider = oc(metaMaskStore).provider(null);
  const userAddress = oc(metaMaskStore).currentAccount('');
  const netName = metaMaskStore.chainData?.nameForUrl;

  if (!collectionId || !provider || !userAddress || !netName) return null;

  const feeCreateTokenStr = collectionsStore.feeCreateTokenStr;

  return (
    <React.Fragment>
      <CustomContainer className={s.main}>
        <Formik
          initialValues={{
            name: '',
            description: '',
            mediaFile: {
              file: null,
              name: '',
              path: '',
              error: ''
            },
            cover: {
              file: null,
              name: '',
              path: '',
              error: ''
            }
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (!values.cover.file || !values.mediaFile.file) return;
            const mediaCid = await uploadImage(values.mediaFile.file);
            const coverCid = await uploadImage(values.cover.file);
            const { name, description } = values;
            const image = baseURI.concat(coverCid);
            const external_url = `${siteURI}/token/${netName}/${collectionId}/${v4()}`;
            const content = baseURI.concat(mediaCid);
            const animation_url = isVideoFile(values.mediaFile.file)
              ? content
              : ''; // duplicate content if content is animation
            const contentCid = await uploadJson({
              name,
              description,
              image,
              external_url,
              animation_url,
              content
            });
            if (contentCid) {
              createToken(contentCid, userAddress, provider, collectionId);
            }
            setSubmitting(false);
          }}
          validationSchema={schema}
        >
          {({
            submitForm,
            isSubmitting,
            setFieldValue,
            values,
            touched,
            errors
          }) => (
            <Form>
              <Typography variant="h1">
                <RouterLink to={`/${netName}/collection/${collectionId}`}>
                  <img src={arrowLeft} alt="" />
                </RouterLink>{' '}
                {t('create_nft')}
              </Typography>
              <Box mt={4} className={s.mainForm}>
                <Box>
                  <Box>
                    <FieldLabel title={t('image_video_audio')} mb={0} />
                    <Typography variant="caption">
                      {t('file_types_supported_media_file')}
                    </Typography>
                    <input
                      accept={`
                        ${IMAGE_TYPES},
                        ${VIDEO_TYPES},
                        ${AUDIO_TYPES},
                        ${THREED_TYPES.map(item => {
                          return '.' + item;
                        })}
                      `}
                      className={s.uploadInput}
                      id="mediaFile"
                      multiple
                      type="file"
                      name="mediaFile"
                      onChange={e =>
                        setFieldValue(
                          'mediaFile',
                          handleFileMedia(e) ?? values.mediaFile
                        )
                      }
                    />
                    <Box width="190px" my={1} className={s.boxImage}>
                      <label htmlFor="mediaFile">
                        <AppCardMedia
                          mediaObj={values.mediaFile}
                          path={photoImg}
                          error={!!touched.mediaFile && !!errors.mediaFile}
                          sx={{ objectFit: 'contain' }}
                        />
                      </label>
                    </Box>
                    {!!touched.mediaFile && !!errors.mediaFile && (
                      <FormHelperText error>{t('required')}</FormHelperText>
                    )}
                  </Box>
                  <Box mt={3}>
                    <FieldLabel title={t('cover')} mb={0} />
                    <Typography variant="caption">
                      {t('file_types_supported')}
                    </Typography>
                    <input
                      accept={`${IMAGE_TYPES}`}
                      className={s.uploadInput}
                      id="uploadarea"
                      multiple
                      type="file"
                      name="cover"
                      onChange={e =>
                        setFieldValue(
                          'cover',
                          handleFileImage(e) ?? values.cover
                        )
                      }
                    />
                    <Box width="190px" my={1} className={s.boxImage}>
                      <label htmlFor="uploadarea">
                        <AppCardMedia
                          mediaObj={values.cover}
                          path={photoImg}
                          error={!!touched.cover && !!errors.cover}
                          sx={{ objectFit: 'contain' }}
                        />
                      </label>
                    </Box>
                    {!!touched.cover && !!errors.cover && (
                      <FormHelperText error>{t('required')}</FormHelperText>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Box mb={3}>
                    <FieldLabel title={t('name')} />
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      hiddenLabel
                      variant="filled"
                      size="small"
                      className={s.inputField}
                    />
                  </Box>
                  <Box mb={3}>
                    <FieldLabel title={t('description')} />
                    <Field
                      component={TextField}
                      name="description"
                      type="text"
                      hiddenLabel
                      multiline
                      rows={4}
                      variant="filled"
                      size="small"
                      className={cx(s.inputField, s.multiLine)}
                    />
                  </Box>
                  <Box mt={5} className={s.boxSubmitBtn}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      {t('create_val_cur', {
                        value: feeCreateTokenStr,
                        currency:
                          metaMaskStore.chainData?.currency.toUpperCase()
                      })}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </CustomContainer>
      <CreateTokenSuccessDialog collectionId={collectionId} />
      <CreateTokenErrorDialog />
    </React.Fragment>
  );
};

export default observer(CreateNft);
