import {
  action,
  observable,
  makeObservable,
  computed,
  runInAction
} from 'mobx';
import {
  ApolloClient,
  InMemoryCache,
  gql,
  NormalizedCacheObject
} from '@apollo/client';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import { debounce } from 'lodash';
import { handleTgRemoteData, createRemoteData } from '../Utils/RemoteData';
import { reloadBrowser } from '../Utils/Reload';
import { configNetList } from '../configNetList';
import { isMobile } from 'react-device-detect';

const ENV_APP = window.config.ENV_APP as 'test' | 'prod';

export enum MetamaskState {
  Initial,
  NotInstalled,
  Installed,
  Connected
}

class MetaMaskStore {
  static #provider: null | ethers.providers.Web3Provider = null;
  static get provider() {
    return this.#provider;
  }

  @observable clientProveMe: null | ApolloClient<NormalizedCacheObject> = null;
  @observable clientMintMe: null | ApolloClient<NormalizedCacheObject> = null;
  configNetList;

  @observable state = MetamaskState.Initial;
  @observable currentAccount = '';
  @observable chainId = '';
  @observable chainData: undefined | ChainDataType;
  @observable provider: null | ethers.providers.Web3Provider = null;
  @observable balance = '-';
  @observable config = createRemoteData<ConfigType>();
  @observable configProveMe = createRemoteData<ConfigProvMeType>();

  constructor() {
    makeObservable(this);
    this.startApp();
    this.configNetList = configNetList;
  }

  switchChain = async (id: string) => {
    const dataChain = configNetList[ENV_APP].find(i => i.id === id);
    if (!dataChain || !window.ethereum) {
      return;
    }

    const addChain = async () => {
      return await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: dataChain.id,
            chainName: dataChain.name,
            nativeCurrency: {
              symbol: dataChain.currency,
              decimals: dataChain.decimals
            },
            rpcUrls: dataChain.rpcUrls,
            blockExplorerUrls: dataChain.blockExplorerUrls
          }
        ]
      });
    };

    if (isMobile) {
      try {
        return addChain();
      } catch (addError) {
        console.log('Error', addError);
      }
      return;
    }

    try {
      return await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: dataChain.id }]
      });
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError?.code === 4902) {
        try {
          return addChain();
        } catch (addError) {
          console.log('Error', addError);
        }
      }
      console.log('Error', switchError);
    }
  };

  @action
  checkMintMeClient = () => {
    if (this.clientMintMe) {
      this.clientMintMe.cache.reset();
    } else {
      if (this.chainData?.tgUriMintMe) {
        this.clientMintMe = new ApolloClient({
          uri: this.chainData.tgUriMintMe,
          cache: new InMemoryCache()
        });
      }
    }
  };

  @action
  checkProveMeClient = () => {
    if (this.clientProveMe) {
      this.clientProveMe.cache.reset();
    } else {
      if (this.chainData?.tgUriProveMe) {
        this.clientProveMe = new ApolloClient({
          uri: this.chainData.tgUriProveMe,
          cache: new InMemoryCache()
        });
      }
    }
  };

  handleChainChanged = () => {
    // We recommend reloading the page, unless you must do otherwise
    reloadBrowser();
  };

  @action
  async getConfig() {
    this.checkMintMeClient();
    if (!this.clientMintMe) return;
    const client = this.clientMintMe;
    return handleTgRemoteData(
      this.config,
      async () =>
        client.query({
          query: gql`
            query getConfiguration {
              configuration(id: "MintMeConfiguration") {
                id
                feeWei
                contract
                mintMePerm
              }
            }
          `
        }),
      data => {
        const result = (data as { configuration: ConfigType }).configuration;
        return {
          ...result,
          licenses: [
            {
              title: 'MintMe Public License V1',
              file_cid:
                'bafkreihlwugqwxwt57sg4wnc6j2mqww7q37utbrr4ibvbi5kmff3a4wmk4'
            },
            {
              title: 'None', // must be the last - 1
              file_cid: ''
            },
            {
              title: 'Custom license', // must be the last
              file_cid: ''
            }
          ]
        };
      }
    );
  }

  @action
  async getConfigProveMe() {
    this.checkProveMeClient();
    if (!this.clientProveMe) return;
    const client = this.clientProveMe;
    return handleTgRemoteData(
      this.configProveMe,
      async () =>
        client.query({
          query: gql`
            query getConfigurationProveMe {
              configuration(id: "ProveMeCfg") {
                contract
              }
            }
          `
        }),
      data => {
        const result = (data as { configuration: ConfigProvMeType })
          .configuration;
        return result;
      }
    );
  }

  @action
  handleAccountsChanged = (accounts: any) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      this.setState(MetamaskState.Installed);
      this.currentAccount = '';
    } else if (accounts[0] !== this.currentAccount) {
      this.currentAccount = accounts[0];
      this.setState(MetamaskState.Connected);
    }
  };

  @action
  async startApp() {
    const provider = await detectEthereumProvider();
    if (!provider) {
      this.setState(MetamaskState.NotInstalled);
      return;
    }
    // If the provider returned by detectEthereumProvider is not the same as
    // window.ethereum, something is overwriting it, perhaps another wallet.
    if (provider !== window.ethereum) {
      console.error('Do you have multiple wallets installed?');
      runInAction(() => {
        this.setState(MetamaskState.Installed);
      });
      return;
    }
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    runInAction(() => {
      this.setChainId(chainId);
    });
    window.ethereum.on('chainChanged', this.handleChainChanged);

    window.ethereum
      .request({ method: 'eth_accounts' })
      .then(this.handleAccountsChanged)
      .catch((err: any) => {
        runInAction(() => {
          this.setState(MetamaskState.Installed);
        });
        // Some unexpected error.
        // For backwards compatibility reasons, if no accounts are available,
        // eth_accounts will return an empty array.
        console.error(err);
      });
    window.ethereum.on('accountsChanged', this.handleAccountsChanged);
  }

  @action
  setChainId = (chainId: string) => {
    this.chainId = chainId;
    this.chainData = this.configNetList[ENV_APP].find(i => i.id === chainId);
    this.getConfig();
    this.getConfigProveMe();
  };

  @action
  async connect() {
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(this.handleAccountsChanged)
      .catch((err: any) => {
        if (err.code === 4001) {
          // Please connect to MetaMask
        } else {
          console.error(err);
          // to do: block button "Click here to connect The Wall with your Metamask"
        }
      });
  }

  @action
  setState = (state: MetamaskState) => {
    this.state = state;
    if (
      state === MetamaskState.Connected ||
      state === MetamaskState.Installed
    ) {
      this.setProvider();
    } else {
      this.setProvider(false);
    }
  };

  @action
  async setProvider(notNull = true) {
    if (!this.currentAccount) return;
    if (this.provider) {
      reloadBrowser();
      // this.provider.removeAllListeners();
    }
    if (notNull) {
      this.provider = new ethers.providers.Web3Provider(window.ethereum);
      MetaMaskStore.#provider = this.provider;

      const filterToAccount = {
        topics: [
          null,
          null,
          null,
          ethers.utils.hexZeroPad(this.currentAccount, 32)
        ]
      };

      const filterFromAccount = {
        topics: [
          null,
          null,
          ethers.utils.hexZeroPad(this.currentAccount, 32),
          null
        ]
      };

      const toAccount = () => {
        this.setBalance();
      };

      const fromAccount = () => {
        this.setBalance();
      };

      this.provider.on(filterToAccount, toAccount);
      this.provider.on(filterFromAccount, fromAccount);
    } else {
      this.provider = null;
      MetaMaskStore.#provider = null;
    }
    this.setBalance();
  }

  @action
  setBalance = debounce(async () => {
    if (this.provider && this.state === MetamaskState.Connected) {
      const balance = await this.provider.getBalance(this.currentAccount);
      runInAction(() => {
        this.balance = Number(ethers.utils.formatUnits(balance, 18)).toFixed(4);
      });
    } else {
      this.balance = '-';
    }
  }, 1000);

  @computed
  get readOnly() {
    return !this.chainData || !this.currentAccount;
  }
}

export default MetaMaskStore;
