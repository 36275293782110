import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box, Button, Link } from '@mui/material';
import cx from 'classnames';
import { MetamaskState } from '../../../Stores/MetaMaskStore';
import { useStores } from '../../../Stores/RootStore';
import InformationDialog from '../../Dialogs/InformationDialog';
import {
  POLYGON_TEST_ID,
  POLYGON_ID,
  SMART_CHAIN_TEST_ID,
  SMART_CHAIN_ID
} from '../../../configNetList';
import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import iconMintMe from '../../../Resources/Instructions/icon_mintme.svg';
import iconMetamask from '../../../Resources/Instructions/icon_metamask.svg';
import imgStep1 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_1.png';
import imgStep2 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_2.png';
import imgStep3 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_3.png';
import imgStep4 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_4.png';
import imgStep5 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_5.png';
import imgStep6 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_6.png';
import imgStep7 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_7.png';
import imgStep8 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_8.png';
import imgStep9 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_9.png';
import imgStep10 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_10.png';
import imgStep11 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_11.png';
import imgStep12 from '../../../Resources/Instructions/HowToStartWithMintMe/how_to_start_with_mintme_step_12.png';
import imgSwitching1 from '../../../Resources/Instructions/HowToStartWithMintMe/switching_1.png';
import imgSwitching2 from '../../../Resources/Instructions/HowToStartWithMintMe/switching_2.png';
import iconPolygon from '../../../Resources/Instructions/icon_polygon.svg';
import iconPolygonS from '../../../Resources/Instructions/icon_polygon_s.svg';
import iconSmartChain from '../../../Resources/Instructions/icon_smart_chain.svg';
import iconSmartChainS from '../../../Resources/Instructions/icon_smart_chain_s.svg';
import iconChrome from '../../../Resources/Instructions/icon_chrome.svg';
import iconFirefox from '../../../Resources/Instructions/icon_firefox.svg';
import iconEdge from '../../../Resources/Instructions/icon_edge.svg';
import iconBrave from '../../../Resources/Instructions/icon_brave.svg';
import metamaskLogo from '../../../Resources/metamaskLogo.svg';
import s from '../Instructions.module.scss';

const ENV_APP = window.config.ENV_APP as 'test' | 'prod';

export const AddChainBtn = ({
  id,
  icon,
  color,
  text,
  infoText
}: {
  id: string;
  icon: string;
  color: string;
  text: string;
  infoText: string;
}) => {
  const { t } = useTranslation();
  const { metaMaskStore } = useStores();
  const [contentDialog, setContentDialog] =
    useState<Nullable<JSX.Element>>(null);
  const handleClick = async () => {
    if (metaMaskStore.state === MetamaskState.NotInstalled) {
      setContentDialog(
        <>
          <Box>
            <img src={metamaskLogo} alt="Metamask" />
          </Box>
          <Link
            href="https://metamask.io/download.html"
            target="_blank"
            variant="h5"
            color="textPrimary"
            className={s.text}
            underline="none"
            dangerouslySetInnerHTML={{ __html: t('install_metamask') }}
          />
        </>
      );
    } else {
      const result = await metaMaskStore.switchChain(id);
      if (result === null) {
        setTimeout(() => {
          setContentDialog(<Typography variant="body1">{infoText}</Typography>);
        }, 1000);
      }
    }
  };
  return (
    <>
      <InformationDialog
        contentDialog={contentDialog}
        setContentDialog={setContentDialog}
      />
      <Box mt={2}>
        <Button
          className={cx(s.addNetworkBtn, s[color])}
          variant="outlined"
          startIcon={<img src={icon} alt="" className={s.iconBtn} />}
          onClick={handleClick}
        >
          <span className={s.btnText}>{text}</span>
        </Button>
      </Box>
    </>
  );
};

const HowToStartWithMintMe = () => {
  const { t } = useTranslation();

  const steps: InstructionStepType[] = [
    {
      img: imgStep1,
      text: 'how_to_start_with_mintme_step_1',
      index: 1
    },
    {
      img: imgStep2,
      text: 'how_to_start_with_mintme_step_2',
      index: 2
    },
    {
      img: imgStep3,
      text: 'how_to_start_with_mintme_step_3',
      index: 3
    },
    {
      img: imgStep4,
      text: 'how_to_start_with_mintme_step_4',
      index: 4
    },
    {
      img: imgStep5,
      text: 'how_to_start_with_mintme_step_5',
      index: 5
    },
    {
      img: imgStep6,
      text: 'how_to_start_with_mintme_step_6',
      index: 6
    },
    {
      img: imgStep7,
      text: 'how_to_start_with_mintme_step_7',
      index: 7
    },
    {
      img: imgStep8,
      text: 'how_to_start_with_mintme_step_8',
      index: 8
    },
    {
      img: imgStep9,
      text: 'how_to_start_with_mintme_step_9',
      index: 9
    },
    {
      img: imgStep10,
      text: 'how_to_start_with_mintme_step_10',
      index: 10
    },
    {
      img: imgStep11,
      text: 'how_to_start_with_mintme_step_11',
      index: 11
    },
    {
      img: imgStep12,
      text: 'how_to_start_with_mintme_step_12',
      index: 12
    }
  ];

  const switching: InstructionStepType[] = [
    {
      img: imgSwitching1,
      text: 'switching_between_networks_on_mintme_1',
      index: 1
    },
    {
      img: imgSwitching2,
      text: 'switching_between_networks_on_mintme_2',
      index: 2
    }
  ];

  const browsers: { href: string; name: string; icon: string }[] = [
    {
      href: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
      name: 'Chrome',
      icon: iconChrome
    },
    {
      href: 'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/',
      name: 'Firefox',
      icon: iconFirefox
    },
    {
      href: 'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US',
      name: 'Edge',
      icon: iconEdge
    },
    {
      href: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
      name: 'Brave',
      icon: iconBrave
    }
  ];
  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('how_to_start_with_mintme?')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Infobox
            image={iconMintMe}
            title={t('what_is_mintme?')}
            text={t('mintme_allows_you_to_create_')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_this_guide_about?')}
            text={t('this_guide_is_intended_to_explain_')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Infobox
            image={iconMetamask}
            title={t('what_is_the_metamask_app?')}
            text={t('the_metamask_app_is_an_interface_')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">
          {t('officially_supported_browsers_by_metamask')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={2} xs={12} />
          {browsers.map(i => (
            <Grid item md={2} xs={12} className={s.browsersItem} key={i.name}>
              <Link
                href={i.href}
                target="_blank"
                color="textPrimary"
                underline="none"
              >
                <img src={i.icon} alt="" />
                <Typography variant="body1">{i.name}</Typography>
              </Link>
            </Grid>
          ))}
          <Grid item md={2} xs={12} />
        </Grid>
      </section>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2} className={s.infoSectionRow}>
          {steps.map(i => (
            <Grid item md={6} key={i.index}>
              <Infobox num={i.index} text={t(i.text)} />
              <FancyBoxImage image={i.img} />
            </Grid>
          ))}
        </Grid>
      </section>
      <section>
        <Typography variant="h3">
          {t('adding_another_supported_networks_')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Infobox
              image={iconPolygon}
              title={t('polygon_network')}
              text={t('to_add_polygon_network_to_')}
              component={
                <AddChainBtn
                  id={ENV_APP === 'test' ? POLYGON_TEST_ID : POLYGON_ID}
                  icon={iconPolygonS}
                  color={'blue'}
                  text={t('add_polygon_network')}
                  infoText={t('polygon_network_is_connected')}
                />
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              image={iconSmartChain}
              title={t('binance_smart_chain')}
              text={t('to_add_binance_smart_chain_')}
              component={
                <AddChainBtn
                  id={ENV_APP === 'test' ? SMART_CHAIN_TEST_ID : SMART_CHAIN_ID}
                  icon={iconSmartChainS}
                  color={'yellow'}
                  text={t('add_bsc_network')}
                  infoText={t('bsc_network_is_connected')}
                />
              }
            />
          </Grid>
        </Grid>
      </section>
      <section>
        <Typography variant="h3">
          {t('switching_between_networks_on_mintme')}
        </Typography>
        <Grid container spacing={2} className={s.infoSectionRow}>
          {switching.map(i => (
            <Grid item md={6} key={i.index}>
              <Infobox num={i.index} text={t(i.text)} />
              <FancyBoxImage image={i.img} />
            </Grid>
          ))}
        </Grid>
      </section>
    </div>
  );
};

export default HowToStartWithMintMe;
