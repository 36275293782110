import { observable, set, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';

export enum RemoteDataState {
  'INITIAL' = 'INITIAL',
  'REQUEST' = 'REQUEST',
  'SUCCESS' = 'SUCCESS',
  'FAILURE' = 'FAILURE'
}

interface RemoteData<T> {
  state: RemoteDataState;
  value: Nullable<T>;
}

class RemoteData<T> {
  @observable state = RemoteDataState.INITIAL;
  @observable value: Nullable<T> = null;
}

export const createRemoteData = <T>(initialValue?: T): RemoteData<T> => ({
  state: RemoteDataState.INITIAL,
  value: initialValue || null
});

export const handleTgRemoteData = async <T>(
  remoteData: RemoteData<T>,
  method: () => Promise<{ data: {} }>,
  transform: (response: {}) => T,
  onSuccess?: (response?: {}) => any
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  try {
    let value = await method();
    if (onSuccess) {
      onSuccess(value.data);
    }
    runInAction(() => {
      set(remoteData, {
        value: transform ? transform(value.data) : value.data,
        state: RemoteDataState.SUCCESS
      });
    });
    return null as any;
  } catch (e) {
    runInAction(() => {
      set(remoteData, { state: RemoteDataState.FAILURE });
    });
    return null as any;
  }
};

export const handleTgRemoteDataPage = async <T>(
  remoteData: RemoteData<T>,
  method: (id: string) => Promise<{ data: {} }>,
  transform: (response: {}[]) => T,
  data: any[],
  getLastId: (response: {}) => string | null,
  id: string
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  try {
    let value = await method(id);
    const lastId = getLastId(value.data);
    data.push(value.data);
    if (lastId) {
      handleTgRemoteDataPage(
        remoteData,
        method,
        transform,
        data,
        getLastId,
        lastId
      );
      return null as any;
    }
    runInAction(() => {
      set(remoteData, {
        value: transform(data),
        state: RemoteDataState.SUCCESS
      });
    });
    return null as any;
  } catch (e) {
    runInAction(() => {
      set(remoteData, { state: RemoteDataState.FAILURE });
    });
    return null as any;
  }
};

export const handleRemoteData = async <T>(
  remoteData: RemoteData<T>,
  method: () => Promise<AxiosResponse<T>>,
  onSuccess?: (response?: T) => any,
  transform?: (response?: T) => T
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  try {
    let value = await method();
    if (onSuccess) {
      onSuccess(value.data);
    }
    runInAction(() => {
      set(remoteData, {
        value: transform ? transform(value.data) : value.data,
        state: RemoteDataState.SUCCESS
      });
    });
    return value.data;
  } catch (e) {
    runInAction(() => {
      set(remoteData, { state: RemoteDataState.FAILURE });
    });
    return null as any;
  }
};

export const handleContractRemoteData = <T>(
  remoteData: RemoteData<T>,
  error: Error,
  response: string,
  transactions?: TransactionHashStatusType[]
) => {
  runInAction(() => {
    if (error) {
      set(remoteData, { state: RemoteDataState.FAILURE });
    } else {
      set(remoteData, { state: RemoteDataState.SUCCESS, value: response });
      if (transactions && transactions.length >= 0) {
        set(transactions, [...transactions, { hash: response, status: '0' }]);
      }
    }
  });
};

export default RemoteData;
