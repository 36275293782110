import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import iconMintMe from '../../../Resources/Instructions/icon_mintme.svg';
import imgCollectionStep1 from '../../../Resources/Instructions/HowToTransferCollectionOrNFT/how_to_transfer_collection_01.png';
import imgCollectionStep2 from '../../../Resources/Instructions/HowToTransferCollectionOrNFT/how_to_transfer_collection_02.png';
import imgCollectionStep3 from '../../../Resources/Instructions/HowToTransferCollectionOrNFT/how_to_transfer_collection_03.png';
import imgCollectionStep4 from '../../../Resources/Instructions/HowToTransferCollectionOrNFT/how_to_transfer_collection_04.png';
import imgTokenStep2 from '../../../Resources/Instructions/HowToTransferCollectionOrNFT/how_to_transfer_token_02.png';
import imgTokenStep3 from '../../../Resources/Instructions/HowToTransferCollectionOrNFT/how_to_transfer_token_03.png';
import imgTokenStep4 from '../../../Resources/Instructions/HowToTransferCollectionOrNFT/how_to_transfer_token_04.png';

import s from '../Instructions.module.scss';

const HowToTransferCollectionOrNFT = () => {
  const { t } = useTranslation();

  const howToTransferCollections: InstructionStepType[] = [
    {
      img: imgCollectionStep1,
      text: 'how_to_transfer_collection_step_1',
      index: 1
    },
    {
      img: imgCollectionStep2,
      text: 'how_to_transfer_collection_step_2',
      index: 2
    },
    {
      img: imgCollectionStep3,
      text: 'how_to_transfer_collection_step_3',
      index: 3
    },
    {
      img: imgCollectionStep4,
      text: 'how_to_transfer_collection_step_4',
      index: 4
    }
  ];

  const howToTransferNFTs: InstructionStepType[] = [
    {
      img: imgCollectionStep4,
      text: 'how_to_transfer_token_step_1',
      index: 1
    },
    {
      img: imgTokenStep2,
      text: 'how_to_transfer_token_step_2',
      index: 2
    },
    {
      img: imgTokenStep3,
      text: 'how_to_transfer_token_step_3',
      index: 3
    },
    {
      img: imgTokenStep4,
      text: 'how_to_transfer_token_step_4',
      index: 4
    }
  ];

  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('how_to_transfer_collection_or_nft?')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Infobox
            image={iconMintMe}
            title={t('what_is_mintme?')}
            text={t('mintme_allows_you_to_create_')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_this_guide_about?')}
            text={t('this_guide_is_intended_to_describe_how_to_transfer')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Infobox
            icon="faInfoCircle"
            title={t('just_one_moment_before_we_start')}
            text={t('in_order_to_create_collections_and_mint_')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('how_to_transfer_collection')}</Typography>
        <Grid container spacing={2} className={s.infoSectionRow}>
          {howToTransferCollections.map(i => (
            <Grid item md={6} key={i.index}>
              <Infobox num={i.index} text={t(i.text)} />
              <FancyBoxImage image={i.img} />
            </Grid>
          ))}
        </Grid>
      </section>
      <section>
        <Typography variant="h3">{t('how_to_transfer_token')}</Typography>
        <Grid container spacing={2} className={s.infoSectionRow}>
          {howToTransferNFTs.map(i => (
            <Grid item md={6} key={i.index}>
              <Infobox num={i.index} text={t(i.text)} />
              <FancyBoxImage image={i.img} />
            </Grid>
          ))}
        </Grid>
      </section>
    </div>
  );
};

export default HowToTransferCollectionOrNFT;
