import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import trashIcon from '../../Resources/trash_icon.svg';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Stores/RootStore';
import s from './Dialog.module.scss';

interface DeleteTokenDialogProps {
  token: TokenTypeTg;
  collectionId: string;
}

const DeleteTokenDialog: React.FC<DeleteTokenDialogProps> = ({
  token,
  collectionId
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { collectionsStore } = useStores();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    collectionsStore.deleteToken(token, collectionId);
    setOpen(false);
  };

  return (
    <div>
      <img
        className={s.imgIcon}
        src={trashIcon}
        alt={t('delete')}
        onClick={handleClickOpen}
        title={t('delete')}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <DialogContent className={s.dialog}>
          <DialogContentText
            id="alert-dialog-description"
            variant="h2"
            className={s.content}
          >
            {t('delete_token_text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={s.dialogActions}>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            {t('no')}
          </Button>
          <Button variant="contained" onClick={handleYes}>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteTokenDialog;
