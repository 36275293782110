import Linkify from 'react-linkify';

const FormatDesc = ({ text }: { text: string }) => {
  return (
    <>
      {text.split('\n\n').map((str: any) => (
        <Linkify>
          <p>{str.split('\n').join(' ')}</p>
        </Linkify>
      ))}
    </>
  );
};

export default FormatDesc;
