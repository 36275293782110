import * as yup from 'yup';

const schema = yup.object({
  symbol: yup.string().required('Required'),
  name: yup.string().required('Required'),
  cover: yup.object({
    file: yup.mixed().required('Required')
  })
});

export default schema;
