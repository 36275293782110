import React from 'react';
import { Typography } from '@mui/material';

interface FieldLabelProps {
  title: string;
  mb?: number;
}

const FieldLabel: React.FC<FieldLabelProps> = ({ title, mb = 1 }) => (
  <Typography variant="body2" sx={{ fontWeight: 'bold' }} mb={mb}>
    {title}
  </Typography>
);

export default FieldLabel;
