import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Link } from '@mui/material';
import { isMobile } from 'react-device-detect';

import iconSmartcontract from '../../Resources/Instructions/icon_smart_contract.svg';
import iconMintMe from '../../Resources/Instructions/icon_mintme_square.svg';
import iconCreateCollection from '../../Resources/Instructions/icon_create_collection.svg';
import iconOpensea from '../../Resources/Instructions/icon_opensea_square.svg';
import iconCodeBlock from '../../Resources/Instructions/icon_code_block.svg';
import iconTransferCollectionOrNFT from '../../Resources/Instructions/icon_transfer_guide.svg';

import s from './Instructions.module.scss';

const InstructionsMenu = () => {
  const { t } = useTranslation();

  const itemsMenu = [
    {
      title: t('how_to_start_with_mintme?'),
      href: '/instructions/how-to-start-with-mintme',
      icon: iconMintMe
    },
    {
      title: t('how_to_create_collection_and_mint_nft?'),
      href: '/instructions/how-to-create-collection',
      icon: iconCreateCollection
    },
    {
      title: t('how_to_transfer_collection_or_nft?'),
      href: '/instructions/how-to-transfer',
      icon: iconTransferCollectionOrNFT
    },
    {
      title: t('how_to_see_my_collection_on_opensea?'),
      href: '/instructions/how-see-collection-on-opensea',
      icon: iconOpensea
    },
    {
      title: t('smartcontracts_and_supported_networks'),
      href: '/instructions/smartcontracts-and-supported-networks',
      icon: iconSmartcontract
    },
    {
      title: t('programming_guide_how_to_integrate_'),
      href: '/instructions/how-to-integrate-mintme',
      icon: iconCodeBlock
    }
  ];
  return (
    <div className={s.instructionsMenu}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item md={1} />
        <Grid item md={10}>
          <Typography variant="h1" color="primary">
            {t('instructions')}
          </Typography>
          {itemsMenu.map((i, index) => (
            <div className={s.itemMenu} key={index}>
              <div className={s.itemIcon}>
                <img src={i.icon} alt="" />
              </div>
              <Link href={i.href} target={isMobile ? '_self' : '_blank'}>
                {i.title}
              </Link>
            </div>
          ))}
        </Grid>
        <Grid item md={1} />
      </Grid>
    </div>
  );
};

export default InstructionsMenu;
