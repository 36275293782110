import { useState } from 'react';
import { Typography, Box, Button, Alert } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useStores } from '../../Stores/RootStore';
import s from './AlertInvalidExternalUrl.module.scss';
import { oc } from 'ts-optchain';
import { v4 } from 'uuid';
import { uploadJson } from '../../../src/Utils/Storage';

interface InvalidExternalUrlProps {
  nft: TokenTypeTg;
  id: string;
  collectionId: string;
  isCorrectExternalUrl: boolean;
  setShowInvalidExternalUrl: (show: boolean) => void;
}

/**
 * Warning. Pops up if the external URL is invalid or the content of the token is not up to date.
 *
 * @param {TokenTypeTg} nft - nft token
 * @param {string} id - token id
 * @param {string} collectionId - collection id
 * @param {boolean} isCorrectExternalUrl - a boolean value tells
 * if the external url of the token is correct
 * @param {(show: boolean) => void} setShowInvalidExternalUrl -
 * state change function to control the opening and closing of the alert
 */
export const AlertInvalidExternalUrl = ({
  nft,
  id,
  collectionId,
  isCorrectExternalUrl,
  setShowInvalidExternalUrl
}: InvalidExternalUrlProps) => {
  const { collectionsStore, metaMaskStore } = useStores();
  const { siteURI } = collectionsStore;
  const [isSubmitting, setSubmitting] = useState(false);

  const provider = oc(metaMaskStore).provider(null);
  const netName = metaMaskStore.chainData?.nameForUrl;

  if (!provider || !id || !collectionId || !netName) return null;

  if (!isCorrectExternalUrl) {
    nft.external_url = `${siteURI}/token/${netName}/${collectionId}/${v4()}`;
  }

  if (!nft.animation_url) {
    nft.animation_url = '';
  }

  const { name, description, image, external_url, animation_url, content } =
    nft;

  const fixExternalUrl = async () => {
    setSubmitting(true);
    const contentCid = await uploadJson({
      name,
      description,
      image,
      external_url,
      animation_url,
      content
    });
    if (contentCid) {
      collectionsStore.setContentToken(id, contentCid, provider, collectionId);
    }
    setSubmitting(false);
  };

  return (
    <Alert
      severity="warning"
      sx={{ backgroundColor: '#FBD89E' }}
      iconMapping={{
        warning: (
          <Box className={s.attentionIcon}>
            <WarningRoundedIcon fontSize="large" />
          </Box>
        )
      }}
    >
      <Typography sx={{ color: 'black' }}>
        We have improved the storage format of token metadata to make them even
        more compatible with other platforms and marketplaces. We recommend that
        you switch to the updated format. This will happen automatically after
        clicking on "Reformat"
      </Typography>
      <Box mt={1} className={s.boxBtn}>
        <Button
          variant="contained"
          onClick={fixExternalUrl}
          disabled={isSubmitting}
        >
          Reformat
        </Button>
        <Button
          className={s.btn}
          variant="outlined"
          onClick={() => {
            setShowInvalidExternalUrl(false);
          }}
        >
          Don't show again
        </Button>
      </Box>
    </Alert>
  );
};
