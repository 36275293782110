import React from 'react';
import { oc } from 'ts-optchain';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Stores/RootStore';

import {
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress
} from '@mui/material';
import { dateStrFromTs } from '../../Helpers/helpers';
import { useTranslation } from 'react-i18next';
import s from './ReportVerifyProver.module.scss';

interface ReportVerifyProverProps {
  id: string;
}

const ReportVerifyProver: React.FC<ReportVerifyProverProps> = ({ id }) => {
  const { t } = useTranslation();
  const { collectionsStore } = useStores();

  const [open, setOpen] = React.useState(false);

  const verifyData = oc(collectionsStore)
    .tokensVerifyDataTg.value([])
    .find((i: TokenVerifyDataTgType) => i.tokenId === id);

  if (verifyData?.status) {
    collectionsStore.getReportVerify(verifyData);
  }
  const dataValue = verifyData?.data;

  if (!dataValue) {
    return <CircularProgress size={16} />;
  }

  let dataValueArr: {
    title: string;
    value: string;
    href?: string;
  }[] = [];

  if (dataValue.clapperboard) {
    const transactionHash =
      oc(dataValue).clapperboard.submit.transactionInfo.transactionHash('');
    const blockHeight =
      oc(dataValue).clapperboard.reference.blockInfo.blockHeight('');
    const submittingTimestamp =
      oc(
        dataValue
      ).clapperboard.submit.transactionInfo.blockInfo.blockTimestamp('');
    const referenceTimestamp =
      oc(dataValue).clapperboard.reference.blockInfo.blockTimestamp('');

    dataValueArr = [
      {
        title: `${t('proven')}:`,
        value: oc(dataValue).proven(false) ? t('yes') : t('no')
      },
      {
        title: `${t('detail')}:`,
        value: oc(dataValue).detail('')
      },
      {
        title: `${t('media_hash')}:`,
        value: oc(dataValue).prover.mediaHash('')
      },
      {
        title: `${t('submitted_message')}:`,
        value: oc(dataValue).clapperboard.submit.message('')
      },
      {
        title: `${t('transaction_hash_of_message_submitting')}:`,
        value: transactionHash,
        href: 'https://explorer.nemtool.com/?#/s_tx?hash=' + transactionHash
      },
      {
        title: `${t('date_time_of_message_submitting')}:`,
        value: submittingTimestamp ? dateStrFromTs(submittingTimestamp) : ''
      },
      {
        title: `${t('block_height_of_message_reference')}:`,
        value: blockHeight,
        href: 'https://explorer.nemtool.com/?#/s_block?height=' + blockHeight
      },
      {
        title: `${t('date_time_of_message_reference')}:`,
        value: referenceTimestamp ? dateStrFromTs(referenceTimestamp) : ''
      },
      {
        title: `${t('pdf_certificate')}:`,
        value: dataValue.certificateUrl ? t('download_pdf_certificate') : '',
        href: dataValue.certificateUrl
      }
    ];
  } else {
    const transactionHash =
      oc(dataValue).prover.submit.transactionInfo.transactionHash('');
    const blockHeightSwype =
      oc(dataValue).prover.reference.blockInfo.blockHeight('');
    const submittingTimestamp =
      oc(dataValue).prover.submit.transactionInfo.blockInfo.blockTimestamp('');
    const swypeTimestamp =
      oc(dataValue).prover.reference.blockInfo.blockTimestamp('');

    dataValueArr = [
      {
        title: `${t('proven')}:`,
        value: oc(dataValue).proven(false) ? t('yes') : t('no')
      },
      {
        title: `${t('detail')}:`,
        value: oc(dataValue).detail('')
      },
      {
        title: `${t('media_hash')}:`,
        value: oc(dataValue).prover.mediaHash('')
      },
      {
        title: `${t('transaction_hash_of_media_submitting')}:`,
        value: transactionHash,
        href: 'https://explorer.nemtool.com/?#/s_tx?hash=' + transactionHash
      },
      {
        title: `${t('date_time_of_media_submitting')}:`,
        value: submittingTimestamp ? dateStrFromTs(submittingTimestamp) : ''
      },
      {
        title: `${t('swype_code')}:`,
        value: oc(dataValue).prover.reference.swype('')
      },
      {
        title: `${t('swype_begin_end')}:`,
        value: `${oc(dataValue).prover.swypeSearch.swypeCodeBeginOffset(
          ''
        )}/${oc(dataValue).prover.swypeSearch.swypeCodeEndOffset('')}`
      },
      {
        title: `${t('block_height_of_swype')}:`,
        value: blockHeightSwype,
        href:
          'https://explorer.nemtool.com/?#/s_block?height=' + blockHeightSwype
      },
      {
        title: `${t('date_time_of_swype')}:`,
        value: swypeTimestamp ? dateStrFromTs(swypeTimestamp) : ''
      },
      {
        title: `${t('pdf_certificate')}:`,
        value: dataValue.certificateUrl ? t('download_pdf_certificate') : '',
        href: dataValue.certificateUrl
      }
    ];
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link
        className={s.title}
        variant="body2"
        sx={{ fontWeight: 'bold' }}
        onClick={handleClickOpen}
      >
        {t('report')}
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <DialogContent className={s.dialog}>
          <DialogContentText
            id="alert-dialog-description"
            variant="h2"
            className={s.titleContent}
            mb={2}
          >
            {t('report_by_prover')}
          </DialogContentText>
          <TableContainer className={s.table}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                {dataValueArr.map(i => {
                  if (!i.value) return null;
                  return (
                    <TableRow key={i.value}>
                      <TableCell>{i.title}</TableCell>
                      <TableCell align="right" className={s.right}>
                        {i.href ? (
                          <Link variant="body2" href={i.href} target="_blank">
                            {i.value}
                          </Link>
                        ) : (
                          i.value
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions className={s.dialogActions}>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(ReportVerifyProver);
