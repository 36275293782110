import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams, Link as RouterLink } from 'react-router-dom';
import NftCard from '../NftCard/NftCard';
import ButtonLink from '../../Components/ButtonLink/ButtonLink';
import { useStores } from '../../Stores/RootStore';
import arrowLeft from '../../Resources/ArrowLeft.svg';
import { configNetList } from '../../configNetList';
import { sortTokens } from '../../Helpers/helpers';
import { RemoteDataState } from '../../Utils/RemoteData';
import s from './Collection.module.scss';
import CustomContainer from '../../Components/CustomContainer/CustomContainer';

const ENV_APP = window.config.ENV_APP as 'test' | 'prod';

const Collection = () => {
  const { t } = useTranslation();
  const { net, collectionId } = useParams();
  const { metaMaskStore, collectionsStore } = useStores();
  const [tokens, setTokens] = useState<TokenTypeTg[]>([]);
  const [collection, setCollection] = useState<Nullable<CollectionTypeTg>>();

  useEffect(() => {
    if (!net) return;
    const chainData = configNetList[ENV_APP].find(i => i.nameForUrl === net);
    if (chainData) {
      metaMaskStore.setChainId(chainData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!collectionsStore.chainData?.id || !collectionId) return;
    collectionsStore.getAllCollectionsById(collectionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsStore.chainData?.id]);

  useEffect(() => {
    if (
      collectionId &&
      collectionsStore.allCollections.state === RemoteDataState.SUCCESS
    ) {
      const collection = collectionsStore.allCollections.value?.find(
        i => i.id === collectionId
      );
      if (collection) {
        setCollection(collection);
        setTokens(sortTokens(collection.tokens));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, collectionsStore.allCollections.state]);

  if (!collectionId || !collection) return null;

  const isUserCollection = collection.owner === metaMaskStore.currentAccount;

  const path = net ? `/showroom/${net}` : '/showroom';

  return (
    <React.Fragment>
      <CustomContainer className={s.main}>
        <Typography variant="h1">
          <RouterLink to={path}>
            <img src={arrowLeft} alt="" />
          </RouterLink>{' '}
          {collection.name}
        </Typography>
        {isUserCollection && (
          <ButtonLink
            to={`/create-nft/${collectionId}`}
            title={t('create_nft')}
            disabled={metaMaskStore.readOnly}
          />
        )}
        {tokens.length ? (
          <Box mt={4} className={s.list}>
            {tokens.map((i: any) => (
              <NftCard nft={i} key={i.tokenId} path={path} />
            ))}
          </Box>
        ) : (
          <Box mt={4}>
            <Typography variant="h2">{t('there_are_no_nfts_here')}</Typography>
          </Box>
        )}
      </CustomContainer>
    </React.Fragment>
  );
};

export default observer(Collection);
