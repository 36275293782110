import React, { useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams, Link as RouterLink } from 'react-router-dom';
import NftCard from '../NftCard/NftCard';
import { MetamaskState } from '../../Stores/MetaMaskStore';
import ButtonLink from '../../Components/ButtonLink/ButtonLink';
import { useStores } from '../../Stores/RootStore';
import arrowLeft from '../../Resources/ArrowLeft.svg';
import { sortTokens } from '../../Helpers/helpers';
import { RemoteDataState } from '../../Utils/RemoteData';
import s from './Collection.module.scss';
import CustomContainer from '../../Components/CustomContainer/CustomContainer';

const UserCollection = () => {
  const { t } = useTranslation();
  const { net, collectionId } = useParams();
  const { metaMaskStore, collectionsStore } = useStores();
  const { allUserCollections, getCollectionById } = collectionsStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (
      metaMaskStore.state === MetamaskState.NotInstalled ||
      metaMaskStore.state === MetamaskState.Installed
    ) {
      const pathname = window.location.pathname;
      if (pathname.indexOf('showroom') === -1) {
        navigate(`/showroom${pathname}`);
      }
    }
  }, [metaMaskStore.state, navigate]);

  if (metaMaskStore.state === MetamaskState.Initial) {
    return <CircularProgress size={32} />;
  }

  if (!collectionId) return null;

  const collection = getCollectionById(allUserCollections, collectionId);

  useEffect(() => {
    if (
      collectionsStore.collectionsTokenOwner.state ===
        RemoteDataState.SUCCESS &&
      !collection
    ) {
      navigate('/');
    }
  }, [collectionsStore.collectionsTokenOwner.state, collection, navigate]);

  if (!collection) return null;
  const isUserCollection = collection.owner === metaMaskStore.currentAccount;
  const tokens = sortTokens(
    collection.tokens.filter(i => i.owner === metaMaskStore.currentAccount)
  );

  return (
    <React.Fragment>
      <CustomContainer className={s.main}>
        <Typography variant="h1">
          <RouterLink to="/">
            <img src={arrowLeft} alt="" />
          </RouterLink>{' '}
          {collection.name}
        </Typography>
        {isUserCollection && (
          <ButtonLink
            to={`/create-nft/${collectionId}`}
            title={t('create_nft')}
            disabled={metaMaskStore.readOnly}
          />
        )}
        {tokens.length ? (
          <Box mt={4} className={s.list}>
            {tokens.map((i: any) => (
              <NftCard nft={i} key={i.tokenId} path={`/${net}`} />
            ))}
          </Box>
        ) : (
          <Box mt={4}>
            <Typography variant="h2">{t('no_nft')}</Typography>
          </Box>
        )}
      </CustomContainer>
    </React.Fragment>
  );
};

export default observer(UserCollection);
