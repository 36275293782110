import { Container, ContainerProps } from '@mui/material';
import { PropsWithChildren } from 'react';

/**
 * A component wrapper based on the MUI Container component
 *
 * @param {PropsWithChildren} children - children
 */
const CustomContainer = ({
  children,
  ...containerProps
}: PropsWithChildren<ContainerProps>) => (
  <Container {...containerProps} sx={{ pt: '55px' }} maxWidth="xl">
    {children}
  </Container>
);

export default CustomContainer;
