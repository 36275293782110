import { oc } from 'ts-optchain';

const shortAddress = (address: string) => {
  if (!address) return '';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const toShortAddress = (address: string, n: number) => {
  const length = address.length;
  if (n * 2 >= length - 2) return address;
  return `${address.slice(0, n + 2)}...${address.slice(-n)}`;
};

const getCidFromUri = (uri: string) => {
  if (!uri) return '';
  return uri.replace(/^.+\//, '');
};

const dateStrFromTs = (ts: number) => {
  let m = new Date(ts * 1000);
  return (
    m.getUTCFullYear() +
    '.' +
    ('0' + (m.getUTCMonth() + 1)).slice(-2) +
    '.' +
    ('0' + m.getUTCDate()).slice(-2) +
    ' ' +
    ('0' + m.getUTCHours()).slice(-2) +
    ':' +
    ('0' + m.getUTCMinutes()).slice(-2) +
    ':' +
    ('0' + m.getUTCSeconds()).slice(-2) +
    ' UTC'
  );
};

const dateStrFromTs2 = (ts: number) => {
  let m = new Date(ts * 1000);
  return (
    ('0' + m.getUTCDate()).slice(-2) +
    '.' +
    ('0' + (m.getUTCMonth() + 1)).slice(-2) +
    '.' +
    m.getUTCFullYear() +
    ' ' +
    ('0' + m.getUTCHours()).slice(-2) +
    ':' +
    ('0' + m.getUTCMinutes()).slice(-2) +
    ':' +
    ('0' + m.getUTCSeconds()).slice(-2)
  );
};

const dateStrFromTs3 = (ts: number) => {
  let m = new Date(ts * 1000);
  return (
    ('0' + m.getUTCHours()).slice(-2) +
    ':' +
    ('0' + m.getUTCMinutes()).slice(-2) +
    ' ' +
    ('0' + m.getUTCDate()).slice(-2) +
    '/' +
    ('0' + (m.getUTCMonth() + 1)).slice(-2) +
    '/' +
    m.getUTCFullYear()
  );
};

const getTokensCount = (tokens: TokenTypeTg[], address: string) => {
  const deleteToken = '0x0000000000000000000000000000000000000000';
  const amountToken = oc(tokens)([]).filter(
    (i: TokenTypeTg) => i.owner !== deleteToken
  ).length;
  const usersToken = oc(tokens)([]).filter(
    (i: TokenTypeTg) => i.owner === address
  ).length;
  return {
    usersToken,
    amountToken
  };
};

const sortTokens = (tokens: TokenTypeTg[]) => {
  const result = [...tokens];
  result.sort((a, b) => +a.tokenId - +b.tokenId);
  return result;
};

export {
  shortAddress,
  toShortAddress,
  getCidFromUri,
  dateStrFromTs,
  dateStrFromTs2,
  dateStrFromTs3,
  getTokensCount,
  sortTokens
};
