import React from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { MetamaskState } from '../../Stores/MetaMaskStore';
import { useStores } from '../../Stores/RootStore';
import CurrentAccount from '../../Components/BalanceInformer/CurrentAccount/CurrentAccount';
import PendingTransactions from './PendingTransactions/PendingTransactions';

import s from './BalanceInformer.module.scss';
import { t } from 'i18next';

const BalanceInformer = () => {
  const { metaMaskStore } = useStores();
  const { currentAccount, chainData, state, balance } = metaMaskStore;

  return (
    <div className={s.main}>
      {chainData ? (
        <Typography variant="body1" className={s.chain}>
          {chainData.name}
        </Typography>
      ) : (
        <Typography variant="body1" className={cx(s.chain, s.alert)}>
          {t('unknown_network')}
        </Typography>
      )}

      {state === MetamaskState.Connected && (
        <>
          <Typography variant="body1">
            {balance} {!!chainData && chainData?.currency.toUpperCase()}
          </Typography>
          <CurrentAccount account={currentAccount} checkChainId={!!chainData} />
        </>
      )}
      <PendingTransactions />
    </div>
  );
};

export default observer(BalanceInformer);
