import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import iconMintMe from '../../../Resources/Instructions/icon_mintme.svg';
import imgStep1 from '../../../Resources/Instructions/HowToCreateCollection/how_to_create_collection_step_1.png';
import imgStep2 from '../../../Resources/Instructions/HowToCreateCollection/how_to_create_collection_step_2.png';
import imgStep3 from '../../../Resources/Instructions/HowToCreateCollection/how_to_create_collection_step_3.png';
import imgStep4 from '../../../Resources/Instructions/HowToCreateCollection/how_to_create_collection_step_4.png';
import imgStep5 from '../../../Resources/Instructions/HowToCreateCollection/how_to_create_collection_step_5.png';
import imgStep6 from '../../../Resources/Instructions/HowToCreateCollection/how_to_create_collection_step_6.png';

import s from '../Instructions.module.scss';

const HowToCreateCollection = () => {
  const { t } = useTranslation();

  const steps: InstructionStepType[] = [
    {
      img: imgStep1,
      text: 'how_to_create_collection_step_1',
      index: 1
    },
    {
      img: imgStep2,
      text: 'how_to_create_collection_step_2',
      index: 2
    },
    {
      img: imgStep3,
      text: 'how_to_create_collection_step_3',
      index: 3
    },
    {
      img: imgStep4,
      text: 'how_to_create_collection_step_4',
      index: 4
    },
    {
      img: imgStep5,
      text: 'how_to_create_collection_step_5',
      index: 5
    },
    {
      img: imgStep6,
      text: 'how_to_create_collection_step_6',
      index: 6
    }
  ];

  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('how_to_create_collection_and_mint_nft?')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Infobox
            image={iconMintMe}
            title={t('what_is_mintme?')}
            text={t('mintme_allows_you_to_create_')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_this_guide_about?')}
            text={t(
              'this_guide_is_intended_to_explain_how_to_create_collections_'
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Infobox
            icon="faInfoCircle"
            title={t('just_one_moment_before_we_start')}
            text={t('in_order_to_create_collections_and_mint_')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2} className={s.infoSectionRow}>
          {steps.map(i => (
            <Grid item md={6} key={i.index}>
              <Infobox num={i.index} text={t(i.text)} />
              <FancyBoxImage image={i.img} />
            </Grid>
          ))}
        </Grid>
      </section>
    </div>
  );
};

export default HowToCreateCollection;
