import React, { useRef, useEffect, useState } from 'react';
import { t } from 'i18next';
import { oc } from 'ts-optchain';
import { ethers } from 'ethers';
import { autorun } from 'mobx';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography, Link } from '@mui/material';
import catImg from '../../Resources/Promo/cat.png';
import handImg from '../../Resources/Promo/hand.png';
import { MathRound10 } from '../../Helpers/decimalAdjust';
import { useStores } from '../../Stores/RootStore';
import { RemoteDataState } from '../../Utils/RemoteData';
import {
  BuyMintCoinSuccessDialog,
  BuyMintCoinErrorDialog,
  BuyLinkSuccessDialog,
  BuyLinkErrorDialog,
  SellMintCoinSuccessDialog,
  SellMintCoinErrorDialog,
  PromoteMeSuccessDialog,
  PromoteMeErrorDialog
} from '../../Components/Dialogs/DialogsPromo';
import { useQuery } from '../../Hooks/useQuery';
import NoCollections from './NoCollections';
import Referrals from './Referrals';
import {
  BorderBox,
  NoSell,
  ButtonPromo,
  Footer,
  FooterHaveCoin
} from './Components';
import AnimatedProgressBar from './AnimatedProgressBar';
import { POLYGON_TEST_ID, POLYGON_ID } from '../../configNetList';
import { MetamaskState } from '../../Stores/MetaMaskStore';
import iconPolygonS from '../../Resources/Instructions/icon_polygon_s.svg';
import { AddChainBtn } from '../../Components/Instructions/Pages/HowToStartWithMintMe';
import s from './Promo.module.scss';
import './Promo.scss';
import CustomContainer from '../../Components/CustomContainer/CustomContainer';

export const NEED_REFERRALS = 3;
export const FIRST_TO_UP = 0.2;

const ENV_APP = window.config.ENV_APP as ENV_APP_TYPE;

interface PromoProps {}

const Promo: React.FC<PromoProps> = () => {
  const navigate = useNavigate();
  const { promoStore, metaMaskStore, collectionsStore } = useStores();
  const [freeMintCoin, setFreeMintCoin] = useState(false);
  const [price, setPrice] = useState<Nullable<string>>(null);
  const [promoPrice, setPromoPrice] = useState<Nullable<string>>(null);
  const [refLinkPrice, setRefLinkPrice] = useState<Nullable<string>>(null);
  const [dataConfig, setDataConfig] =
    useState<Nullable<ConfigurationPromoType>>(null);
  const [haveMintCoin, setHaveMintCoin] = useState(false);
  const [randomReferrer, setRandomReferrer] = useState('');
  const [promoterCollections, setPromoterCollections] =
    useState<Nullable<CollectionTypeTg[]>>(null);
  const [promoterCollectionId, setPromoterCollectionId] =
    useState<Nullable<string>>(null);
  const [promoter, setPromoter] = useState<Nullable<PromoterType>>(null);
  const [decimals, setDecimals] = useState<Nullable<number>>(null);
  const [correctChain, setCorrectChain] = useState<Nullable<boolean>>(null);
  const [metaMaskState, setMetaMaskState] = useState(MetamaskState.Initial);
  const [noLinkPromoted, setNoLinkPromoted] = useState(true);

  const query = useQuery();
  const referrer = query.get('referrer');

  const handRef = useRef<any>(null);
  const catRef = useRef<any>(null);

  useEffect(
    () =>
      autorun(() => {
        // Only Polygon (Polygon test)
        setMetaMaskState(metaMaskStore.state);
        const curCainId = ENV_APP === 'test' ? POLYGON_TEST_ID : POLYGON_ID;
        if (metaMaskStore.chainId) {
          setCorrectChain(metaMaskStore.chainId === curCainId);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metaMaskStore.state]
  );

  useEffect(
    () =>
      autorun(() => {
        if (promoStore.configuration.state === RemoteDataState.SUCCESS) {
          const config = promoStore.configuration.value;
          setDataConfig(config);
          const decimals = promoStore.chainData?.decimals;
          if (config?.price) {
            const price = ethers.utils.formatUnits(
              config.price,
              decimals ? decimals : 18
            );
            setFreeMintCoin(+price === 0);
            setPrice(price);
          }
          if (decimals) {
            setDecimals(decimals);
            if (config?.refLinkPrice) {
              const refLinkPrice = ethers.utils.formatUnits(
                config.refLinkPrice,
                decimals
              );
              setRefLinkPrice(refLinkPrice);
            }
            if (config?.promoPrice) {
              const promoPrice = ethers.utils.formatUnits(
                config.promoPrice,
                decimals
              );
              setPromoPrice(promoPrice);
            }
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [promoStore.configuration.state]
  );

  useEffect(
    () =>
      autorun(() => {
        if (
          promoStore.promotersLinkPromoted.state === RemoteDataState.SUCCESS
        ) {
          if (promoStore.promotersLinkPromoted.value) {
            const referrersIdArr = oc(promoStore).promotersLinkPromoted.value(
              []
            );
            setNoLinkPromoted(!referrersIdArr.length);
          }
        }
        if (promoStore.promoter.state === RemoteDataState.SUCCESS) {
          if (promoStore.promoter.value) {
            setHaveMintCoin(promoStore.promoter.value?.hasToken);
          }
        }
        if (
          promoStore.promoter.state === RemoteDataState.SUCCESS &&
          promoStore.promotersLinkPromoted.state === RemoteDataState.SUCCESS
        ) {
          if (promoStore.promoter.value) {
            const promoter = promoStore.promoter.value;
            setPromoter(promoter);

            if (promoter.refLinkBought) {
              const promoterId = promoter.id;
              const referrersIdArr = oc(promoStore)
                .promotersLinkPromoted.value([])
                .filter(i => i.id !== promoterId)
                .map((i: PromoterType) => i.id);
              const rand = Math.floor(Math.random() * referrersIdArr.length);
              setRandomReferrer(referrersIdArr[rand]);
            }
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [promoStore.promoter.state, promoStore.promotersLinkPromoted.state]
  );

  useEffect(
    () =>
      autorun(() => {
        if (handRef.current) {
          handRef.current.transformOrigin = 'top right';
        }
        if (catRef.current) {
          catRef.current.transformOrigin = 'top center';
        }
      }),
    []
  );

  useEffect(
    () =>
      autorun(() => {
        if (
          referrer &&
          metaMaskStore.provider &&
          promoStore.configuration.state === RemoteDataState.SUCCESS
        ) {
          navigate('/promo', { replace: true });
          setRandomReferrer(referrer);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [referrer, metaMaskStore.provider, promoStore.configuration.state]
  );

  const successBuyLink = () => {};

  useEffect(
    () =>
      autorun(() => {
        if (collectionsStore.collections.state === RemoteDataState.SUCCESS) {
          const collections = oc(collectionsStore).collections.value([]);
          setPromoterCollections(collections);
          const notEmptyCollection = collections.find(i => i.tokens.length);
          if (notEmptyCollection) {
            setPromoterCollectionId(notEmptyCollection.id);
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionsStore.collections.state]
  );

  if (!dataConfig)
    return (
      <CircularProgress size={48} className={s.circularProgress48centre} />
    );

  return (
    <CustomContainer className={s.main}>
      <div className={s.bgImage} />
      <div className={s.content}>
        <div className={s.left}>
          <img
            ref={catRef}
            className="illustration cat cat-an"
            src={catImg}
            alt=""
          />
        </div>

        <div className={s.center}>
          <h1 className="text-gradient">
            {freeMintCoin
              ? t('take_your_mintcoin_')
              : t('buy_your_mintcoin_and_earn_money')}
          </h1>
          <h2
            dangerouslySetInnerHTML={{
              __html: freeMintCoin
                ? t('only_mintcoin_available_for_', {
                    value: dataConfig?.totalSupply
                  })
                : t('only_mintcoin_available_for_matic', {
                    value: dataConfig?.totalSupply,
                    matic: price
                  })
            }}
            className={s.underTitle}
          />
          {dataConfig?.totalSupply != null &&
            dataConfig?.availableSupply != null && (
              <AnimatedProgressBar
                value={+dataConfig.totalSupply - +dataConfig.availableSupply}
                total={+dataConfig.totalSupply}
                title={t('sale_progress')}
              />
            )}
          <div
            style={{ marginBottom: '2rem' }}
            dangerouslySetInnerHTML={{
              __html: t('just_mintcoin_left_until_price_', {
                coin: dataConfig?.availableSupply,
                matic:
                  price && +price === 0
                    ? FIRST_TO_UP
                    : price && MathRound10(+price * 2, -1)
              })
            }}
          />
          <BorderBox
            promoter={promoter}
            decimals={decimals}
            price={price}
            freeMintCoin={freeMintCoin}
          />
          {/* for the installed Metamask with the correct network */}
          {metaMaskState === MetamaskState.Connected &&
          correctChain === true ? (
            <>
              {freeMintCoin && haveMintCoin && (
                <NoSell text={t('selling_unavailable_till_initial_')} />
              )}
              {freeMintCoin && !haveMintCoin && (
                <ButtonPromo
                  label={`${t('get_mintcoin_for_free')}!`}
                  handleClick={() => promoStore.contractBuy(null)}
                  disabled={false}
                />
              )}
              {!freeMintCoin && (
                <NoCollections
                  show={!promoterCollectionId && haveMintCoin}
                  promoterCollections={promoterCollections}
                />
              )}
              {!freeMintCoin && (
                <Referrals
                  haveMintCoin={haveMintCoin}
                  promoter={promoter}
                  promoterCollectionId={promoterCollectionId}
                />
              )}
              {!freeMintCoin && !haveMintCoin && (
                <ButtonPromo
                  label={`${t('buy_for_matic', { matic: price })}`}
                  handleClick={() => promoStore.contractBuy(randomReferrer)}
                  disabled={!randomReferrer && !promoter}
                />
              )}

              {!freeMintCoin &&
                !haveMintCoin &&
                !randomReferrer &&
                !promoter && (
                  <NoSell text={t('to_buy_mintcoin_please_find_referrer_')} />
                )}
            </>
          ) : (
            <>
              <NoSell text={t('to_participate_in_the_promotion_')} />
              {metaMaskState === MetamaskState.NotInstalled && (
                <Link
                  href="https://metamask.io/download.html"
                  target="_blank"
                  variant="h5"
                  color="textPrimary"
                  className="pm-button"
                  underline="none"
                  dangerouslySetInnerHTML={{ __html: t('install_metamask') }}
                />
              )}
              {metaMaskState === MetamaskState.Installed && (
                <button
                  className="pm-button"
                  onClick={() => metaMaskStore.connect()}
                >
                  {t('connect_metamask')}
                </button>
              )}
              {metaMaskState === MetamaskState.Connected &&
                correctChain === false && (
                  <Box>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: t('switch_to_the_correct_network_', {
                          name:
                            ENV_APP === 'test' ? 'Polygon Testnet' : 'Polygon'
                        })
                      }}
                    />
                    <AddChainBtn
                      id={ENV_APP === 'test' ? POLYGON_TEST_ID : POLYGON_ID}
                      icon={iconPolygonS}
                      color={'lavender'}
                      text={t('add_polygon_network')}
                      infoText={t('polygon_network_is_connected')}
                    />
                  </Box>
                )}
            </>
          )}
        </div>

        <div className={s.right}>
          <img
            ref={handRef}
            className="illustration hand hand-an"
            src={handImg}
            alt=""
          />
        </div>
      </div>
      {/* for the installed Metamask with the correct network */}
      {metaMaskState === MetamaskState.Connected && correctChain === true ? (
        <>
          {!freeMintCoin && !haveMintCoin && !randomReferrer && !promoter && (
            <Footer
              refLinkPrice={refLinkPrice}
              disabledRefLink={noLinkPromoted}
            />
          )}
          {!freeMintCoin &&
            haveMintCoin &&
            promoter?.restForUnlock !== '0' &&
            promoterCollectionId && (
              <FooterHaveCoin
                promoPrice={promoter?.linkPromoted ? null : promoPrice}
                copyLink={`${window.config.SITE_URL}/promo?referrer=${metaMaskStore.currentAccount}`}
              />
            )}
        </>
      ) : null}
      <BuyMintCoinSuccessDialog />
      <BuyMintCoinErrorDialog />
      <BuyLinkSuccessDialog successFunc={successBuyLink} />
      <BuyLinkErrorDialog />
      <SellMintCoinSuccessDialog />
      <SellMintCoinErrorDialog />
      <PromoteMeSuccessDialog />
      <PromoteMeErrorDialog />
    </CustomContainer>
  );
};

export default Promo;
