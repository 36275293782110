import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import BalloonToothyImg from '../../../Resources/Promo/balloon-toothy.png';
import { t } from 'i18next';
import s from './Dialog.module.scss';
import { Typography } from '@mui/material';

const QuestDialog = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    window.open('https://thewall.global', '_blank');
    setOpen(false);
  };

  return (
    <div>
      <Button className="card-btn" onClick={handleClickOpen}>
        {t('play')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
      >
        <DialogContent className={s.dialog}>
          <img src={BalloonToothyImg} alt="" />
          <div>
            <DialogContentText
              id="alert-dialog-description"
              variant="h2"
              className={s.content}
            >
              {t('quest')}
            </DialogContentText>
            <Typography>{t('this_picture_appears_on_the_wall')}</Typography>
            <Typography mb={2}>
              {t('there_will_be_a_referral_link_')}
            </Typography>
            <Button variant="contained" className={s.btnV2} onClick={handleYes}>
              {t('go')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default QuestDialog;
