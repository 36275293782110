import {
  MAXIMUM_FILE_SIZE,
  IMAGE_TYPES,
  VIDEO_TYPES,
  AUDIO_TYPES,
  THREED_TYPES
} from '../constants';

import axios from 'axios';

const getTypeByUrl = (url: string) => {
  return axios.head(url).then(r => {
    try {
      const type = r.headers['content-type'];
      return type;
    } catch (error) {
      return '';
    }
  });
};

const tplMediaObj: MediaObj = {
  file: null,
  name: '',
  path: '',
  error: ''
};

const handleFileImage = (
  e: React.ChangeEvent<HTMLInputElement>
): MediaObj | null => {
  if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0];
    if (file.size > MAXIMUM_FILE_SIZE) {
      return {
        ...tplMediaObj,
        error: 'file_size_exceeded'
      };
    }
    if (!IMAGE_TYPES.includes(file.type)) {
      return {
        ...tplMediaObj,
        error: 'file_type_not_supported'
      };
    }
    return {
      file,
      name: file.name,
      path: URL.createObjectURL(file),
      error: ''
    };
  }
  return null;
};

const handleFileMedia = (
  e: React.ChangeEvent<HTMLInputElement>
): MediaObj | null => {
  if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0];
    if (file.size > MAXIMUM_FILE_SIZE) {
      return {
        ...tplMediaObj,
        error: 'file_size_exceeded'
      };
    }
    const fileExtension = file.name.toLowerCase().replace(/^.*\./, '');
    if (
      ![...IMAGE_TYPES, ...VIDEO_TYPES, ...AUDIO_TYPES].includes(file.type) &&
      !THREED_TYPES.includes(fileExtension)
    ) {
      return {
        ...tplMediaObj,
        error: 'file_type_not_supported'
      };
    }
    return {
      file,
      name: file.name,
      path: URL.createObjectURL(file),
      error: ''
    };
  }
  return null;
};

const isVideoFile = (file: Nullable<File>) => {
  if (file != null) {
    return VIDEO_TYPES.includes(file.type);
  }
  return false;
};

const isAudioFile = (file: Nullable<File>) => {
  if (file != null) {
    return AUDIO_TYPES.includes(file.type);
  }
  return false;
};

const is3dFile = (file: Nullable<File>) => {
  if (file != null) {
    return /(.+\.glb$)|(.+\.gltf$)/i.test(file.name);
  }
  return false;
};

export {
  getTypeByUrl,
  handleFileImage,
  handleFileMedia,
  isVideoFile,
  isAudioFile,
  is3dFile
};
