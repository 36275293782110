import React from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface HeaderLinkProps {
  to: string;
  title: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
}

const ButtonLink: React.FC<HeaderLinkProps> = ({
  to,
  title,
  disabled,
  fullWidth = false,
  onClick
}) => (
  <Button
    component={RouterLink}
    to={to}
    variant="contained"
    color="primary"
    disabled={disabled}
    fullWidth={fullWidth}
    onClick={onClick}
  >
    {title}
  </Button>
);

export default ButtonLink;
