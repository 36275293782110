import React, { useState, useEffect } from 'react';
import {
  CardMedia,
  Button,
  Box,
  Typography,
  CircularProgress,
  SxProps,
  Theme
} from '@mui/material';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { isVideoFile, isAudioFile, is3dFile } from '../../Utils/HandleFile';
import photoImg from '../../Resources/photo.svg';
import img3d from '../../Resources/3d.png';
import { getTypeByUrl } from '../../Utils/HandleFile';

import s from './AppCardMedia.module.scss';

const FileName = ({ name }: { name: string }) => {
  if (name) {
    return (
      <Typography
        variant="caption"
        component="p"
        mt={1}
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {name}
      </Typography>
    );
  }
  return null;
};

const SelectBtn = ({ show, title }: { show: boolean; title: string }) => {
  return show ? (
    <Box mt={1}>
      <Button variant="outlined" component="p" className={s.selectBtn}>
        {title}
      </Button>
    </Box>
  ) : null;
};

const openNewTab = (path: string, open: boolean) => {
  if (open) {
    window.open(path, '_blank');
  }
};

const AppCardMedia = ({
  mediaObj,
  path,
  maxWidth = false,
  height = '190',
  readOnly = false,
  error = false,
  sx
}: {
  mediaObj: MediaObj;
  path: string;
  maxWidth?: boolean;
  height?: string;
  readOnly?: boolean;
  error?: boolean;
  sx?: SxProps<Theme>;
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState<Nullable<string>>(null);

  useEffect(() => {
    getTypeByUrl(path).then(type => {
      setType(type);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  if (!mediaObj.file && type === null) {
    return (
      <>
        <Box height={`${height}px`} className={s.circularProgress}>
          <CircularProgress size={32} />
        </Box>
        <SelectBtn show={!readOnly} title={t('select_file')} />
      </>
    );
  }

  if (mediaObj.error) {
    return (
      <>
        <CardMedia
          className={cx(s.cardMedia, maxWidth && s.maxWidth)}
          component="img"
          height={height}
          image={photoImg}
          alt=""
          sx={sx}
        />
        <Typography variant="caption" sx={{ color: 'error.main' }}>
          {t(mediaObj.error)}
        </Typography>
      </>
    );
  }

  if (
    isVideoFile(mediaObj.file) ||
    (!mediaObj.file && isVideoFile({ type: type } as File))
  ) {
    return (
      <>
        <CardMedia
          className={cx(s.cardMedia, maxWidth && s.maxWidth)}
          component="video"
          height={height}
          src={mediaObj.path || path}
          // autoPlay
          controls
          loop
        />
        <FileName name={mediaObj.name} />
        <SelectBtn show={!readOnly} title={t('select_file')} />
      </>
    );
  }

  if (
    isAudioFile(mediaObj.file) ||
    (!mediaObj.file && isAudioFile({ type: type } as File))
  ) {
    return (
      <Box mt={2}>
        <audio controls src={mediaObj.path || path} />
        <FileName name={mediaObj.name} />
        <SelectBtn show={!readOnly} title={t('select_file')} />
      </Box>
    );
  }

  if (
    is3dFile(mediaObj.file) ||
    (!mediaObj.file && is3dFile({ type: type } as File))
  ) {
    return (
      <>
        <CardMedia
          className={cx(s.cardMedia, maxWidth && s.maxWidth)}
          component="img"
          height={height}
          image={img3d}
          alt=""
          onClick={() => openNewTab(path, readOnly)}
          sx={sx}
        />
        <FileName name={mediaObj.name} />
      </>
    );
  }

  return (
    <>
      <CardMedia
        className={cx(s.cardMedia, maxWidth && s.maxWidth, error && s.error)}
        component="img"
        height={height}
        image={mediaObj.path || path || photoImg}
        alt=""
        onClick={() => openNewTab(path, readOnly)}
        sx={sx}
      />
      <FileName name={mediaObj.name} />
    </>
  );
};

export default AppCardMedia;
