const decimalAdjust = (
  type: 'floor' | 'round' | 'ceil',
  value: number,
  exp: number
) => {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  let valueArr = value.toString().split('e');
  value = Math[type](
    +(valueArr[0] + 'e' + (valueArr[1] ? +valueArr[1] - exp : -exp))
  );
  valueArr = value.toString().split('e');
  return +(valueArr[0] + 'e' + (valueArr[1] ? +valueArr[1] + exp : exp));
};

export const MathRound10 = (value: number, exp: number) => {
  return decimalAdjust('round', value, exp);
};

export const MathFloor10 = (value: number, exp: number) => {
  return decimalAdjust('floor', value, exp);
};
