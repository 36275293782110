import CID from 'cids';

const checkCid = (cid: string) => {
  return cid.length < 74 && cid.length > 58;
};

const checkResponseCid = (response: string) => {
  const result = response.replace('\n', '');
  if (checkCid(result)) {
    return result;
  }
  // eslint-disable-next-line no-throw-literal
  throw 'Bad response uploading file';
};

const uploadUrl = '/api/upload';

const uploadJson = (contentData: any): Promise<string> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  return fetch(uploadUrl, {
    method: 'POST',
    body: JSON.stringify(contentData),
    headers: myHeaders
  })
    .then(res => res.text())
    .then(r => checkResponseCid(r))
    .catch(error => {
      console.log('error', error);
      return '';
    });
};

const uploadImage = (file: File): Promise<string> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'image/jpeg');
  const formData = new FormData();
  formData.append('file', file);
  return fetch(uploadUrl, {
    method: 'POST',
    body: file,
    headers: myHeaders
  })
    .then(res => res.text())
    .then(r => checkResponseCid(r))
    .catch(error => {
      console.log('error', error);
      return '';
    });
};

const uploadFile = (file: File, contentType: string): Promise<string> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', contentType);
  const formData = new FormData();
  formData.append('file', file);
  return fetch(uploadUrl, {
    method: 'POST',
    body: file,
    headers: myHeaders
  })
    .then(res => res.text())
    .then(r => checkResponseCid(r))
    .catch(error => {
      console.log('error', error);
      return '';
    });
};

// Replaces CID base16 with base32 in the string
const base16To32 = (str: string) => {
  const regex = /f0[0-9a-f]{71}/i;
  const match = str.match(regex);
  if (!match) return str;
  const strBase16 = match[0];
  const cid = new CID(strBase16);
  return str.replace(regex, cid.toBaseEncodedString('base32'));
};

export { checkCid, uploadJson, uploadImage, uploadFile, base16To32 };
