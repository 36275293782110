import React, { useEffect, useState } from 'react';
import { Typography, Box, Link, Button, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import { Link as RouterLink } from 'react-router-dom';
import { useStores } from '../../Stores/RootStore';
import ButtonLink from '../../Components/ButtonLink/ButtonLink';
import arrowLeft from '../../Resources/ArrowLeft.svg';
import documentDownloadIcon from '../../Resources/document-download.svg';
import verifiedIconGreen from '../../Resources/verified_green.svg';
import verifiedIconGrey from '../../Resources/verified_grey.svg';
import verifiedIconRed from '../../Resources/verified_red.svg';
import AppCardMedia from '../../Components/AppCardMedia/AppCardMedia';
import ReportVerifyProver from '../../Containers/ReportVerifyProver/ReportVerifyProver';
import { TOKENS } from '../../constants';
import {
  getCidFromUri,
  dateStrFromTs2,
  toShortAddress
} from '../../Helpers/helpers';
import { RemoteDataState } from '../../Utils/RemoteData';
import { checkCid } from '../../Utils/Storage';
import s from './Token.module.scss';
import { AlertInvalidExternalUrl } from './AlertInvalidExternalUrl';
import axios from 'axios';
import tokenComparate from '../../Utils/tokenComparate';
import FormatDesc from '../FormatDesc/FormatDesc';
import CustomContainer from '../CustomContainer/CustomContainer';
import SendTokenDialog from '../Dialogs/SendTokenDialog';

const tplMediaObj: MediaObj = {
  file: null,
  name: '',
  path: '',
  error: ''
};

interface TokenProps {
  collectionId: string;
  id: string;
  status: proverStatusTgType;
  nft: TokenTypeTg;
  licenses: LicenseType[];
  baseURI: string;
  chainData: undefined | ChainDataType;
  readonly: boolean;
  path?: string;
}

const Token: React.FC<TokenProps> = ({
  collectionId,
  id,
  status,
  nft,
  licenses,
  baseURI,
  chainData,
  readonly,
  path = ''
}) => {
  const { t } = useTranslation();
  const { collectionsStore, metaMaskStore } = useStores();

  const [dontShowInvalidExternalUrls, setDontShowInvalidExternalUrls] =
    useState<string[]>([]);
  const [currentContentData, setCurrentContentData] = useState<TokenTypeTg>();
  const [showInvalidExternalUrl, setShowInvalidExternalUrl] = useState(true);
  const [isCorrectContentData, setIsCorrectContentData] = useState(true);
  const [isCorrectExternalUrl, setIsCorrectExternalUrl] = useState(true);

  const License = () => {
    let name = '';

    const nftCid = oc(nft).collection.licenseCID('');

    if (checkCid(nftCid)) {
      const license = oc(metaMaskStore)
        .config.value.licenses([])
        .find(i => i.file_cid === nftCid);
      name = license?.title ? license.title : t('custom');
    }

    return (
      <Typography variant="body2" mr={1}>
        {name ? (
          <>
            {name}
            <Link
              href={baseURI.concat(oc(nft).collection.licenseCID(''))}
              target="_blank"
              ml={2}
            >
              <img src={documentDownloadIcon} alt="" />
            </Link>
          </>
        ) : (
          t('none')
        )}
      </Typography>
    );
  };

  const Name = ({ name }: { name: string }) => (
    <Typography
      variant="body2"
      sx={{ color: 'text.secondary', fontWeight: 'bold' }}
    >
      {name}
    </Typography>
  );

  useEffect(() => {
    if (dontShowInvalidExternalUrls.length === 0) {
      const nftIdentifiers = localStorage.getItem(
        'dontShowInvalidExternalUrls'
      );
      if (nftIdentifiers) {
        setDontShowInvalidExternalUrls([...JSON.parse(nftIdentifiers)]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dontShowInvalidExternalUrls.includes(nft.id)) {
      setShowInvalidExternalUrl(false);
    }
  }, [dontShowInvalidExternalUrls, nft.id]);

  if (showInvalidExternalUrl && !currentContentData) {
    axios.get(`https://ipfs.io/ipfs/${nft.contentCID}`).then(resp => {
      setCurrentContentData(resp.data);
    });
  }

  useEffect(() => {
    if (showInvalidExternalUrl && currentContentData && isCorrectContentData) {
      setIsCorrectContentData(tokenComparate(currentContentData, nft));

      if (
        // correction of the old format /uuid... If the format is old then, create a new external_url
        /uuid/i.test(nft.external_url) ||
        // checking collection. If the collection does not match, then create a new external_url
        !new RegExp(`${collectionId}`, 'i').test(nft.external_url)
      ) {
        setIsCorrectExternalUrl(false);
      }
    }
  }, [
    currentContentData,
    showInvalidExternalUrl,
    isCorrectContentData,
    collectionId,
    nft
  ]);

  useEffect(() => {
    if (
      !showInvalidExternalUrl &&
      !dontShowInvalidExternalUrls.includes(nft.id)
    ) {
      localStorage.setItem(
        'dontShowInvalidExternalUrls',
        JSON.stringify([...dontShowInvalidExternalUrls, nft.id])
      );
    }
  }, [showInvalidExternalUrl, dontShowInvalidExternalUrls, nft.id]);

  return (
    <div>
      {!readonly &&
        showInvalidExternalUrl &&
        (!isCorrectContentData || !isCorrectExternalUrl) && (
          <AlertInvalidExternalUrl
            nft={nft}
            id={id}
            collectionId={collectionId}
            isCorrectExternalUrl={isCorrectExternalUrl}
            setShowInvalidExternalUrl={setShowInvalidExternalUrl}
          />
        )}
      <CustomContainer>
        <Typography variant="h1">
          <RouterLink to={`${path}/collection/${collectionId}`}>
            <img src={arrowLeft} alt="" className={s.arrowLeft} />
          </RouterLink>
          {nft.name}
        </Typography>
        <Box className={s.main}>
          <Box width="251px" my={1} className={s.boxImage}>
            <AppCardMedia
              mediaObj={tplMediaObj}
              path={baseURI.concat(getCidFromUri(nft.content))}
              readOnly={true}
              sx={{ objectFit: 'contain' }}
            />
            <Box mt={2}>
              <Button
                variant="outlined"
                component={Link}
                href={baseURI.concat(getCidFromUri(nft.content))}
                target="_blank"
              >
                {t('open')}
              </Button>
            </Box>
          </Box>
          <div>
            <Box className={s.right}>
              <Name name={t('description')} />
              <Typography variant="body2" className={s.description}>
                <FormatDesc text={nft.description}></FormatDesc>
              </Typography>
              <Name name={t('license_file')} />
              <License />
              <Name name={t('blockchain')} />
              <Typography variant="body2">{chainData?.name}</Typography>
              <Name name={t('token_creation_standard')} />
              <Typography variant="body2">{TOKENS[0]}</Typography>
              <Name name={t('collection')} />
              <Typography variant="body2">
                {oc(nft).collection.name('')}
              </Typography>
              <Name name={t('token_id')} />
              <Typography variant="body2">{nft.tokenId}</Typography>
              <Name name={t('owner')} />
              <Link
                href={`${metaMaskStore.chainData?.scanAddress}/${nft.owner}`}
                variant="body2"
                target="_blank"
              >
                {toShortAddress(nft.owner, 8)}
              </Link>
              <Name name={t('last_change')} />
              <Typography variant="body2">
                {nft.timeCreation === nft.timeChanging
                  ? t('never')
                  : dateStrFromTs2(Number(nft.timeChanging))}
              </Typography>
              {status === null &&
                collectionsStore.tokensVerifyDataTg.state ===
                  RemoteDataState.REQUEST && (
                  <>
                    <CircularProgress size={24} /> <Box />
                  </>
                )}
              {status === 'Pending' && (
                <Box className={s.flexStartRow}>
                  <img src={verifiedIconGrey} alt="" />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold' }}
                    ml={1}
                  >
                    {t('verification_progress')}
                  </Typography>
                </Box>
              )}
              {status === 'Initial' && (
                <Box className={s.flexStartRow}>
                  <img src={verifiedIconGrey} alt="" />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold' }}
                    ml={1}
                  >
                    {t('verification_prover_not_carried_out')}
                  </Typography>
                </Box>
              )}
              {status === 'Proved' && (
                <>
                  <Box className={s.flexStart}>
                    <img src={verifiedIconGreen} alt="" />
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 'bold' }}
                      ml={1}
                    >
                      {t('verified_by_prover')}
                    </Typography>
                  </Box>
                  <ReportVerifyProver id={nft.id} />
                </>
              )}
              {status === 'NotProved' && (
                <>
                  <Box className={s.flexStart}>
                    <img src={verifiedIconRed} alt="" />
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 'bold' }}
                      ml={1}
                    >
                      {t('not_verified_by_prover')}
                    </Typography>
                  </Box>
                  <ReportVerifyProver id={nft.id} />
                </>
              )}
              {!readonly && (
                <Box mt={3} className={s.boxBtn}>
                  <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <ButtonLink
                      to={`/edit-nft/${collectionId}/${id}`}
                      title={t('edit')}
                    />
                    <SendTokenDialog token={nft} collectionId={collectionId}>
                      <Button variant="outlined" color="primary" fullWidth>
                        {t('transfer')}
                      </Button>
                    </SendTokenDialog>
                  </Box>
                </Box>
              )}
            </Box>
          </div>
        </Box>
      </CustomContainer>
    </div>
  );
};

export default observer(Token);
