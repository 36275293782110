import React from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { shortAddress } from '../../../Helpers/helpers';
import s from './CurrentAccount.module.scss';

interface CurrentAccountProps {
  account: string;
  checkChainId: boolean;
}

const CurrentAccount: React.FC<CurrentAccountProps> = ({
  account,
  checkChainId
}) => {
  if (!account) return null;
  return (
    <Typography variant="body1">
      {shortAddress(account)}
      <span className={cx(s.indicator, checkChainId ? s.success : s.error)} />
    </Typography>
  );
};

export default CurrentAccount;
